import React from "react";
import { Loader } from "priceit-ui";
import { ThemeComponent, themeList } from "@theme/Theme";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalRoutes from "@routes/Routes";
import { useQuery } from "@apollo/client";
import { GET_USER_THEME, GET_AUTH_FOR_LOGIN } from "@graphQl/queries/user";

export const Main = React.memo(() => {
	const { loading: loadingAuth } = useQuery(GET_AUTH_FOR_LOGIN);
	const { data: dataUser, loading: loadingUser } = useQuery(GET_USER_THEME);

	const { theme } = dataUser?.getAuth || {};

	if (loadingAuth || loadingUser) return <Loader />;

	return (
		<ThemeComponent mode={theme || "dark"} theme={themeList.theme1}>
			<Router>
				<GlobalRoutes />
			</Router>
		</ThemeComponent>
	);
});
Main.whyDidYouRender = true;
Main.displayName = "Main";
