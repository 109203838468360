import { gql } from "@apollo/client";
import { OFFER_STATUS_MAPPING_FRAGMENT } from "@graphQl/fragments/offerStatusMapping";
import {
	PRICE_MODEL_PACKS_ID_FRAGMENT,
	PUBLIC_PRICE_MODEL_FOR_COMBINATION_FRAGMENT,
	PUBLIC_PRICE_MODEL_FOR_DISCOUNT_FRAGMENT,
} from "@graphQl/fragments/priceModel";
import {
	PRICE_MODEL_COMBINATION_PROPERTIES_NAME_FRAGMENT,
	PRICE_MODEL_COMBINATION_PROPERTIES_ONLY_NAME_FRAGMENT,
} from "@graphQl/fragments/priceModelCombinationProperties";
import { PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT } from "@graphQl/fragments/priceModelCombinationQuoteFieldFragment";
import {
	PRICE_MODEL_ID_PRICE_MODEL_LINK_COMBINATION_FRAGMENT,
	PRICE_MODEL_LINK_COMBINATION_FRAGMENT,
	PRICE_MODEL_LINKED_LANGUAGE_COMBINATION_FRAGMENT,
} from "@graphQl/fragments/priceModelLinkCombinationFragment";
import { PROFITABILITY_THRESHOLD_FRAGMENT } from "@graphQl/fragments/profitabilityThresholdFragment";
import { AVAILABILITY_DATA_TOKEN_FRAGMENT, TOKEN_FRAGMENT } from "@graphQl/fragments/token";
import { USER_FRAGMENT } from "@graphQl/fragments/user";
import {
	WORKSPACE_FOR_FOR_DISCOVER_STEP_FRAGMENT,
	WORKSPACE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT,
	WORKSPACE_TC_TYPE_FRAGMENT,
} from "@graphQl/fragments/workspace";

import {
	QUOTE_CONFIGURATION_HEADER_BACKGROUND_COLOR_FRAGMENT,
	QUOTE_CONFIGURATION_HEADER_FONT_COLOR_FRAGMENT,
	QUOTE_CONFIGURATION_HEADER_LOGO_FRAGMENT,
} from "./quoteConfiguration";
import { TERMS_AND_CONDITIONS_FRAGMENT } from "./termsAndConditions";
import { OFFER_PROPERTIES_FOR_PRICE_MODEL_COMBINATION_FRAGMENT } from "./offerProperties";

export const ID_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment IdPriceModelCombinationFragment on PriceModelCombination {
		id
	}
`;

export const PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment PriceModelCombinationPropertiesIdPriceModelCombinationFragment on PriceModelCombination {
		id
		priceModelCombinationPropertiesId
	}
`;

export const ASK_EMAIL_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment AskEmailPriceModelCombinationFragment on PriceModelCombination {
		id
		askEmailWhenLinkIsOpened
	}
`;

export const BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment BackgroundColorPriceModelCombinationFragment on PriceModelCombination {
		id
		summaryPageBackgroundColor
	}
`;

export const OFFER_ACCESS_PAGE_THEME_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment OfferAccessPageThemePriceModelCombinationFragment on PriceModelCombination {
		id
		offerAccessPageTheme
	}
`;

export const QUOTE_GENERATOR_IS_SALES_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment QuoteGeneratorIsSalesPriceModelCombinationFragment on PriceModelCombination {
		id
		quoteGeneratorIsSales
	}
`;

export const COMPANY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment CompanyNamePriceModelCombinationFragment on PriceModelCombination {
		id
		companyName
	}
`;

export const UPDATED_AT_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment UpdatedAtPriceModelCombinationFragment on PriceModelCombination {
		id
		updatedAt
	}
`;

export const UPDATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment UpdatedByPriceModelCombinationFragment on PriceModelCombination {
		id
		updatedById
	}
`;

export const CREATED_AT_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment CreatedAtPriceModelCombinationFragment on PriceModelCombination {
		id
		createdAt
	}
`;

export const CREATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment CreatedByPriceModelCombinationFragment on PriceModelCombination {
		id
		createdById
	}
`;

export const DESCRIPTION_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment DescriptionPriceModelCombinationFragment on PriceModelCombination {
		id
		description
	}
`;

export const DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment DisplayedThemePriceModelCombinationFragment on PriceModelCombination {
		id
		displayedTheme
	}
`;

export const EMAILS_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment EmailsPriceModelCombinationFragment on PriceModelCombination {
		id
	}
`;

export const ORIGINAL_PRICE_MODEL_COMBINATION_ID_FRAGMENT = gql`
	fragment OriginalPriceModelCombinationIdFragment on PriceModelCombination {
		id
		originalPriceModelCombinationId
	}
`;

export const OPEN_COMBINATION_LINK_USERS_FRAGMENT = gql`
	${ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${USER_FRAGMENT}
	fragment OpenCombinationLinkUsersFragment on PriceModelCombination {
		...IdPriceModelCombinationFragment
		openCombinationLinkUsers {
			...UserFragment
		}
	}
`;

export const QUOTE_COMBINATION_LINK_USERS_FRAGMENT = gql`
	${ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${USER_FRAGMENT}
	fragment QuoteCombinationLinkUsersFragment on PriceModelCombination {
		...IdPriceModelCombinationFragment
		quoteCombinationLinkUsers {
			...UserFragment
		}
	}
`;

export const ENABLE_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment EnablePasswordPriceModelCombinationFragment on PriceModelCombination {
		id
		enablePassword
	}
`;

export const EXPIRATION_DATE_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment ExpirationDatePriceModelCombinationFragment on PriceModelCombination {
		id
		expirationDate
	}
`;

export const FAVICON_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment FaviconPriceModelCombinationFragment on PriceModelCombination {
		id
		favicon
	}
`;

export const FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment FontColorPriceModelCombinationFragment on PriceModelCombination {
		id
		summaryPageFontColor
	}
`;

export const MAIN_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment MainShadeTypographyColorPriceModelCombinationFragment on PriceModelCombination {
		id
		mainShadeTypographyColor
	}
`;

export const COMPLEMENTARY_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment ComplementaryShadeTypographyColorPriceModelCombinationFragment on PriceModelCombination {
		id
		complementaryShadeTypographyColor
	}
`;

export const ACCENT_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment AccentShadeTypographyColorPriceModelCombinationFragment on PriceModelCombination {
		id
		accentShadeTypographyColor
	}
`;

export const MAIN_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment MainBackgroundColorPriceModelCombinationFragment on PriceModelCombination {
		id
		mainBackgroundColor
	}
`;

export const ALTERNATIVE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment AlternativeBackgroundColorPriceModelCombinationFragment on PriceModelCombination {
		id
		alternativeBackgroundColor
	}
`;

export const INACTIVE_SHADE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment InactiveShadeBackgroundColorPriceModelCombinationFragment on PriceModelCombination {
		id
		inactiveShadeBackgroundColor
	}
`;

export const IS_QUOTE_GENERATOR_ACTIVE_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment IsQuoteGeneratorActivePriceModelCombinationFragment on PriceModelCombination {
		id
		isQuoteGeneratorActive
	}
`;

export const IS_FAVORITE_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment IsFavoritePriceModelCombinationFragment on PriceModelCombination {
		id
		isFavorite
	}
`;

export const LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment LanguagePriceModelCombinationFragment on PriceModelCombination {
		id
		language
	}
`;

export const LOGIN_LINK_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment LoginLinkPriceModelCombinationFragment on PriceModelCombination {
		id
		loginLink
	}
`;

export const LINK_VOLUMES_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment LinkVolumesPriceModelCombinationFragment on PriceModelCombination {
		id
		shouldLinkVolumes
	}
`;

export const MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment MaxGlobalDiscountPriceModelCombinationFragment on PriceModelCombination {
		id
		maxGlobalDiscount
	}
`;

export const ENABLE_MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment EnableMaxGlobalDiscountPriceModelCombinationFragment on PriceModelCombination {
		id
		enableMaxGlobalDiscount
	}
`;

export const OFFER_LOGO_LINK_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment OfferLogoLinkPriceModelCombinationFragment on PriceModelCombination {
		id
		offerLogoLink
	}
`;

export const NAME_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment NamePriceModelCombinationFragment on PriceModelCombination {
		id
		name
	}
`;

export const ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment OnlyNamePriceModelCombinationFragment on PriceModelCombination {
		id
		name
		priceModelCombinationProperties {
			id
			name
		}
	}
`;

export const OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment OfferImagePriceModelCombinationFragment on PriceModelCombination {
		id
		offerImage
	}
`;

export const SELECTED_TEMPLATE_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment SelectedTemplatePriceModelCombinationFragment on PriceModelCombination {
		id
		selectedTemplate
	}
`;

export const SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment ShowPriceitBrandingPriceModelCombinationFragment on PriceModelCombination {
		id
		showPriceitBranding
	}
`;

export const SUMMARY_PAGE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment SummaryPageBackgroundColorPriceModelCombinationFragment on PriceModelCombination {
		id
		summaryPageBackgroundColor
	}
`;

export const SUMMARY_PAGE_FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment SummaryPageFontColorPriceModelCombinationFragment on PriceModelCombination {
		id
		summaryPageFontColor
	}
`;

export const SYNC_DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment SyncDisplayedThemePriceModelCombinationFragment on PriceModelCombination {
		id
		syncDisplayedTheme
	}
`;

export const INTRO_PAGE_SECTIONS_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment IntroPageSectionsPriceModelCombinationFragment on PriceModelCombination {
		id
		introPageSections
	}
`;

export const PRICE_MODEL_COMBINATION_SELECTION_SUMMARY_FRAGMENT = gql`
	fragment PriceModelCombinationSelectionSummaryFragment on PriceModelCombination {
		id
		selectionSummary
	}
`;

export const PRICE_MODEL_COMBINATION_PUBLIC_LINK_TC_DATA_FRAGMENT = gql`
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${WORKSPACE_TC_TYPE_FRAGMENT}
	${TERMS_AND_CONDITIONS_FRAGMENT}
	fragment PriceModelCombinationPublicLinkTcDataFragment on PriceModelCombination {
		...LanguagePriceModelCombinationFragment
		workspace {
			...WorkspaceTCTypeFragment
			termsAndConditions {
				...TermsAndConditionsFragment
			}
		}
	}
`;

export const HAS_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment HasPasswordPriceModelCombinationFragment on PriceModelCombination {
		id
		tokenObj {
			hasPassword
		}
	}
`;

export const PRICE_MODEL_COMBINATION_SELECTED_CATEGORIES_FRAGMENT = gql`
	fragment PriceModelCombinationSelectedCategoriesFragment on PriceModelCombination {
		id
		selectedCategories {
			id
			categorizationId
			categoryId
			priceModelCombinationId
			priceModelCombinationPropertiesId
			category {
				id
				name
				color
			}
		}
	}
`;

export const WORKSPACE_ID_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment WorkspaceIdPriceModelCombinationFragment on PriceModelCombination {
		id
		workspaceId
	}
`;

export const CRM_PIPELINES_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_FRAGMENT}
	fragment CrmPipelinesPriceModelCombinationFragment on PriceModelCombination {
		id
		priceModelCombinationCrmPipelines {
			...OfferStatusMappingFragment
		}
	}
`;

export const NAME_AND_LINK_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment NameAndLinkPriceModelCombinationFragment on PriceModelCombination {
		priceModelLinkCombination {
			priceModel {
				id
				priceMetric
				volumeReference
			}
		}
		id
		name
		priceModelCombinationPropertiesId
		priceModelCombinationProperties {
			id
			name
		}
		shouldLinkVolumes
	}
`;

export const PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${PUBLIC_PRICE_MODEL_FOR_COMBINATION_FRAGMENT}
	fragment PriceModelLinkCombinationPriceModelCombinationFragment on PriceModelCombination {
		id
		priceModelLinkCombination {
			id
			index
			name
			mandatory
			priceModel {
				...PublicPriceModelForCombinationFragment
			}
		}
	}
`;

export const PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_PACK_ID_FRAGMENT = gql`
	${PRICE_MODEL_PACKS_ID_FRAGMENT}
	fragment PriceModelLinkCombinationPriceModelPackIdFragment on PriceModelCombination {
		id
		priceModelLinkCombination {
			id
			priceModel {
				...PriceModelPacksIdFragment
			}
		}
	}
`;

export const PRICE_MODEL_LINK_COMBINATION_FOR_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${PUBLIC_PRICE_MODEL_FOR_DISCOUNT_FRAGMENT}
	fragment PriceModelLinkCombinationForDiscountPriceModelCombinationFragment on PriceModelCombination {
		id
		priceModelLinkCombination {
			id
			index
			name
			mandatory
			priceModel {
				...PublicPriceModelForDiscountFragment
			}
		}
	}
`;

export const SMALL_PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment SmallPriceModelLinkCombinationPriceModelCombinationFragment on PriceModelCombination {
		id
		priceModelLinkCombination {
			id
		}
	}
`;

export const CURRENCY_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment CurrencyPriceModelCombinationFragment on PriceModelCombination {
		id
		currency
	}
`;

export const PRICE_MODEL_COMBINATION_OVERVIEW_FRAGMENT = gql`
	${TOKEN_FRAGMENT}
	${ENABLE_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SELECTED_TEMPLATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment PriceModelCombinationOverviewFragment on PriceModelCombination {
		id
		...EnablePasswordPriceModelCombinationFragment
		...DisplayedThemePriceModelCombinationFragment
		...SelectedTemplatePriceModelCombinationFragment
		token
		tokenId
		shouldLinkVolumes
		companyName
		priceModelLinkCombination {
			id
			priceModelId
			name
			priceModel {
				priceMetric
				volumeReference
			}
		}
		tokenObj {
			...TokenFragment
		}
	}
`;

export const PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_FRAGMENT = gql`
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DESCRIPTION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_SELECTED_CATEGORIES_FRAGMENT}
	${TOKEN_FRAGMENT}
	${PRICE_MODEL_LINKED_LANGUAGE_COMBINATION_FRAGMENT}

	fragment PriceModelCombinationForOfferCreationFragment on PriceModelCombination {
		...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
		...OnlyNamePriceModelCombinationFragment
		...DescriptionPriceModelCombinationFragment
		...PriceModelCombinationSelectedCategoriesFragment
		tokenObj {
			...TokenFragment
		}
		priceModelLinkCombination {
			...PriceModelLinkedLanguageCombinationFragment
		}
	}
`;

export const PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_MAIL_AND_QUOTE_LAYOUT_FRAGMENT = gql`
	${ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ASK_EMAIL_PRICE_MODEL_COMBINATION_FRAGMENT}
	${USER_FRAGMENT}
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment PriceModelCombinationForOfferCreationMailAndQuoteLayoutFragment on PriceModelCombination {
		...IdPriceModelCombinationFragment
		...AskEmailPriceModelCombinationFragment
		openCombinationLinkUsers {
			...UserFragment
		}
		quoteCombinationLinkUsers {
			...UserFragment
		}
		...LanguagePriceModelCombinationFragment
	}
`;

export const PRICE_MODEL_COMBINATION_ALL_FRAGMENT = gql`
	${ASK_EMAIL_PRICE_MODEL_COMBINATION_FRAGMENT}
	${BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${COMPANY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${CREATED_AT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${CREATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DESCRIPTION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EMAILS_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ENABLE_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EXPIRATION_DATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${FAVICON_PRICE_MODEL_COMBINATION_FRAGMENT}
	${FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${IS_FAVORITE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${IS_QUOTE_GENERATOR_ACTIVE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LINK_VOLUMES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_ACCESS_PAGE_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OPEN_COMBINATION_LINK_USERS_FRAGMENT}
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_SELECTED_CATEGORIES_FRAGMENT}
	${PRICE_MODEL_COMBINATION_PROPERTIES_NAME_FRAGMENT}
	${PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	${PRICE_MODEL_LINK_COMBINATION_FRAGMENT}
	${PROFITABILITY_THRESHOLD_FRAGMENT}
	${QUOTE_COMBINATION_LINK_USERS_FRAGMENT}
	${QUOTE_GENERATOR_IS_SALES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SELECTED_TEMPLATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SYNC_DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${INTRO_PAGE_SECTIONS_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_SELECTION_SUMMARY_FRAGMENT}
	${TOKEN_FRAGMENT}
	${UPDATED_AT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${UPDATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ENABLE_MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ORIGINAL_PRICE_MODEL_COMBINATION_ID_FRAGMENT}
	${LOGIN_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	${CRM_PIPELINES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${QUOTE_CONFIGURATION_HEADER_BACKGROUND_COLOR_FRAGMENT}
	${QUOTE_CONFIGURATION_HEADER_FONT_COLOR_FRAGMENT}
	${QUOTE_CONFIGURATION_HEADER_LOGO_FRAGMENT}
	${OFFER_PROPERTIES_FOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_LOGO_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAIN_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${COMPLEMENTARY_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ACCENT_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAIN_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ALTERNATIVE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${INACTIVE_SHADE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment PriceModelCombinationAllFragment on PriceModelCombination {
		id
		currency
		...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
		...OnlyNamePriceModelCombinationFragment
		...AskEmailPriceModelCombinationFragment
		...BackgroundColorPriceModelCombinationFragment
		...OfferAccessPageThemePriceModelCombinationFragment
		...CompanyNamePriceModelCombinationFragment
		...DescriptionPriceModelCombinationFragment
		...DisplayedThemePriceModelCombinationFragment
		...EmailsPriceModelCombinationFragment
		...EnablePasswordPriceModelCombinationFragment
		...ExpirationDatePriceModelCombinationFragment
		...FaviconPriceModelCombinationFragment
		...FontColorPriceModelCombinationFragment
		...IsFavoritePriceModelCombinationFragment
		...IsQuoteGeneratorActivePriceModelCombinationFragment
		...LanguagePriceModelCombinationFragment
		...LinkVolumesPriceModelCombinationFragment
		...OfferImagePriceModelCombinationFragment
		...SelectedTemplatePriceModelCombinationFragment
		...ShowPriceitBrandingPriceModelCombinationFragment
		...SummaryPageBackgroundColorPriceModelCombinationFragment
		...SummaryPageFontColorPriceModelCombinationFragment
		...SyncDisplayedThemePriceModelCombinationFragment
		...IntroPageSectionsPriceModelCombinationFragment
		...CreatedAtPriceModelCombinationFragment
		...CreatedByPriceModelCombinationFragment
		...UpdatedAtPriceModelCombinationFragment
		...UpdatedByPriceModelCombinationFragment
		...QuoteGeneratorIsSalesPriceModelCombinationFragment
		...MaxGlobalDiscountPriceModelCombinationFragment
		...EnableMaxGlobalDiscountPriceModelCombinationFragment
		...OfferLogoLinkPriceModelCombinationFragment
		...MainShadeTypographyColorPriceModelCombinationFragment
		...ComplementaryShadeTypographyColorPriceModelCombinationFragment
		...AccentShadeTypographyColorPriceModelCombinationFragment
		...MainBackgroundColorPriceModelCombinationFragment
		...AlternativeBackgroundColorPriceModelCombinationFragment
		...InactiveShadeBackgroundColorPriceModelCombinationFragment
		...PriceModelCombinationSelectionSummaryFragment
		token
		workspaceId
		active
		...PriceModelCombinationSelectedCategoriesFragment
		priceModelLinkCombination {
			...PriceModelLinkCombinationFragment
		}
		tokenId
		tokenObj {
			...TokenFragment
		}
		profitabilityThreshold {
			...ProfitabilityThresholdFragment
		}
		quoteFields {
			...PriceModelCombinationQuoteFieldFragment
		}
		priceModelCombinationProperties {
			...PriceModelCombinationPropertiesNameFragment
		}
		quoteConfiguration {
			id
			...QuoteConfigurationHeaderLogoFragment
			...QuoteConfigurationHeaderBackgroundColorFragment
			...QuoteConfigurationHeaderFontColorFragment
			layout
			offerId
			priceModelCombinationId
			showAttributesDescription
			showFirstPaymentBox
			showPaymentSchedule
			showBrandGeneratedBy
			introductionText
			showIncludedMention
			showTotalBoxes
			showVatDetail
			useIntroTextFromIntroPage
			showIntroductionText
		}
		...OpenCombinationLinkUsersFragment
		...QuoteCombinationLinkUsersFragment
		...OriginalPriceModelCombinationIdFragment
		...LoginLinkPriceModelCombinationFragment
		...CrmPipelinesPriceModelCombinationFragment
		offerProperties {
			...OfferPropertiesForPriceModelCombinationFragment
			enableClientsLogo
			enableFooter
			footerContent
			footerTitle
			enableIntroductionTextForProspect
			enableDealOwnerContact
			name
			enableTc
			enableAttachement
		}
		attachments {
			id
			index
			name
			type
			url
			isEmbedded
		}
	}
`;

export const PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${ASK_EMAIL_PRICE_MODEL_COMBINATION_FRAGMENT}
	${BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${COMPANY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${CREATED_AT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${CREATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DESCRIPTION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EMAILS_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ENABLE_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EXPIRATION_DATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${FAVICON_PRICE_MODEL_COMBINATION_FRAGMENT}
	${FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${IS_FAVORITE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${IS_QUOTE_GENERATOR_ACTIVE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${CURRENCY_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LINK_VOLUMES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_ACCESS_PAGE_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_SELECTED_CATEGORIES_FRAGMENT}

	${PRICE_MODEL_LINK_COMBINATION_FRAGMENT}
	${QUOTE_GENERATOR_IS_SALES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SELECTED_TEMPLATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SYNC_DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${INTRO_PAGE_SECTIONS_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_SELECTION_SUMMARY_FRAGMENT}
	${TOKEN_FRAGMENT}
	${UPDATED_AT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${UPDATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ENABLE_MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ORIGINAL_PRICE_MODEL_COMBINATION_ID_FRAGMENT}
	${LOGIN_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_PROPERTIES_FOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_LOGO_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAIN_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${COMPLEMENTARY_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ACCENT_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAIN_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ALTERNATIVE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${INACTIVE_SHADE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment PriceModelCombinationFragment on PriceModelCombination {
		id
		isReference
		...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
		...OnlyNamePriceModelCombinationFragment
		...AskEmailPriceModelCombinationFragment
		...BackgroundColorPriceModelCombinationFragment
		...OfferAccessPageThemePriceModelCombinationFragment
		...CompanyNamePriceModelCombinationFragment
		...DescriptionPriceModelCombinationFragment
		...DisplayedThemePriceModelCombinationFragment
		...EmailsPriceModelCombinationFragment
		...EnablePasswordPriceModelCombinationFragment
		...ExpirationDatePriceModelCombinationFragment
		...FaviconPriceModelCombinationFragment
		...FontColorPriceModelCombinationFragment
		...IsFavoritePriceModelCombinationFragment
		...IsQuoteGeneratorActivePriceModelCombinationFragment
		...LanguagePriceModelCombinationFragment
		...CurrencyPriceModelCombinationFragment
		...LinkVolumesPriceModelCombinationFragment
		...OfferImagePriceModelCombinationFragment
		...SelectedTemplatePriceModelCombinationFragment
		...ShowPriceitBrandingPriceModelCombinationFragment
		...SummaryPageBackgroundColorPriceModelCombinationFragment
		...SummaryPageFontColorPriceModelCombinationFragment
		...SyncDisplayedThemePriceModelCombinationFragment
		...IntroPageSectionsPriceModelCombinationFragment
		...CreatedAtPriceModelCombinationFragment
		...CreatedByPriceModelCombinationFragment
		...UpdatedAtPriceModelCombinationFragment
		...UpdatedByPriceModelCombinationFragment
		...QuoteGeneratorIsSalesPriceModelCombinationFragment
		...MaxGlobalDiscountPriceModelCombinationFragment
		...EnableMaxGlobalDiscountPriceModelCombinationFragment
		...OfferLogoLinkPriceModelCombinationFragment
		...MainShadeTypographyColorPriceModelCombinationFragment
		...ComplementaryShadeTypographyColorPriceModelCombinationFragment
		...AccentShadeTypographyColorPriceModelCombinationFragment
		...MainBackgroundColorPriceModelCombinationFragment
		...AlternativeBackgroundColorPriceModelCombinationFragment
		...InactiveShadeBackgroundColorPriceModelCombinationFragment
		...PriceModelCombinationSelectionSummaryFragment
		token
		workspaceId
		active
		...PriceModelCombinationSelectedCategoriesFragment

		priceModelLinkCombination {
			...PriceModelLinkCombinationFragment
		}

		tokenId
		tokenObj {
			...TokenFragment
		}

		...OriginalPriceModelCombinationIdFragment
		...LoginLinkPriceModelCombinationFragment

		offerProperties {
			...OfferPropertiesForPriceModelCombinationFragment
		}
	}
`;

export const PUBLIC_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ASK_EMAIL_PRICE_MODEL_COMBINATION_FRAGMENT}
	${BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_ACCESS_PAGE_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${COMPANY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DESCRIPTION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EMAILS_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ENABLE_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT}
	${FAVICON_PRICE_MODEL_COMBINATION_FRAGMENT}
	${FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${IS_QUOTE_GENERATOR_ACTIVE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LINK_VOLUMES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SELECTED_TEMPLATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EXPIRATION_DATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SYNC_DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${INTRO_PAGE_SECTIONS_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_SELECTION_SUMMARY_FRAGMENT}
	${PROFITABILITY_THRESHOLD_FRAGMENT}
	${QUOTE_GENERATOR_IS_SALES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	${WORKSPACE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT}
	${AVAILABILITY_DATA_TOKEN_FRAGMENT}
	${PRICE_MODEL_COMBINATION_PROPERTIES_ONLY_NAME_FRAGMENT}
	${PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ENABLE_MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LOGIN_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	${QUOTE_CONFIGURATION_HEADER_BACKGROUND_COLOR_FRAGMENT}
	${QUOTE_CONFIGURATION_HEADER_FONT_COLOR_FRAGMENT}
	${QUOTE_CONFIGURATION_HEADER_LOGO_FRAGMENT}
	${OFFER_PROPERTIES_FOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_LOGO_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAIN_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${COMPLEMENTARY_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ACCENT_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAIN_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ALTERNATIVE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${INACTIVE_SHADE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment PublicPriceModelCombinationFragment on PriceModelCombination {
		id
		...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
		...AskEmailPriceModelCombinationFragment
		...BackgroundColorPriceModelCombinationFragment
		...OfferAccessPageThemePriceModelCombinationFragment
		...CompanyNamePriceModelCombinationFragment
		...DescriptionPriceModelCombinationFragment
		...DisplayedThemePriceModelCombinationFragment
		...EmailsPriceModelCombinationFragment
		...EnablePasswordPriceModelCombinationFragment
		...ExpirationDatePriceModelCombinationFragment
		...FaviconPriceModelCombinationFragment
		...FontColorPriceModelCombinationFragment
		...IsQuoteGeneratorActivePriceModelCombinationFragment
		...LanguagePriceModelCombinationFragment
		...LinkVolumesPriceModelCombinationFragment
		...OnlyNamePriceModelCombinationFragment
		...OfferImagePriceModelCombinationFragment
		...SelectedTemplatePriceModelCombinationFragment
		...ShowPriceitBrandingPriceModelCombinationFragment
		...SummaryPageBackgroundColorPriceModelCombinationFragment
		...SummaryPageFontColorPriceModelCombinationFragment
		...SyncDisplayedThemePriceModelCombinationFragment
		...IntroPageSectionsPriceModelCombinationFragment
		...QuoteGeneratorIsSalesPriceModelCombinationFragment
		...MaxGlobalDiscountPriceModelCombinationFragment
		...EnableMaxGlobalDiscountPriceModelCombinationFragment
		...OfferLogoLinkPriceModelCombinationFragment
		...MainShadeTypographyColorPriceModelCombinationFragment
		...ComplementaryShadeTypographyColorPriceModelCombinationFragment
		...AccentShadeTypographyColorPriceModelCombinationFragment
		...MainBackgroundColorPriceModelCombinationFragment
		...AlternativeBackgroundColorPriceModelCombinationFragment
		...InactiveShadeBackgroundColorPriceModelCombinationFragment
		...PriceModelCombinationSelectionSummaryFragment
		token
		workspaceId
		active
		tokenId
		tokenObj {
			...AvailabilityDataTokenFragment
		}
		profitabilityThreshold {
			...ProfitabilityThresholdFragment
		}
		quoteFields {
			...PriceModelCombinationQuoteFieldFragment
		}
		workspace {
			...WorkspaceForPublicPriceModelFragment
		}
		priceModelCombinationProperties {
			...PriceModelCombinationPropertiesOnlyNameFragment
		}
		quoteConfiguration {
			...QuoteConfigurationHeaderLogoFragment
			...QuoteConfigurationHeaderBackgroundColorFragment
			...QuoteConfigurationHeaderFontColorFragment
		}
		...PriceModelLinkCombinationPriceModelCombinationFragment
		...LoginLinkPriceModelCombinationFragment
		offerProperties {
			...OfferPropertiesForPriceModelCombinationFragment
		}
		currency
	}
`;

export const PUBLIC_PRICE_MODEL_COMBINATION_FOR_DISCOVER_STEP_FRAGMENT = gql`
	${PRICE_MODEL_COMBINATION_PROPERTIES_ONLY_NAME_FRAGMENT}
	${WORKSPACE_FOR_FOR_DISCOVER_STEP_FRAGMENT}
	${OFFER_ACCESS_PAGE_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LOGIN_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}

	fragment PublicPriceModelCombinationForDiscoverStepFragment on PriceModelCombination {
		id

		...OfferAccessPageThemePriceModelCombinationFragment
		...OfferImagePriceModelCombinationFragment
		...ShowPriceitBrandingPriceModelCombinationFragment
		...LoginLinkPriceModelCombinationFragment
		...LanguagePriceModelCombinationFragment
		priceModelCombinationProperties {
			...PriceModelCombinationPropertiesOnlyNameFragment
		}
		workspace {
			...WorkspaceForDiscoverStepFragment
		}
	}
`;

export const SIGNATURE_LINK_PUBLIC_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ASK_EMAIL_PRICE_MODEL_COMBINATION_FRAGMENT}
	${BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_ACCESS_PAGE_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${COMPANY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DESCRIPTION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EMAILS_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ENABLE_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT}
	${FAVICON_PRICE_MODEL_COMBINATION_FRAGMENT}
	${FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SELECTED_TEMPLATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EXPIRATION_DATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${WORKSPACE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT}
	${AVAILABILITY_DATA_TOKEN_FRAGMENT}
	${PRICE_MODEL_COMBINATION_PROPERTIES_ONLY_NAME_FRAGMENT}
	${LOGIN_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment SignatureLinkPublicPriceModelCombinationFragment on PriceModelCombination {
		id
		...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
		...AskEmailPriceModelCombinationFragment
		...BackgroundColorPriceModelCombinationFragment
		...OfferAccessPageThemePriceModelCombinationFragment
		...CompanyNamePriceModelCombinationFragment
		...DescriptionPriceModelCombinationFragment
		...DisplayedThemePriceModelCombinationFragment
		...EmailsPriceModelCombinationFragment
		...EnablePasswordPriceModelCombinationFragment
		...ExpirationDatePriceModelCombinationFragment
		...FaviconPriceModelCombinationFragment
		...FontColorPriceModelCombinationFragment
		...LanguagePriceModelCombinationFragment
		...OnlyNamePriceModelCombinationFragment
		...OfferImagePriceModelCombinationFragment
		...SelectedTemplatePriceModelCombinationFragment
		...ShowPriceitBrandingPriceModelCombinationFragment
		...SummaryPageBackgroundColorPriceModelCombinationFragment
		...SummaryPageFontColorPriceModelCombinationFragment
		token
		workspaceId
		active
		tokenId
		tokenObj {
			...AvailabilityDataTokenFragment
		}
		workspace {
			...WorkspaceForPublicPriceModelFragment
		}
		priceModelCombinationProperties {
			...PriceModelCombinationPropertiesOnlyNameFragment
		}
		...LoginLinkPriceModelCombinationFragment
	}
`;

export const PROTECTED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${WORKSPACE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT}
	fragment ProtectedPriceModelCombinationFragment on ProtectedPriceModelCombination {
		active
		createdAt
		description
		summaryPageBackgroundColor
		summaryPageFontColor
		offerAccessPageTheme
		selectedTemplate
		companyName
		expirationDate
		id
		offerImage
		displayedTheme
		syncDisplayedTheme
		isFavorite
		name
		priceModelCombinationProperties {
			id
			name
		}
		showPriceitBranding
		favicon
		language
		priceModelLinkCombination {
			index
			name
			priceModelId
		}
		token
		tokenId
		enablePassword
		tokenObj {
			id
			active
			token
			expirationDate
			hasPassword
			password
		}
		updatedAt
		wrongCredentials
		shouldLinkVolumes
		askEmailWhenLinkIsOpened
		isQuoteGeneratorActive
		workspace {
			...WorkspaceForPublicPriceModelFragment
		}
	}
`;

export const PROTECTED_PRICE_MODEL_COMBINATION_FOR_DISCOVER_STEP_FRAGMENT = gql`
	${PRICE_MODEL_COMBINATION_PROPERTIES_ONLY_NAME_FRAGMENT}
	${WORKSPACE_FOR_FOR_DISCOVER_STEP_FRAGMENT}
	fragment ProtectedPriceModelCombinationForDiscoverStepFragment on ProtectedPriceModelCombination {
		id
		offerAccessPageTheme
		offerImage
		showPriceitBranding
		loginLink
		language
		priceModelCombinationProperties {
			...PriceModelCombinationPropertiesOnlyNameFragment
		}
		workspace {
			...WorkspaceForDiscoverStepFragment
		}
	}
`;

export const PRICE_MODEL_COMBINATION_CONFIRMATION_FRAGMENT = gql`
	${WORKSPACE_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_ID_PRICE_MODEL_LINK_COMBINATION_FRAGMENT}
	fragment PriceModelCombinationConfirmationFragment on PriceModelCombination {
		...WorkspaceIdPriceModelCombinationFragment
		priceModelLinkCombination {
			...PriceModelIdPriceModelLinkCombinationFragment
		}
	}
`;
