import { gql } from "@apollo/client";

export const OFFER_STATUS_ID_FRAGMENT = gql`
	fragment OfferStatusIdFragment on OfferStatus {
		id
	}
`;

export const OFFER_STATUS_COLOR_FRAGMENT = gql`
	${OFFER_STATUS_ID_FRAGMENT}
	fragment OfferStatusColorFragment on OfferStatus {
		...OfferStatusIdFragment
		color
	}
`;

export const OFFER_STATUS_NAME_FRAGMENT = gql`
	${OFFER_STATUS_ID_FRAGMENT}
	fragment OfferStatusNameFragment on OfferStatus {
		...OfferStatusIdFragment
		name
	}
`;

export const OFFER_STATUS_INDEX_FRAGMENT = gql`
	${OFFER_STATUS_ID_FRAGMENT}
	fragment OfferStatusIndexFragment on OfferStatus {
		...OfferStatusIdFragment
		index
	}
`;

export const OFFER_STATUS_FRAGMENT = gql`
	${OFFER_STATUS_ID_FRAGMENT}
	${OFFER_STATUS_COLOR_FRAGMENT}
	${OFFER_STATUS_NAME_FRAGMENT}
	${OFFER_STATUS_INDEX_FRAGMENT}
	fragment OfferStatusFragment on OfferStatus {
		...OfferStatusIdFragment
		...OfferStatusColorFragment
		...OfferStatusNameFragment
		...OfferStatusIndexFragment
	}
`;
