import { gql } from "@apollo/client";
import { OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_FRAGMENT } from "@graphQl/fragments/offerVersionRuleForRepaymentSchedule";
import { REPAYMENT_SCHEDULE_TVA_FRAGMENT } from "@graphQl/fragments/repaymentScheduleTva";

export const REPAYMENT_SCHEDULE_DURATION_FRAGMENT = gql`
	fragment RepaymentScheduleDurationFragment on RepaymentSchedule {
		duration
	}
`;

export const REPAYMENT_SCHEDULE_DISCOUNT_APPLIED_FRAGMENT = gql`
	fragment RepaymentScheduleDiscountAppliedFragment on RepaymentSchedule {
		discountApplied
	}
`;

export const REPAYMENT_SCHEDULE_RECURRING_DISCOUNT_APPLIED_FRAGMENT = gql`
	fragment RepaymentScheduleRecurringDiscountAppliedFragment on RepaymentSchedule {
		recurringDiscountApplied
	}
`;

export const REPAYMENT_SCHEDULE_APPLIED_RULES_FRAGMENT = gql`
	${OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_FRAGMENT}
	fragment RepaymentScheduleAppliedRulesFragment on RepaymentSchedule {
		appliedRules {
			...OfferVersionRuleForRepaymentScheduleFragment
		}
	}
`;

export const REPAYMENT_SCHEDULE_LIST_PRICE_HTVA_BEFORE_ALL_DISCOUNTS_FRAGMENT = gql`
	fragment RepaymentScheduleListPriceHTVABeforeAllDiscountsFragment on RepaymentSchedule {
		listPriceHTVABeforeAllDiscounts
	}
`;

export const REPAYMENT_SCHEDULE_LIST_PRICE_HTVA_BEFORE_OFFER_VERSION_RULES_FRAGMENT = gql`
	fragment RepaymentScheduleListPriceHTVABeforeOfferVersionRulesFragment on RepaymentSchedule {
		listPriceHTVABeforeOfferVersionRules
	}
`;

export const REPAYMENT_SCHEDULE_NET_PRICE_HTVA_AFTER_ALL_DISCOUNTS_FRAGMENT = gql`
	fragment RepaymentScheduleNetPriceHTVAAfterAllDiscountsFragment on RepaymentSchedule {
		netPriceHTVAAfterAllDiscounts
	}
`;

export const REPAYMENT_SCHEDULE_NET_PRICE_WITH_VAT_AFTER_ALL_DISCOUNTS_FRAGMENT = gql`
	fragment RepaymentScheduleNetPriceWithVATAfterAllDiscountsFragment on RepaymentSchedule {
		netPriceWithVATAfterAllDiscounts
	}
`;

export const REPAYMENT_SCHEDULE_FRAGMENT = gql`
	${REPAYMENT_SCHEDULE_DURATION_FRAGMENT}
	${REPAYMENT_SCHEDULE_APPLIED_RULES_FRAGMENT}
	${REPAYMENT_SCHEDULE_TVA_FRAGMENT}
	${REPAYMENT_SCHEDULE_DISCOUNT_APPLIED_FRAGMENT}
	${REPAYMENT_SCHEDULE_RECURRING_DISCOUNT_APPLIED_FRAGMENT}
	${REPAYMENT_SCHEDULE_LIST_PRICE_HTVA_BEFORE_ALL_DISCOUNTS_FRAGMENT}
	${REPAYMENT_SCHEDULE_LIST_PRICE_HTVA_BEFORE_OFFER_VERSION_RULES_FRAGMENT}
	${REPAYMENT_SCHEDULE_NET_PRICE_HTVA_AFTER_ALL_DISCOUNTS_FRAGMENT}
	${REPAYMENT_SCHEDULE_NET_PRICE_WITH_VAT_AFTER_ALL_DISCOUNTS_FRAGMENT}
	fragment RepaymentScheduleFragment on RepaymentSchedule {
		...RepaymentScheduleDurationFragment
		...RepaymentScheduleAppliedRulesFragment
		...RepaymentScheduleDiscountAppliedFragment
		...RepaymentScheduleRecurringDiscountAppliedFragment
		...RepaymentScheduleListPriceHTVABeforeAllDiscountsFragment
		...RepaymentScheduleListPriceHTVABeforeOfferVersionRulesFragment
		...RepaymentScheduleNetPriceHTVAAfterAllDiscountsFragment
		...RepaymentScheduleNetPriceWithVATAfterAllDiscountsFragment
		tva {
			...RepaymentScheduleTVAFragment
		}
	}
`;
