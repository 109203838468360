import { gql } from "@apollo/client";

export const ROLE_FRAGMENT = gql`
	fragment RoleFragment on Role {
		id
		name
		protected
		# workspace
		workspaceId
		index
		defaultRoleId
		description
		workspace_read
		workspace_edit
		workspace_delete
		company_read
		company_editGeneral
		company_editDesign
		company_editCGU
		company_editVAT
		billing_read
		billing_edit
		plan_edit
		user_read
		user_create
		user_editRole
		user_delete
		role_read
		role_create
		role_edit
		role_delete
		integration_read
		integration_create
		integration_edit
		integration_delete
		feature_read
		feature_create
		feature_edit
		feature_duplicate
		feature_delete
		priceModel_read
		priceModel_create
		priceModel_edit
		priceModel_duplicate
		priceModel_delete
		priceModelCombination_read
		priceModelCombination_create
		priceModelCombination_edit
		priceModelCombination_duplicate
		priceModelCombination_delete
		offer_read
		offer_create
		offer_priceModelCombination_read
		offer_personalize
		offer_edit
		offer_delete
		psa
		period
		product
		variableCost
		fixedCost
		profitability
		potential
		simulation
		costBased
		distribution
		roadmap
		perceivedValue
		valueMap
		tips
		ai
	}
`;
