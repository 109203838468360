import { gql } from "@apollo/client";
import { CONNECTION_OWNER_FRAGMENT } from "@graphQl/fragments/user";

export const PAYMENT_CONNECTION_SERVICE_FRAGMENT = gql`
	fragment PaymentConnectionServiceFragment on PaymentService {
		id
		name
	}
`;
export const PAYMENT_CONNECTION_FRAGMENT = gql`
	${CONNECTION_OWNER_FRAGMENT}
	${PAYMENT_CONNECTION_SERVICE_FRAGMENT}
	fragment PaymentConnectionFragment on StripeConnection {
		id
		name
		owner {
			...ConnectionOwnerFragment
		}
		service {
			...PaymentConnectionServiceFragment
		}
		accountStatus
	}
`;

export const STRIPE_CONNECTION_SERVICE_INFOS_FRAGMENT = gql`
	${PAYMENT_CONNECTION_SERVICE_FRAGMENT}
	fragment StripeConnectionServiceInfosFragment on StripeConnection {
		id
		name
		service {
			...PaymentConnectionServiceFragment
		}
	}
`;
