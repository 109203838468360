import { gql } from "@apollo/client";
import {
	FROM_VOLUME_RANGE_FRAGMENT,
	INDEX_FROM_TO_VOLUME_RANGE_FRAGMENT,
	TO_VOLUME_RANGE_FRAGMENT,
	VOLUME_DISCOUNT_ID_VOLUME_RANGE_FRAGMENT,
	VOLUME_DISCOUNT_RANGE_DATA_TO_COMPUTE_IF_DISABLED_FRAGMENT,
	VOLUME_RANGE_FOR_PUBLIC_FRAGMENT,
	VOLUME_RANGE_FRAGMENT,
	VOLUME_RANGE_VOLUME_DISCOUNT_RANGE_DISCOUNT_FRAGMENT,
} from "@graphQl/fragments/volumeRangeFragment";
import {
	VOLUME_DISCOUNT_LINK_ID_FRAGMENT,
	VOLUME_DISCOUNT_LINK_VOLUME_DISCOUNTS_FRAGMENT,
} from "./volumeDiscountLink";

export const BASED_ON_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment BasedOnVolumeDiscountFragment on VolumeDiscount {
		id
		basedOn
	}
`;

export const TIME_METRIC_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment TimeMetricDiscountFragment on VolumeDiscount {
		id
		timeMetric
	}
`;

export const UNIT_METRIC_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment UnitMetricVolumeDiscountFragment on VolumeDiscount {
		id
		unitMetric
	}
`;

export const IS_DECREASING_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment IsDecreasingVolumeDiscountFragment on VolumeDiscount {
		id
		isDecreasing
	}
`;

export const DISPLAYED_AS_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment DisplayedAsVolumeDiscountFragment on VolumeDiscount {
		id
		displayedAs
	}
`;

export const DISPLAY_PRICE_PER_UNIT_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment DisplayPricePerUnitVolumeDiscountFragment on VolumeDiscount {
		id
		displayPricePerUnit
	}
`;

export const UNIT_METRICS_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment UnitMetricsDiscountFragment on VolumeDiscount {
		id
		unitMetric
		pluralUnitMetric
		uuid
	}
`;

export const TYPE_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment TypeVolumeDiscountFragment on VolumeDiscount {
		id
		type
	}
`;

export const DECIMALS_NUMBER_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment DecimalsNumberVolumeDiscountFragment on VolumeDiscount {
		id
		decimalsNumber
	}
`;

export const DISCOUNT_LINK_ID_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment DiscountLinkIdVolumeDiscountFragment on VolumeDiscount {
		id
		volumeDiscountLinkId
	}
`;

export const VOLUME_DISCOUNT_VOLUME_RANGES_ID_FRAGMENT = gql`
	${VOLUME_DISCOUNT_ID_VOLUME_RANGE_FRAGMENT}
	fragment VolumeDiscountVolumeRangesIdFragment on VolumeDiscount {
		id
		volumeRange {
			...VolumeDiscountIdVolumeRangeFragment
		}
	}
`;

export const VOLUME_DISCOUNT_VOLUME_RANGES_INDEX_FROM_TO_FRAGMENT = gql`
	${INDEX_FROM_TO_VOLUME_RANGE_FRAGMENT}
	fragment VolumeDiscountVolumeRangesIndexFromToFragment on VolumeDiscount {
		id
		volumeRange {
			...IndexFromToVolumeRangeFragment
		}
	}
`;

export const VOLUME_DISCOUNT_VOLUME_DISCOUNT_LINK_FRAGMENT = gql`
	${VOLUME_DISCOUNT_LINK_VOLUME_DISCOUNTS_FRAGMENT}
	fragment VolumeDiscountVolumeDiscountLinkFragment on VolumeDiscount {
		id
		volumeDiscountLinkId
		volumeDiscountLink {
			...VolumeDiscountLinkVolumeDiscountsFragment
		}
	}
`;

export const VOLUME_DISCOUNT_VOLUME_DISCOUNT_LINKED_FRAGMENT = gql`
	${VOLUME_DISCOUNT_LINK_VOLUME_DISCOUNTS_FRAGMENT}
	fragment VolumeDiscountVolumeDiscountLinkedFragment on VolumeDiscount {
		id
		volumeDiscountLinkId
		volumeDiscountLink {
			...VolumeDiscountLinkVolumeDiscountsFragment
		}
	}
`;

export const VOLUME_DISCOUNT_DATA_TO_COMPUTE_SAVINGS_FRAGMENT = gql`
	${VOLUME_RANGE_VOLUME_DISCOUNT_RANGE_DISCOUNT_FRAGMENT}
	${TYPE_VOLUME_DISCOUNT_FRAGMENT}
	${BASED_ON_VOLUME_DISCOUNT_FRAGMENT}
	${UNIT_METRICS_VOLUME_DISCOUNT_FRAGMENT}
	${VOLUME_DISCOUNT_VOLUME_DISCOUNT_LINK_FRAGMENT}
	${DISPLAYED_AS_VOLUME_DISCOUNT_FRAGMENT}
	${DISPLAY_PRICE_PER_UNIT_VOLUME_DISCOUNT_FRAGMENT}
	fragment VolumeDiscountDataToComputeSavingsFragment on VolumeDiscount {
		id
		...TypeVolumeDiscountFragment
		...BasedOnVolumeDiscountFragment
		...UnitMetricsDiscountFragment
		...VolumeDiscountVolumeDiscountLinkFragment
		...DisplayedAsVolumeDiscountFragment
		...DisplayPricePerUnitVolumeDiscountFragment
		uuid
		volumeRange {
			...VolumeRangeVolumeDiscountRangeDiscountFragment
		}
	}
`;

export const VOLUME_DISCOUNT_VOLUME_RANGES_FROM_FRAGMENT = gql`
	${FROM_VOLUME_RANGE_FRAGMENT}
	fragment VolumeDiscountVolumeRangesFromFragment on VolumeDiscount {
		id
		volumeRange {
			...FromVolumeRangeFragment
		}
	}
`;

export const VOLUME_DISCOUNT_VOLUME_RANGES_FROM_TO_FRAGMENT = gql`
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	fragment VolumeDiscountVolumeRangesFromToFragment on VolumeDiscount {
		id
		volumeRange {
			...FromVolumeRangeFragment
			...ToVolumeRangeFragment
		}
	}
`;

export const VOLUME_DISCOUNT_FOR_PUBLIC_FRAGMENT = gql`
	${VOLUME_RANGE_FOR_PUBLIC_FRAGMENT}
	${BASED_ON_VOLUME_DISCOUNT_FRAGMENT}
	${TYPE_VOLUME_DISCOUNT_FRAGMENT}
	${DECIMALS_NUMBER_VOLUME_DISCOUNT_FRAGMENT}
	${VOLUME_DISCOUNT_VOLUME_DISCOUNT_LINK_FRAGMENT}
	fragment VolumeDiscountForPublicFragment on VolumeDiscount {
		...BasedOnVolumeDiscountFragment
		id
		basedOn
		unitMetric
		pluralUnitMetric
		timeMetric
		isDecreasing
		displayedAs
		displayPricePerUnit
		...DecimalsNumberVolumeDiscountFragment
		...VolumeDiscountVolumeDiscountLinkFragment
		volumeRange {
			...VolumeRangeForPublicFragment
		}
		isLocked
		...TypeVolumeDiscountFragment
	}
`;

export const VOLUME_DISCOUNT_FRAGMENT = gql`
	${VOLUME_RANGE_FRAGMENT}
	${BASED_ON_VOLUME_DISCOUNT_FRAGMENT}
	${TYPE_VOLUME_DISCOUNT_FRAGMENT}
	${VOLUME_DISCOUNT_VOLUME_DISCOUNT_LINK_FRAGMENT}
	fragment VolumeDiscountFragment on VolumeDiscount {
		...BasedOnVolumeDiscountFragment
		createdAt
		createdBy
		id
		decimalsNumber
		updatedAt
		updatedBy
		basedOn
		unitMetric
		pluralUnitMetric
		timeMetric
		isDecreasing
		displayedAs
		uuid
		...VolumeDiscountVolumeDiscountLinkFragment
		volumeRange {
			...VolumeRangeFragment
		}
		isLocked
		...TypeVolumeDiscountFragment
	}
`;

export const RULES_SECTION_VOLUME_DISCOUNT_FRAGMENT = gql`
	${BASED_ON_VOLUME_DISCOUNT_FRAGMENT}
	${UNIT_METRIC_VOLUME_DISCOUNT_FRAGMENT}
	${FROM_VOLUME_RANGE_FRAGMENT}
	${VOLUME_DISCOUNT_LINK_ID_FRAGMENT}
	fragment RulesSectionVolumeDiscountFragment on VolumeDiscount {
		...BasedOnVolumeDiscountFragment
		...UnitMetricVolumeDiscountFragment
		volumeDiscountLink {
			...VolumeDiscountLinkIdFragment
		}
		volumeRange {
			...FromVolumeRangeFragment
		}
	}
`;

export const VOLUME_RANGE_DATA_TO_COMPUTE_IF_DISABLED_FRAGMENT = gql`
	${UNIT_METRICS_VOLUME_DISCOUNT_FRAGMENT}
	${VOLUME_DISCOUNT_RANGE_DATA_TO_COMPUTE_IF_DISABLED_FRAGMENT}
	${VOLUME_DISCOUNT_VOLUME_DISCOUNT_LINK_FRAGMENT}
	fragment VolumeRangeDataToComputeIfDisabledFragment on VolumeDiscount {
		...UnitMetricsDiscountFragment
		...VolumeDiscountVolumeDiscountLinkFragment
		uuid
		volumeRange {
			...VolumeDiscountRangeDataToComputeIfDisabledFragment
		}
	}
`;

export const VOLUME_DISCOUNT_DATA_FOR_VOLUME_DEPENDENCY_FRAGMENT = gql`
	fragment VolumeDiscountDataForVolumeDependencyFragment on VolumeDiscount {
		id
		volumeDiscountLinkId
		unitMetric
	}
`;
