import { gql } from "@apollo/client";

export const PRICE_MODEL_TIME_METRIC_ID_FRAGMENT = gql`
	fragment PriceModelTimeMetricIdFragment on PriceModelTimeMetric {
		id
	}
`;

export const PRICE_MODEL_TIME_METRIC_IS_TOTAL_COMMITMENT_FRAGMENT = gql`
	fragment PriceModelTimeMetricIsTotalCommitmentFragment on PriceModelTimeMetric {
		id
		isTotalCommitment
	}
`;

export const PRICE_MODEL_TIME_METRIC_IS_MANDATORY_FRAGMENT = gql`
	fragment PriceModelTimeMetricIsMandatoryFragment on PriceModelTimeMetric {
		id
		isMandatory
	}
`;

export const PRICE_MODEL_TIME_METRIC_USED_AS_PRIMARY_FRAGMENT = gql`
	fragment PriceModelTimeMetricUsedAsPrimaryFragment on PriceModelTimeMetric {
		id
		usedAsPrimary
	}
`;

export const PRICE_MODEL_TIME_METRIC_USED_AS_SECONDARY_FRAGMENT = gql`
	fragment PriceModelTimeMetricUsedAsSecondaryFragment on PriceModelTimeMetric {
		id
		usedAsSecondary
	}
`;

export const PRICE_MODEL_TIME_METRIC_TERM_FRAGMENT = gql`
	fragment PriceModelTimeMetricTermFragment on PriceModelTimeMetric {
		id
		term
	}
`;

export const PRICE_MODEL_TIME_METRIC_DATA_FRAGMENT = gql`
	${PRICE_MODEL_TIME_METRIC_IS_MANDATORY_FRAGMENT}
	${PRICE_MODEL_TIME_METRIC_IS_TOTAL_COMMITMENT_FRAGMENT}
	${PRICE_MODEL_TIME_METRIC_TERM_FRAGMENT}
	${PRICE_MODEL_TIME_METRIC_USED_AS_PRIMARY_FRAGMENT}
	${PRICE_MODEL_TIME_METRIC_USED_AS_SECONDARY_FRAGMENT}
	fragment PriceModelTimeMetricDataFragment on PriceModelTimeMetric {
		id
		priceModelId
		uuid
		...PriceModelTimeMetricIsMandatoryFragment
		...PriceModelTimeMetricIsTotalCommitmentFragment
		...PriceModelTimeMetricUsedAsPrimaryFragment
		...PriceModelTimeMetricUsedAsSecondaryFragment
		...PriceModelTimeMetricTermFragment
	}
`;
