import { gql } from "@apollo/client";
import { PROFITABILITY_THRESHOLD_RANGE_FRAGMENT } from "@graphQl/fragments/profitabilityThresholdRangeFragment";

export const ID_PROFITABILITY_THRESHOLD_FRAGMENT = gql`
	fragment IdProfitabilityThresholdFragment on ProfitabilityThreshold {
		id
	}
`;

export const PRICE_MODEL_COMBINATION_ID_PROFITABILITY_THRESHOLD_FRAGMENT = gql`
	fragment PriceModelCombinationIdProfitabilityThresholdFragment on ProfitabilityThreshold {
		id
		priceModelCombinationId
	}
`;

export const SHOW_TOTAL_PROFITABILITY_PROFITABILITY_THRESHOLD_FRAGMENT = gql`
	fragment ShowTotalProfitabilityProfitabilityThresholdFragment on ProfitabilityThreshold {
		id
		showTotalProfitability
	}
`;

export const USER_IDS_FOR_PROFITABILITIES_PROFITABILITY_THRESHOLD_FRAGMENT = gql`
	fragment UserIdsForProfitabilitiesProfitabilityThresholdFragment on ProfitabilityThreshold {
		id
		userIdsForProfitabilities
	}
`;

export const PROFITABILITY_THRESHOLD_RANGES_PROFITABILITY_THRESHOLD_FRAGMENT = gql`
	${PROFITABILITY_THRESHOLD_RANGE_FRAGMENT}
	fragment ProfitabilityThresholdRangesProfitabilityThresholdFragment on ProfitabilityThreshold {
		id
		profitabilityThresholdRanges {
			...ProfitabilityThresholdRangeFragment
		}
	}
`;

export const PROFITABILITY_THRESHOLD_FRAGMENT = gql`
	${ID_PROFITABILITY_THRESHOLD_FRAGMENT}
	${PRICE_MODEL_COMBINATION_ID_PROFITABILITY_THRESHOLD_FRAGMENT}
	${SHOW_TOTAL_PROFITABILITY_PROFITABILITY_THRESHOLD_FRAGMENT}
	${USER_IDS_FOR_PROFITABILITIES_PROFITABILITY_THRESHOLD_FRAGMENT}
	${PROFITABILITY_THRESHOLD_RANGES_PROFITABILITY_THRESHOLD_FRAGMENT}
	fragment ProfitabilityThresholdFragment on ProfitabilityThreshold {
		...IdProfitabilityThresholdFragment
		...PriceModelCombinationIdProfitabilityThresholdFragment
		...ShowTotalProfitabilityProfitabilityThresholdFragment
		...UserIdsForProfitabilitiesProfitabilityThresholdFragment
		...ProfitabilityThresholdRangesProfitabilityThresholdFragment
	}
`;
