import React, { useContext } from "react";
import * as themes from "./userThemes";
import { ThemeContext, ThemeProvider } from "styled-components";

export const themeList = {
	theme1: "theme1",
};

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeComponent = ({ children, theme, mode }) => {
	return <ThemeProvider theme={themes[theme][mode]}>{children}</ThemeProvider>;
};
