import { gql } from "@apollo/client";

export const ID_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	fragment IdProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		id
	}
`;

export const PROFITABILITY_THRESHOLD_ID_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	fragment ProfitabilityThresholdIdProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		id
		profitabilityThresholdId
	}
`;

export const INDEX_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	fragment IndexProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		id
		index
	}
`;

export const THRESHOLD_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	fragment ThresholdProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		id
		threshold
	}
`;

export const COLOR_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	fragment ColorProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		id
		color
	}
`;

export const APPROVAL_NEEDED_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	fragment ApprovalNeededProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		id
		approvalNeeded
	}
`;

export const MESSAGE_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	fragment MessageProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		id
		message
	}
`;

export const ERRORS_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	fragment ErrorsProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		id
		errors {
			id
			threshold
		}
	}
`;

export const IS_UNLIMITED_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	fragment IsUnlimitedProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		id
		isUnlimited
	}
`;

export const THRESHOLD_AND_ISUNLIMITED_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	fragment ThresholdAndIsUnlimitedProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		id
		threshold
		isUnlimited
	}
`;

export const PROFITABILITY_THRESHOLD_RANGE_FRAGMENT = gql`
	${ID_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT}
	${PROFITABILITY_THRESHOLD_ID_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT}
	${INDEX_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT}
	${THRESHOLD_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT}
	${COLOR_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT}
	${APPROVAL_NEEDED_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT}
	${MESSAGE_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT}
	${ERRORS_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT}
	${IS_UNLIMITED_PROFITABILITY_THRESHOLD_RANGE_FRAGMENT}
	fragment ProfitabilityThresholdRangeFragment on ProfitabilityThresholdRange {
		...IdProfitabilityThresholdRangeFragment
		...ProfitabilityThresholdIdProfitabilityThresholdRangeFragment
		...IndexProfitabilityThresholdRangeFragment
		...ThresholdProfitabilityThresholdRangeFragment
		...ColorProfitabilityThresholdRangeFragment
		...ApprovalNeededProfitabilityThresholdRangeFragment
		...MessageProfitabilityThresholdRangeFragment
		...ErrorsProfitabilityThresholdRangeFragment
		...IsUnlimitedProfitabilityThresholdRangeFragment
	}
`;
