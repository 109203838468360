import { gql } from "@apollo/client";

export const SELECT_OPTION_PRICE_ERROR_BOOSTER_FRAGMENT = gql`
	fragment SelectOptionPriceErrorBoosterFragment on SelectOptionPriceError {
		id
		isBoosterAllowed
	}
`;

export const SELECT_OPTION_PRICE_ERROR_LOWER_THAN_PREVIOUS_FRAGMENT = gql`
	fragment SelectOptionPriceErrorLowerThanPreviousFragment on SelectOptionPriceError {
		id
		isLowerThanPrevious
	}
`;

export const SELECT_OPTION_PRICE_ERROR_FRAGMENT = gql`
	${SELECT_OPTION_PRICE_ERROR_BOOSTER_FRAGMENT}
	${SELECT_OPTION_PRICE_ERROR_LOWER_THAN_PREVIOUS_FRAGMENT}
	fragment SelectOptionPriceErrorFragment on SelectOptionError {
		id
		price {
			...SelectOptionPriceErrorBoosterFragment
			...SelectOptionPriceErrorLowerThanPreviousFragment
		}
	}
`;

export const SELECT_OPTION_SCORE_WARNING_FRAGMENT = gql`
	fragment SelectOptionScoreWarningFragment on SelectOption {
		id
		warnings {
			id
			score
		}
	}
`;

export const SELECT_OPTION_IS_BOOSTER_ALLOWED_FRAGMENT = gql`
	fragment SelectOptionIsBoosterAllowedFragment on SelectOption {
		id
		isBoosterAllowed
	}
`;

export const SELECT_OPTION_VALUE_FRAGMENT = gql`
	fragment SelectOptionValueFragment on SelectOption {
		id
		value
	}
`;

export const SELECT_OPTION_INDEX_FRAGMENT = gql`
	fragment SelectOptionIndexFragment on SelectOption {
		id
		index
	}
`;

export const SELECT_OPTION_SCORE_FRAGMENT = gql`
	fragment SelectOptionScoreFragment on SelectOption {
		id
		score
	}
`;

export const SELECT_OPTION_PRICE_FRAGMENT = gql`
	fragment SelectOptionPriceFragment on SelectOption {
		id
		price
	}
`;

export const SELECT_OPTION_COST_FRAGMENT = gql`
	fragment SelectOptionCostFragment on SelectOption {
		id
		cost
	}
`;

export const SELECT_OPTION_PROFIT_FRAGMENT = gql`
	fragment SelectOptionProfitFragment on SelectOption {
		id
		profit
	}
`;

export const SELECT_OPTION_DESCRIPTION_FRAGMENT = gql`
	fragment SelectOptionDescriptionFragment on SelectOption {
		id
		description
	}
`;

export const SELECT_OPTION_DATA_TO_REFRESH_ERRORS_FRAGMENT = gql`
	fragment SelectOptionDataToRefreshErrorsFragment on SelectOption {
		id
		levelData {
			id
			levelDataPacks {
				packId
				levelDataId
				errors {
					parentId
					parentType
					field
					type
				}
			}
		}
	}
`;

export const SELECT_OPTION_WITHOUT_INDEX_FRAGMENT = gql`
	${SELECT_OPTION_VALUE_FRAGMENT}
	${SELECT_OPTION_SCORE_FRAGMENT}
	${SELECT_OPTION_IS_BOOSTER_ALLOWED_FRAGMENT}
	${SELECT_OPTION_PRICE_FRAGMENT}
	${SELECT_OPTION_COST_FRAGMENT}
	${SELECT_OPTION_PROFIT_FRAGMENT}
	${SELECT_OPTION_PRICE_ERROR_FRAGMENT}
	${SELECT_OPTION_SCORE_WARNING_FRAGMENT}
	${SELECT_OPTION_DESCRIPTION_FRAGMENT}
	fragment SelectOptionWithoutIndexFragment on SelectOption {
		id
		...SelectOptionValueFragment
		...SelectOptionScoreFragment
		...SelectOptionIsBoosterAllowedFragment
		...SelectOptionPriceFragment
		...SelectOptionCostFragment
		...SelectOptionProfitFragment
		...SelectOptionScoreWarningFragment
		...SelectOptionDescriptionFragment
		errors {
			...SelectOptionPriceErrorFragment
		}
	}
`;

export const SELECT_OPTION_FRAGMENT = gql`
	${SELECT_OPTION_VALUE_FRAGMENT}
	${SELECT_OPTION_INDEX_FRAGMENT}
	${SELECT_OPTION_SCORE_FRAGMENT}
	${SELECT_OPTION_IS_BOOSTER_ALLOWED_FRAGMENT}
	${SELECT_OPTION_PRICE_FRAGMENT}
	${SELECT_OPTION_COST_FRAGMENT}
	${SELECT_OPTION_PROFIT_FRAGMENT}
	${SELECT_OPTION_DESCRIPTION_FRAGMENT}
	fragment SelectOptionFragment on SelectOption {
		id
		selectId
		...SelectOptionValueFragment
		...SelectOptionIndexFragment
		...SelectOptionScoreFragment
		...SelectOptionIsBoosterAllowedFragment
		...SelectOptionPriceFragment
		...SelectOptionCostFragment
		...SelectOptionProfitFragment
		...SelectOptionDescriptionFragment
	}
`;

export const PUBLIC_SELECT_OPTION_FRAGMENT = gql`
	${SELECT_OPTION_VALUE_FRAGMENT}
	${SELECT_OPTION_INDEX_FRAGMENT}
	${SELECT_OPTION_SCORE_FRAGMENT}
	${SELECT_OPTION_IS_BOOSTER_ALLOWED_FRAGMENT}
	${SELECT_OPTION_PRICE_FRAGMENT}
	${SELECT_OPTION_COST_FRAGMENT}
	${SELECT_OPTION_DESCRIPTION_FRAGMENT}
	fragment PublicSelectOptionFragment on SelectOption {
		id
		selectId
		...SelectOptionValueFragment
		...SelectOptionIndexFragment
		...SelectOptionScoreFragment
		...SelectOptionIsBoosterAllowedFragment
		...SelectOptionPriceFragment
		...SelectOptionCostFragment
		...SelectOptionDescriptionFragment
	}
`;
