import { gql } from "@apollo/client";
import { CONNECTION_OWNER_FRAGMENT } from "@graphQl/fragments/user";

export const SIGNATURE_CONNECTION_SERVICE_FRAGMENT = gql`
	fragment SignatureConnectionServiceFragment on SignatureService {
		id
		name
	}
`;

export const SIGNATURE_CONNECTION_SERVICE_DETAILS_FRAGMENT = gql`
	fragment SignatureConnectionServiceDetailsFragment on SignatureService {
		id
		name
		description
	}
`;

export const SIGNATURE_CONNECTION_ACCOUNT_FRAGMENT = gql`
	fragment SignatureConnectionAccountFragment on SignatureConnectionAccount {
		id
		name
	}
`;

export const SIGNATURE_CONNECTION_USER_FRAGMENT = gql`
	fragment SignatureConnectionUserFragment on SignatureConnectionUser {
		id
		email
		firstName
		lastName
	}
`;

export const SIGNATURE_CONNECTION_FRAGMENT = gql`
	${CONNECTION_OWNER_FRAGMENT}
	${SIGNATURE_CONNECTION_SERVICE_FRAGMENT}
	${SIGNATURE_CONNECTION_ACCOUNT_FRAGMENT}
	${SIGNATURE_CONNECTION_USER_FRAGMENT}
	fragment SignatureConnectionFragment on SignatureConnection {
		id
		name
		owner {
			...ConnectionOwnerFragment
		}
		service {
			...SignatureConnectionServiceFragment
		}
		user {
			...SignatureConnectionUserFragment
		}
		selectedAccount {
			...SignatureConnectionAccountFragment
		}
		active
	}
`;

export const SIGNATURE_CONNECTION_SERVICE_INFOS_FRAGMENT = gql`
	fragment SignatureConnectionServiceInfosFragment on SignatureConnection {
		id
		name
		service {
			id
			name
		}
		selectedAccount {
			id
			name
		}
	}
`;
