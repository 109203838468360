import { gql } from "@apollo/client";

export const NUMERICAL_RANGE_PRICE_ERROR_BOOSTER_FRAGMENT = gql`
	fragment NumericalRangePriceErrorBoosterFragment on NumericalRangePriceError {
		id
		isBoosterAllowed
	}
`;

export const NUMERICAL_RANGE_PRICE_ERROR_LOWER_THAN_PREVIOUS_FRAGMENT = gql`
	fragment NumericalRangePriceErrorLowerThanPreviousFragment on NumericalRangePriceError {
		id
		isLowerThanPrevious
	}
`;

export const NUMERICAL_RANGE_FROM_ERROR_FRAGMENT = gql`
	fragment NumericalRangeFromErrorFragment on NumericalRangeError {
		id
		from
	}
`;

export const NUMERICAL_RANGE_PRICE_ERROR_FRAGMENT = gql`
	${NUMERICAL_RANGE_PRICE_ERROR_BOOSTER_FRAGMENT}
	${NUMERICAL_RANGE_PRICE_ERROR_LOWER_THAN_PREVIOUS_FRAGMENT}
	fragment NumericalRangePriceErrorFragment on NumericalRangeError {
		id
		price {
			...NumericalRangePriceErrorBoosterFragment
			...NumericalRangePriceErrorLowerThanPreviousFragment
		}
	}
`;

export const NUMERICAL_RANGE_SCORE_WARNING_FRAGMENT = gql`
	fragment NumericalRangeScoreWarningFragment on NumericalRange {
		id
		warnings {
			id
			score
		}
	}
`;

export const NUMERICAL_RANGE_IS_BOOSTER_ALLOWED_FRAGMENT = gql`
	fragment NumericalRangeIsBoosterAllowedFragment on NumericalRange {
		id
		isBoosterAllowed
	}
`;

export const NUMERICAL_RANGE_FROM_NAME_UNLIMITED_FRAGMENT = gql`
	fragment NumericalRangeFromNameUnlimitedFragment on NumericalRange {
		id
		name
		from
		isUnlimited
	}
`;

export const NUMERICAL_RANGE_FROM_FRAGMENT = gql`
	fragment NumericalRangeFromFragment on NumericalRange {
		id
		from
	}
`;

export const NUMERICAL_RANGE_NAME_FRAGMENT = gql`
	fragment NumericalRangeNameFragment on NumericalRange {
		id
		name
	}
`;

export const NUMERICAL_RANGE_UNLIMITED_FRAGMENT = gql`
	fragment NumericalIsUnlimitedNameFragment on NumericalRange {
		id
		isUnlimited
	}
`;

export const NUMERICAL_RANGE_TO_FRAGMENT = gql`
	fragment NumericalRangeToFragment on NumericalRange {
		id
		to
	}
`;

export const NUMERICAL_RANGE_MEDIA_URL_FRAGMENT = gql`
	fragment NumericalRangeMediaUrlFragment on NumericalRange {
		id
		mediaURL
	}
`;

export const NUMERICAL_RANGE_LINK_FRAGMENT = gql`
	fragment NumericalRangeLinkFragment on NumericalRange {
		id
		link
	}
`;

export const NUMERICAL_RANGE_INDEX_FRAGMENT = gql`
	fragment NumericalRangeIndexFragment on NumericalRange {
		id
		index
	}
`;

export const NUMERICAL_RANGE_FROM_NAME_INDEX_IS_UNLIMITED_FRAGMENT = gql`
	${NUMERICAL_RANGE_FROM_NAME_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_INDEX_FRAGMENT}
	fragment NumericalRangeFromNameIndexIsUnlimitedFragment on NumericalRange {
		...NumericalRangeFromNameUnlimitedFragment
		...NumericalRangeIndexFragment
	}
`;

export const NUMERICAL_RANGE_SCORE_FRAGMENT = gql`
	fragment NumericalRangeScoreFragment on NumericalRange {
		id
		score
	}
`;

export const NUMERICAL_RANGE_PRICE_FRAGMENT = gql`
	fragment NumericalRangePriceFragment on NumericalRange {
		id
		price
	}
`;

export const NUMERICAL_RANGE_COST_FRAGMENT = gql`
	fragment NumericalRangeCostFragment on NumericalRange {
		id
		cost
	}
`;

export const NUMERICAL_RANGE_PROFIT_FRAGMENT = gql`
	fragment NumericalRangeProfitFragment on NumericalRange {
		id
		profit
	}
`;

export const NUMERICAL_RANGE_DESCRIPTION_FRAGMENT = gql`
	fragment NumericalRangeDescriptionFragment on NumericalRange {
		id
		description
	}
`;

export const NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT = gql`
	fragment NumericalRangeIsUnlimitedFragment on NumericalRange {
		id
		isUnlimited
	}
`;

export const NUMERICAL_RANGE_DATA_TO_REFRESH_ERRORS_FRAGMENT = gql`
	fragment NumericalRangeDataToRefreshErrorsFragment on NumericalRange {
		id
		levelData {
			id
			levelDataPacks {
				packId
				levelDataId
				errors {
					parentId
					parentType
					field
					type
				}
			}
		}
	}
`;

export const NUMERICAL_RANGE_WITHOUT_INDEX_FRAGMENT = gql`
	${NUMERICAL_RANGE_FROM_NAME_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_SCORE_FRAGMENT}
	${NUMERICAL_RANGE_IS_BOOSTER_ALLOWED_FRAGMENT}
	${NUMERICAL_RANGE_PRICE_FRAGMENT}
	${NUMERICAL_RANGE_COST_FRAGMENT}
	${NUMERICAL_RANGE_PROFIT_FRAGMENT}
	${NUMERICAL_RANGE_PRICE_ERROR_FRAGMENT}
	${NUMERICAL_RANGE_SCORE_WARNING_FRAGMENT}
	${NUMERICAL_RANGE_FROM_ERROR_FRAGMENT}
	${NUMERICAL_RANGE_DESCRIPTION_FRAGMENT}
	${NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT}
	fragment NumericalRangeWithoutIndexFragment on NumericalRange {
		id
		...NumericalRangeFromNameUnlimitedFragment
		...NumericalRangeScoreFragment
		...NumericalRangeIsBoosterAllowedFragment
		...NumericalRangePriceFragment
		...NumericalRangeCostFragment
		...NumericalRangeProfitFragment
		...NumericalRangeScoreWarningFragment
		...NumericalRangeDescriptionFragment
		...NumericalRangeIsUnlimitedFragment
		errors {
			...NumericalRangePriceErrorFragment
			...NumericalRangeFromErrorFragment
		}
	}
`;

export const NUMERICAL_RANGE_FRAGMENT = gql`
	${NUMERICAL_RANGE_FROM_NAME_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_SCORE_FRAGMENT}
	${NUMERICAL_RANGE_IS_BOOSTER_ALLOWED_FRAGMENT}
	${NUMERICAL_RANGE_INDEX_FRAGMENT}
	${NUMERICAL_RANGE_PRICE_FRAGMENT}
	${NUMERICAL_RANGE_COST_FRAGMENT}
	${NUMERICAL_RANGE_PROFIT_FRAGMENT}
	${NUMERICAL_RANGE_DESCRIPTION_FRAGMENT}
	${NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_TO_FRAGMENT}
	${NUMERICAL_RANGE_MEDIA_URL_FRAGMENT}
	${NUMERICAL_RANGE_LINK_FRAGMENT}
	fragment NumericalRangeFragment on NumericalRange {
		id
		numericalId
		...NumericalRangeFromNameUnlimitedFragment
		...NumericalRangeToFragment
		...NumericalRangeScoreFragment
		...NumericalRangeIsBoosterAllowedFragment
		...NumericalRangePriceFragment
		...NumericalRangeCostFragment
		...NumericalRangeProfitFragment
		...NumericalRangeIndexFragment
		...NumericalRangeDescriptionFragment
		...NumericalRangeIsUnlimitedFragment
		...NumericalRangeMediaUrlFragment
		...NumericalRangeLinkFragment
		errors {
			id
			from
		}
	}
`;

export const PUBLIC_NUMERICAL_RANGE_FRAGMENT = gql`
	${NUMERICAL_RANGE_FROM_FRAGMENT}
	${NUMERICAL_RANGE_SCORE_FRAGMENT}
	${NUMERICAL_RANGE_IS_BOOSTER_ALLOWED_FRAGMENT}
	${NUMERICAL_RANGE_INDEX_FRAGMENT}
	${NUMERICAL_RANGE_PRICE_FRAGMENT}
	${NUMERICAL_RANGE_COST_FRAGMENT}
	${NUMERICAL_RANGE_DESCRIPTION_FRAGMENT}
	${NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_TO_FRAGMENT}
	${NUMERICAL_RANGE_MEDIA_URL_FRAGMENT}
	${NUMERICAL_RANGE_LINK_FRAGMENT}
	${NUMERICAL_RANGE_NAME_FRAGMENT}
	fragment PublicNumericalRangeFragment on NumericalRange {
		id
		numericalId
		...NumericalRangeFromFragment
		...NumericalRangeToFragment
		...NumericalRangeScoreFragment
		...NumericalRangeIsBoosterAllowedFragment
		...NumericalRangePriceFragment
		...NumericalRangeCostFragment
		...NumericalRangeIndexFragment
		...NumericalRangeDescriptionFragment
		...NumericalRangeIsUnlimitedFragment
		...NumericalRangeMediaUrlFragment
		...NumericalRangeLinkFragment
		...NumericalRangeNameFragment
	}
`;

export const NUMERICAL_RANGE_FOR_USE_PACK_PRICE_FRAGMENT = gql`
	${NUMERICAL_RANGE_FROM_FRAGMENT}
	${NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT}
	fragment NumericalRangeForUsePackPriceFragment on NumericalRange {
		id
		numericalId
		...NumericalRangeFromFragment
		...NumericalRangeIsUnlimitedFragment
	}
`;
