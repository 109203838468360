import { gql } from "@apollo/client";
import { ENGAGEMENT_DISCOUNT_ENGAGEMENT_RANGE_FRAGMENT } from "./engagementDiscountFragment";
import { PAYMENT_TERM_DISCOUNT_FRAGMENT } from "./paymentTermDiscountFragment";
import {
	RANGE_DEPENDENCY_FOR_PRICE_MODEL_FRAGMENT,
	RANGE_DEPENDENCY_FRAGMENT,
	RANGE_DEPENDENCY_DESIRED_RANGE_FRAGMENT,
} from "./rangeDependency";
import { SPECIAL_DISCOUNT_FRAGMENT } from "./specialDiscountFragment";
import { VOLUME_DISCOUNT_FRAGMENT } from "./volumeDiscountFragment";

export const DEPENDENCY_ID_FRAGMENT = gql`
	fragment DependencyIdFragment on Dependency {
		id
	}
`;

export const DEPENDENCY_REQUIRED_DISCOUNT_RULE_ID_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	fragment DependencyRequiredDiscountRuleIdFragment on Dependency {
		...DependencyIdFragment
		requiredDiscountRuleId
	}
`;

export const DEPENDENCY_DESIRED_DISCOUNT_RULE_ID_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	fragment DependencyDesiredDiscountRuleIdFragment on Dependency {
		...DependencyIdFragment
		desiredDiscountRuleId
	}
`;

export const DEPENDENCY_RANGE_DEPENDENCIES_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	${RANGE_DEPENDENCY_FRAGMENT}
	fragment DependencyRangeDependenciesFragment on Dependency {
		...DependencyIdFragment
		rangeDependencies {
			...RangeDependencyFragment
		}
	}
`;

export const DEPENDENCY_RANGE_DEPENDENCIES_DESIRED_RANGE_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	${RANGE_DEPENDENCY_DESIRED_RANGE_FRAGMENT}
	fragment DependencyRangeDependenciesDesiredRangeFragment on Dependency {
		...DependencyIdFragment
		rangeDependencies {
			...RangeDependencyDesiredRangeFragment
		}
	}
`;
export const DISCOUNT_RULE_IDS_FOR_DEPENDENCY_FRAGMENT = gql`
	fragment DiscountRuleIdsForDependencyFragment on DiscountRule {
		id
		type
		name
		engagementDiscount {
			id
		}
		volumeDiscount {
			id
		}
		paymentTermDiscount {
			id
		}
		specialDiscount {
			id
		}
	}
`;

export const DEPENDENCY_REQUIRED_DISCOUNT_RULE_IDS_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	${DISCOUNT_RULE_IDS_FOR_DEPENDENCY_FRAGMENT}
	fragment DependencyRequiredDiscountRuleIdsFragment on Dependency {
		...DependencyIdFragment
		requiredDiscountRule {
			...DiscountRuleIdsForDependencyFragment
		}
	}
`;

export const DEPENDENCY_DESIRED_DISCOUNT_RULE_IDS_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	${DISCOUNT_RULE_IDS_FOR_DEPENDENCY_FRAGMENT}
	fragment DependencyDesiredDiscountRuleIdsFragment on Dependency {
		...DependencyIdFragment
		desiredDiscountRule {
			...DiscountRuleIdsForDependencyFragment
		}
	}
`;

export const DISCOUNT_RULE_FOR_DEPENDENCY_FRAGMENT = gql`
	${ENGAGEMENT_DISCOUNT_ENGAGEMENT_RANGE_FRAGMENT}
	${VOLUME_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_DISCOUNT_FRAGMENT}
	${SPECIAL_DISCOUNT_FRAGMENT}
	fragment DiscountRuleForDependencyFragment on DiscountRule {
		id
		name
		type
		engagementDiscount {
			...EngagementDiscountEngagementRangeFragment
		}
		volumeDiscount {
			...VolumeDiscountFragment
		}
		paymentTermDiscount {
			...PaymentTermDiscountFragment
		}
		specialDiscount {
			...SpecialDiscountFragment
		}
	}
`;

export const DEPENDENCY_REQUIRED_DISCOUNT_RULE_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	${DISCOUNT_RULE_FOR_DEPENDENCY_FRAGMENT}
	fragment DependencyRequiredDiscountRuleFragment on Dependency {
		...DependencyIdFragment
		requiredDiscountRule {
			...DiscountRuleForDependencyFragment
		}
	}
`;

export const DEPENDENCY_DESIRED_DISCOUNT_RULE_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	${DISCOUNT_RULE_FOR_DEPENDENCY_FRAGMENT}
	fragment DependencyDesiredDiscountRuleFragment on Dependency {
		...DependencyIdFragment
		desiredDiscountRule {
			...DiscountRuleForDependencyFragment
		}
	}
`;

export const DEPENDENCY_REQUIRED_DISCOUNT_RULE_TYPE_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	fragment DependencyRequiredDiscountRuleTypeFragment on Dependency {
		...DependencyIdFragment
		requiredDiscountRule {
			id
			type
		}
	}
`;

export const DEPENDENCY_DESIRED_DISCOUNT_RULE_TYPE_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	fragment DependencyDesiredDiscountRuleTypeFragment on Dependency {
		...DependencyIdFragment
		desiredDiscountRule {
			id
			type
		}
	}
`;

export const DEPENDENCY_DISCOUNT_RULE_NAME_AND_TYPE_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	${DEPENDENCY_REQUIRED_DISCOUNT_RULE_ID_FRAGMENT}
	${DEPENDENCY_DESIRED_DISCOUNT_RULE_ID_FRAGMENT}
	fragment DependencyDiscountRuleNameAndTypeFragment on Dependency {
		...DependencyIdFragment
		...DependencyRequiredDiscountRuleIdFragment
		...DependencyDesiredDiscountRuleIdFragment
		desiredDiscountRule {
			id
			name
			type
		}
		requiredDiscountRule {
			id
			name
			type
		}
	}
`;

export const DEPENDENCY_FOR_DISCOUNT_RULE_WITH_RANGES_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	${DEPENDENCY_REQUIRED_DISCOUNT_RULE_ID_FRAGMENT}
	${DEPENDENCY_DESIRED_DISCOUNT_RULE_ID_FRAGMENT}
	${DEPENDENCY_REQUIRED_DISCOUNT_RULE_FRAGMENT}
	${DEPENDENCY_DESIRED_DISCOUNT_RULE_FRAGMENT}
	fragment DependencyForDiscountRuleWithRangesFragment on Dependency {
		...DependencyIdFragment
		...DependencyRequiredDiscountRuleIdFragment
		...DependencyDesiredDiscountRuleIdFragment
		...DependencyRequiredDiscountRuleFragment
		...DependencyDesiredDiscountRuleFragment
	}
`;

export const DEPENDENCY_FOR_DISCOUNT_RULE_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	${DEPENDENCY_REQUIRED_DISCOUNT_RULE_ID_FRAGMENT}
	${DEPENDENCY_DESIRED_DISCOUNT_RULE_ID_FRAGMENT}
	${DEPENDENCY_RANGE_DEPENDENCIES_FRAGMENT}
	fragment DependencyForDiscountRuleFragment on Dependency {
		...DependencyIdFragment
		...DependencyRequiredDiscountRuleIdFragment
		...DependencyDesiredDiscountRuleIdFragment
		...DependencyRangeDependenciesFragment
	}
`;

export const DEPENDENCY_FRAGMENT = gql`
	${DEPENDENCY_FOR_DISCOUNT_RULE_WITH_RANGES_FRAGMENT}
	${DEPENDENCY_REQUIRED_DISCOUNT_RULE_FRAGMENT}
	${DEPENDENCY_DESIRED_DISCOUNT_RULE_FRAGMENT}
	fragment DependencyFragment on Dependency {
		...DependencyForDiscountRuleWithRangesFragment
		...DependencyRequiredDiscountRuleFragment
		...DependencyDesiredDiscountRuleFragment
	}
`;

export const DEPENDENCY_FOR_PRICE_MODEL_FRAGMENT = gql`
	${DEPENDENCY_ID_FRAGMENT}
	${DEPENDENCY_REQUIRED_DISCOUNT_RULE_ID_FRAGMENT}
	${DEPENDENCY_DESIRED_DISCOUNT_RULE_ID_FRAGMENT}
	${DEPENDENCY_REQUIRED_DISCOUNT_RULE_IDS_FRAGMENT}
	${DEPENDENCY_DESIRED_DISCOUNT_RULE_IDS_FRAGMENT}
	${RANGE_DEPENDENCY_FOR_PRICE_MODEL_FRAGMENT}
	fragment DependencyForPriceModelFragment on Dependency {
		...DependencyIdFragment
		...DependencyRequiredDiscountRuleIdFragment
		...DependencyDesiredDiscountRuleIdFragment
		...DependencyRequiredDiscountRuleIdsFragment
		...DependencyDesiredDiscountRuleIdsFragment
		rangeDependencies {
			...RangeDependencyForPriceModelFragment
		}
	}
`;

export const RANGE_DEPENDENCY_AND_DISCOUNT_RULE_TYPE_FRAGMENT = gql`
	${DEPENDENCY_FOR_DISCOUNT_RULE_FRAGMENT}
	${DEPENDENCY_REQUIRED_DISCOUNT_RULE_TYPE_FRAGMENT}
	${DEPENDENCY_DESIRED_DISCOUNT_RULE_TYPE_FRAGMENT}
	fragment RangeDependenciesAndDiscountRuleTypeFragment on Dependency {
		...DependencyForDiscountRuleFragment
		...DependencyRequiredDiscountRuleTypeFragment
		...DependencyDesiredDiscountRuleTypeFragment
	}
`;
