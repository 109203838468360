import React from "react";

export const groupBy = (arr, key, returnArray = true) => {
	if (!arr) {
		arr = [];
	}
	Object.byString = function (o, s) {
		s = s.replace(/\[(\w+)\]/g, ".$1");
		s = s.replace(/^\./, "");
		const a = s.split(".");
		for (let i = 0, n = a.length; i < n; ++i) {
			const k = a[i];
			if (k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		return o;
	};
	const result = arr.reduce((r, a) => {
		r[Object.byString(a, key)] = r[Object.byString(a, key)] || [];
		r[Object.byString(a, key)].push(a);
		return r;
	}, Object.create(null));
	if (!returnArray) {
		return result;
	}
	return Object.values(result);
};
const isObject = object => object !== null && typeof object === "object";

export const isObjectsEquals = (object1, object2, keysToExclude, excludeFunctions = true) => {
	if (!isObject(object1) || !isObject(object2)) {
		return object1 === object2;
	}
	const keys1 = Object.keys(object1).filter(x => !keysToExclude?.includes(x));
	const keys2 = Object.keys(object2).filter(x => !keysToExclude?.includes(x));

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		const areFunctions = typeof val1 === "function" || typeof val2 === "function";
		const areReactElement = React.isValidElement(val1) || React.isValidElement(val2);
		const newKeysToExclude = keysToExclude
			?.filter(x => x.startsWith(key + "."))
			?.map(x => x.split(".").slice(1).join("."));
		if (
			!areReactElement &&
			((!excludeFunctions && areFunctions && val1 !== val2) ||
				(areObjects && !isObjectsEquals(val1, val2, newKeysToExclude)) ||
				(!areObjects && val1 !== val2))
		) {
			return false;
		}
	}

	return true;
};

Object.prototype.flatten = function (obj) {
	if (!obj) {
		return [];
	}
	function _flatten(o) {
		return [].concat(
			...Object.keys(o).map(k =>
				typeof o[k] === "object" ? _flatten(o[k]) : k !== "__typename" ? o[k] : null
			)
		);
	}
	return _flatten(obj)?.filter(item => item !== null);
};
Object.defineProperty(Object.prototype, "flatten", { enumerable: false });

Object.prototype.gmfequals = function (object, keysToExclude = [], excludeFunctions = true) {
	if (!isObject(this) || !isObject(object)) {
		return this === object;
	}

	const keys1 = Object.keys(this).filter(x => !keysToExclude?.includes(x));
	const keys2 = Object.keys(object).filter(x => !keysToExclude?.includes(x));

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = this[key];
		const val2 = object[key];
		const areObjects =
			(isObject(val1) && isObject(val2)) || (Array.isArray(val1) && Array.isArray(val2));
		const areFunctions = typeof val1 === "function" || typeof val2 === "function";
		const areReactElement = React.isValidElement(val1) || React.isValidElement(val2);

		const newKeysToExclude = keysToExclude
			?.filter(x => x.startsWith(key + "."))
			?.map(x => x.split(".").slice(1).join("."));
		if (
			!areReactElement &&
			((!excludeFunctions && areFunctions && val1 !== val2) ||
				(areObjects && !val1.gmfequals(val2, newKeysToExclude)) ||
				(!areObjects && !areFunctions && val1 !== val2))
		) {
			return false;
		}
	}

	return true;
};
Object.defineProperty(Object.prototype, "gmfequals", { enumerable: false });

if (Array.prototype.gmfequals) {
	console.warn(
		"Overriding existing Array.prototype.gmfequals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code."
	);
}
Array.prototype.gmfequals = function (array, keysToExclude) {
	if (!array) {
		return false;
	}

	if (this.length !== array.length) {
		return false;
	}

	for (let i = 0, l = this.length; i < l; i++) {
		if (this[i] instanceof Array && array[i] instanceof Array) {
			if (!this[i].gmfequals(array[i], keysToExclude)) {
				return false;
			}
		} else if (isObject(this[i]) && isObject(array[i])) {
			if (!isObjectsEquals(this[i], array[i], keysToExclude)) {
				return false;
			}
		} else if (this[i] !== array[i]) {
			return false;
		}
	}
	return true;
};
Object.defineProperty(Array.prototype, "gmfequals", { enumerable: false });

export const uniqueObjectsByKey = (array, key) => {
	return [...new Map(array.map(item => [item[key], item])).values()];
};
