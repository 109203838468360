import { gql } from "@apollo/client";

export const PRICE_MODEL_DESIGN_AND_SETTING_ID_FRAGMENT = gql`
	fragment PriceModelDesignAndSettingIdFragment on PriceModelDesignAndSetting {
		priceModelId
	}
`;

export const PRICE_MODEL_HIGHLIGHTED_DIFFERENCES_FRAGMENT = gql`
	fragment PriceModelHighlightedDifferencesFragment on PriceModelDesignAndSetting {
		priceModelId
		highlightedDifferences
	}
`;

export const PRICE_MODEL_BACKGROUND_PRIMARY_COLOR_FRAGMENT = gql`
	fragment PriceModelBackgroundPrimaryColorFragment on PriceModelDesignAndSetting {
		priceModelId
		backgroundPrimaryColor
	}
`;

export const PRICE_MODEL_CORNER_RADIUS_FRAGMENT = gql`
	fragment PriceModelCornerRadiusFragment on PriceModelDesignAndSetting {
		priceModelId
		cornerRadius
	}
`;

export const PRICE_MODEL_DISPLAYED_CUSTOM_OFFER_LABEL_FRAGMENT = gql`
	fragment PriceModelDisplayedCustomOfferLabelFragment on PriceModelDesignAndSetting {
		priceModelId
		displayedCustomOfferLabel
	}
`;

export const PRICE_MODEL_DISPLAYED_DESCRIPTION_FRAGMENT = gql`
	fragment PriceModelDisplayedDescriptionFragment on PriceModelDesignAndSetting {
		priceModelId
		displayedDescription
	}
`;

export const PRICE_MODEL_IS_SECTION_DISCOUNT_RULE_SEPARATE_FRAGMENT = gql`
	fragment PriceModelIsSectionDiscountRuleSeparateFragment on PriceModelDesignAndSetting {
		priceModelId
		isSectionDiscountRuleSeparate
	}
`;

export const PRICE_MODEL_DISPLAYED_VAT_DEFAULT_FRAGMENT = gql`
	fragment PriceModelDisplayedVatDefaultFragment on PriceModelDesignAndSetting {
		priceModelId
		displayedVatDefault
	}
`;

export const PRICE_MODEL_ARE_FEATURE_DESCRIPTIONS_WRAPPED_FRAGMENT = gql`
	fragment PriceModelAreFeatureDescriptionsWrappedFragment on PriceModelDesignAndSetting {
		priceModelId
		featureDescriptionsLayout
	}
`;

export const PRICE_MODEL_PRICE_ROUND_UP_FRAGMENT = gql`
	fragment PriceModelPriceRoundUpFragment on PriceModelDesignAndSetting {
		priceModelId
		priceRoundUp
	}
`;

export const PRICE_MODEL_SHOW_COMMITMENT_INPUT_FRAGMENT = gql`
	fragment PriceModelShowCommitmentInputFragment on PriceModelDesignAndSetting {
		priceModelId
		showCommitmentInput
	}
`;

export const PRICE_MODEL_SHOW_CROSSED_OUT_PRICES_FRAGMENT = gql`
	fragment PriceModelShowCrossedOutPricesFragment on PriceModelDesignAndSetting {
		priceModelId
		showCrossedOutPrices
	}
`;

export const PRICE_MODEL_DS_SHOW_SAVINGS_FRAGMENT = gql`
	fragment PriceModelShowSavingsFragment on PriceModelDesignAndSetting {
		priceModelId
		showSavings
	}
`;

export const PRICE_MODEL_SHOW_SWITCHER_VAT_DEFAULT_FRAGMENT = gql`
	fragment PriceModelShowSwitcherVatDefaultFragment on PriceModelDesignAndSetting {
		priceModelId
		showSwitcherVatDefault
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_ADDON_SECTION_NAME_BEFORE_FRAGMENT = gql`
	fragment PriceModelDesignAndSettingAddOnSectionNameBeforeFragment on PriceModelDesignAndSetting {
		id
		addOnSectionNameBefore
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_SECTION_NAME_BEFORE_FRAGMENT = gql`
	fragment PriceModelDesignAndSettingExtraSectionNameBeforeFragment on PriceModelDesignAndSetting {
		id
		extraSectionNameBefore
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_ADDON_OPENED_BY_DEFAULT_BEFORE_FRAGMENT = gql`
	fragment PriceModelDesignAndSettingAddonOpenedByDefaultBeforeFragment on PriceModelDesignAndSetting {
		id
		addOnCollapsedByDefaultBefore
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_OPENED_BY_DEFAULT_BEFORE_FRAGMENT = gql`
	fragment PriceModelDesignAndSettingExtraOpenedByDefaultBeforeFragment on PriceModelDesignAndSetting {
		id
		extraCollapsedByDefaultBefore
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_ADDON_SECTION_NAME_AFTER_FRAGMENT = gql`
	fragment PriceModelDesignAndSettingAddOnSectionNameAfterFragment on PriceModelDesignAndSetting {
		id
		addOnSectionNameAfter
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_SECTION_NAME_AFTER_FRAGMENT = gql`
	fragment PriceModelDesignAndSettingExtraSectionNameAfterFragment on PriceModelDesignAndSetting {
		id
		extraSectionNameAfter
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_ADDON_OPENED_BY_DEFAULT_AFTER_FRAGMENT = gql`
	fragment PriceModelDesignAndSettingAddonOpenedByDefaultAfterFragment on PriceModelDesignAndSetting {
		id
		addOnCollapsedByDefaultAfter
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_OPENED_BY_DEFAULT_AFTER_FRAGMENT = gql`
	fragment PriceModelDesignAndSettingExtraOpenedByDefaultAfterFragment on PriceModelDesignAndSetting {
		id
		extraCollapsedByDefaultAfter
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_HIGHLIGHTED_DIFFERENCES_FRAGMENT = gql`
	${PRICE_MODEL_DESIGN_AND_SETTING_ID_FRAGMENT}
	fragment PriceModelDesignAndSettingHighlightedDifferencesFragment on PriceModelDesignAndSetting {
		...PriceModelDesignAndSettingIdFragment
		highlightedDifferences
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_HIDE_PRICES_FRAGMENT = gql`
	${PRICE_MODEL_DESIGN_AND_SETTING_ID_FRAGMENT}
	fragment PriceModelDesignAndSettingHidePricesFragment on PriceModelDesignAndSetting {
		...PriceModelDesignAndSettingIdFragment
		hidePrices
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_HIGHLIGHT_METHOD_FRAGMENT = gql`
	${PRICE_MODEL_DESIGN_AND_SETTING_ID_FRAGMENT}
	fragment PriceModelDesignAndSettingHighlightMethodFragment on PriceModelDesignAndSetting {
		...PriceModelDesignAndSettingIdFragment
		highlightMethod
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_IS_ROUND_UP_ABOVE_THOUSAND_FRAGMENT = gql`
	${PRICE_MODEL_DESIGN_AND_SETTING_ID_FRAGMENT}
	fragment PriceModelDesignAndSettingIsRoundUpAboveThousandFragment on PriceModelDesignAndSetting {
		...PriceModelDesignAndSettingIdFragment
		isRoundUpAboveThousand
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_OPENED_BY_DEFAULT_FRAGMENT = gql`
	${PRICE_MODEL_DESIGN_AND_SETTING_ID_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_ADDON_OPENED_BY_DEFAULT_AFTER_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_OPENED_BY_DEFAULT_AFTER_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_ADDON_OPENED_BY_DEFAULT_BEFORE_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_OPENED_BY_DEFAULT_BEFORE_FRAGMENT}
	fragment PriceModelDesignAndSettingOpenedByDefaultFragment on PriceModelDesignAndSetting {
		...PriceModelDesignAndSettingIdFragment
		...PriceModelDesignAndSettingAddonOpenedByDefaultAfterFragment
		...PriceModelDesignAndSettingExtraOpenedByDefaultAfterFragment
		...PriceModelDesignAndSettingAddonOpenedByDefaultBeforeFragment
		...PriceModelDesignAndSettingExtraOpenedByDefaultBeforeFragment
	}
`;

export const PRICE_MODEL_DESIGN_AND_SETTING_FRAGMENT = gql`
	${PRICE_MODEL_DESIGN_AND_SETTING_ID_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_HIGHLIGHTED_DIFFERENCES_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_HIDE_PRICES_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_HIGHLIGHT_METHOD_FRAGMENT}
	fragment PriceModelDesignAndSettingFragment on PriceModelDesignAndSetting {
		...PriceModelDesignAndSettingIdFragment
		...PriceModelDesignAndSettingHighlightedDifferencesFragment
		...PriceModelDesignAndSettingHidePricesFragment
		...PriceModelDesignAndSettingHighlightMethodFragment
	}
`;

export const PRICE_MODEL_DS_VAT_FRAGMENT = gql`
	fragment PriceModelDsVatFragment on PriceModelDesignAndSetting {
		id
		priceModelId
		displayedVatDefault
		showSwitcherVatDefault
	}
`;

export const PRICE_MODEL_NUMERICAL_NULL_DISPLAY_FRAGMENT = gql`
	${PRICE_MODEL_DESIGN_AND_SETTING_ID_FRAGMENT}
	fragment PriceModelNumericalNullDisplayFragment on PriceModelDesignAndSetting {
		...PriceModelDesignAndSettingIdFragment
		numericalNullDisplay
	}
`;

export const PRICE_MODEL_DESCRIPTIVE_NULL_DISPLAY_FRAGMENT = gql`
	${PRICE_MODEL_DESIGN_AND_SETTING_ID_FRAGMENT}
	fragment PriceModelDescriptiveNullDisplayFragment on PriceModelDesignAndSetting {
		...PriceModelDesignAndSettingIdFragment
		descriptiveNullDisplay
	}
`;
