import { gql } from "@apollo/client";

export const CATEGORY_PACKAGE_BUILDER_FRAGMENT = gql`
	fragment CategoryPackageBuilderFragment on CategoryPackageBuilder {
		id
		priceModelId
		categorizationId
		categoryId
	}
`;

export const FULL_CATEGORY_FRAGMENT = gql`
	fragment FullCategoryFragment on CategoryPackageBuilder {
		id
		priceModelId
		categorizationId
		categoryId
		category {
			id
			name
			color
		}
	}
`;
