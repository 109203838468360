import { gql } from "@apollo/client";
import {
	OFFER_VERSION_QUOTE_FRAGMENT,
	OFFER_VERSION_QUOTE_SIGNED_AT_FRAGMENT,
} from "@graphQl/fragments/offerVersionQuote";
import {
	ENABLE_MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT,
	ID_PRICE_MODEL_COMBINATION_FRAGMENT,
	LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT,
	MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT,
	ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT,
	PRICE_MODEL_COMBINATION_FRAGMENT,
	PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT,
	PRICE_MODEL_COMBINATION_SELECTED_CATEGORIES_FRAGMENT,
	PUBLIC_PRICE_MODEL_COMBINATION_FRAGMENT,
	SIGNATURE_LINK_PUBLIC_PRICE_MODEL_COMBINATION_FRAGMENT,
} from "@graphQl/fragments/priceModelCombinationFragment";

import { OFFER_VERSION_ACCESS_FRAGMENT } from "@graphQl/fragments/offerVersionAccess";
import { OFFER_VERSION_RULE_FRAGMENT } from "@graphQl/fragments/offerVersionRule";

export const OFFER_VERSION_ID_FRAGMENT = gql`
	fragment OfferVersionIdFragment on OfferVersion {
		id
	}
`;

export const OFFER_VERSION_OFFER_ID_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	fragment OfferVersionOfferIdFragment on OfferVersion {
		...OfferVersionIdFragment
		offerId
	}
`;

export const OFFER_VERSION_INDEX_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	fragment OfferVersionIndexFragment on OfferVersion {
		...OfferVersionIdFragment
		index
	}
`;

export const OFFER_VERSION_CREATED_AT_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	fragment OfferVersionCreatedAtFragment on OfferVersion {
		...OfferVersionIdFragment
		createdAt
	}
`;

export const OFFER_VERSION_UPDATED_AT_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	fragment OfferVersionUpdatedAtFragment on OfferVersion {
		...OfferVersionIdFragment
		updatedAt
	}
`;

export const OFFER_VERSION_CREATED_BY_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	fragment OfferVersionCreatedByFragment on OfferVersion {
		...OfferVersionIdFragment
		createdBy
	}
`;

export const OFFER_VERSION_UPDATED_BY_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	fragment OfferVersionUpdatedByFragment on OfferVersion {
		...OfferVersionIdFragment
		updatedBy
	}
`;

export const OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_SELECTED_CATEGORIES_FRAGMENT}
	${ENABLE_MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment OfferVersionPriceModelCombinationFragment on OfferVersion {
		...OfferVersionIdFragment
		priceModelCombination {
			...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
			...OnlyNamePriceModelCombinationFragment
			...PriceModelCombinationSelectedCategoriesFragment
			...EnableMaxGlobalDiscountPriceModelCombinationFragment
			...MaxGlobalDiscountPriceModelCombinationFragment
			...LanguagePriceModelCombinationFragment
		}
	}
`;

export const OFFER_VERSION_PRICE_MODEL_COMBINATION_LANGUAGE_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment OfferVersionPriceModelCombinationLanguageFragment on OfferVersion {
		...OfferVersionIdFragment
		priceModelCombination {
			...LanguagePriceModelCombinationFragment
		}
	}
`;

export const LAST_OFFER_VERSION_CREATION_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment LastOfferVersionCreationPriceModelCombinationFragment on OfferVersion {
		...OfferVersionIdFragment
		priceModelCombination {
			...PriceModelCombinationFragment
		}
	}
`;

export const LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_QUOTE_FRAGMENT}
	${PUBLIC_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment LastOfferVersionPriceModelCombinationFragment on OfferVersion {
		...OfferVersionIdFragment
		offerVersionQuotes {
			...OfferVersionQuoteFragment
		}
		priceModelCombination {
			...PublicPriceModelCombinationFragment
		}
	}
`;

export const SIGNATURE_LINK_LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${SIGNATURE_LINK_PUBLIC_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_VERSION_QUOTE_FRAGMENT}
	fragment SignatureLinkLastOfferVersionPriceModelCombinationFragment on OfferVersion {
		...OfferVersionIdFragment
		offerVersionQuotes {
			...OfferVersionQuoteFragment
		}
		priceModelCombination {
			...SignatureLinkPublicPriceModelCombinationFragment
		}
	}
`;

export const LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_ENABLE_MAX_GLOBAL_DISCOUNT_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${ENABLE_MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment LastOfferVersionPriceModelCombinationEnableMaxGlobalDiscountFragment on OfferVersion {
		...OfferVersionIdFragment
		priceModelCombination {
			...EnableMaxGlobalDiscountPriceModelCombinationFragment
		}
	}
`;

export const LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_MAX_GLOBAL_DISCOUNT_DATA_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${ENABLE_MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment LastOfferVersionPriceModelCombinationMaxGlobalDiscountDataFragment on OfferVersion {
		...OfferVersionIdFragment
		priceModelCombination {
			...EnableMaxGlobalDiscountPriceModelCombinationFragment
			...MaxGlobalDiscountPriceModelCombinationFragment
		}
	}
`;

export const OFFER_VERSION_OFFER_VERSION_QUOTES_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_QUOTE_FRAGMENT}
	fragment OfferVersionOfferVersionQuotesFragment on OfferVersion {
		...OfferVersionIdFragment
		offerVersionQuotes {
			...OfferVersionQuoteFragment
		}
	}
`;

export const OFFER_VERSION_OFFER_VERSION_QUOTES_SIGNED_AT_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_QUOTE_SIGNED_AT_FRAGMENT}
	fragment OfferVersionOfferVersionQuotesSignedAtFragment on OfferVersion {
		...OfferVersionIdFragment
		offerVersionQuotes {
			...OfferVersionQuoteSignedAtFragment
		}
	}
`;

export const OFFER_VERSION_OFFER_VERSION_ACCESS_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_ACCESS_FRAGMENT}
	fragment OfferVersionOfferVersionAccessFragment on OfferVersion {
		...OfferVersionIdFragment
		offerVersionAccess {
			...OfferVersionAccessFragment
		}
	}
`;

export const OFFER_VERSION_OFFER_VERSION_RULES_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_RULE_FRAGMENT}
	fragment OfferVersionOfferVersionRulesFragment on OfferVersion {
		...OfferVersionIdFragment
		offerVersionRules {
			...OfferVersionRuleFragment
		}
	}
`;

export const OFFER_VERSION_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_OFFER_ID_FRAGMENT}
	${OFFER_VERSION_INDEX_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_QUOTES_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_ACCESS_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_RULES_FRAGMENT}
	${OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_VERSION_CREATED_AT_FRAGMENT}
	${OFFER_VERSION_UPDATED_AT_FRAGMENT}
	${OFFER_VERSION_CREATED_BY_FRAGMENT}
	${OFFER_VERSION_UPDATED_BY_FRAGMENT}
	fragment OfferVersionFragment on OfferVersion {
		...OfferVersionIdFragment
		...OfferVersionOfferIdFragment
		...OfferVersionIndexFragment
		...OfferVersionOfferVersionQuotesFragment
		...OfferVersionOfferVersionAccessFragment
		...OfferVersionOfferVersionRulesFragment
		...OfferVersionPriceModelCombinationFragment
		...OfferVersionCreatedAtFragment
		...OfferVersionUpdatedAtFragment
		...OfferVersionCreatedByFragment
		...OfferVersionUpdatedByFragment
	}
`;

export const OFFER_VERSION_OFFER_VERSION_QUOTE_SIGNED_AT_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_QUOTES_SIGNED_AT_FRAGMENT}
	fragment OfferVersionOfferVersionQuoteSignedAtFragment on OfferVersion {
		...OfferVersionIdFragment
		...OfferVersionOfferVersionQuotesSignedAtFragment
	}
`;

export const OFFER_VERSION_CREATION_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_OFFER_ID_FRAGMENT}
	${OFFER_VERSION_INDEX_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_QUOTES_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_ACCESS_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_RULES_FRAGMENT}
	${LAST_OFFER_VERSION_CREATION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_VERSION_CREATED_AT_FRAGMENT}
	${OFFER_VERSION_UPDATED_AT_FRAGMENT}
	${OFFER_VERSION_CREATED_BY_FRAGMENT}
	${OFFER_VERSION_UPDATED_BY_FRAGMENT}
	fragment OfferVersionCreationFragment on OfferVersion {
		...OfferVersionIdFragment
		...OfferVersionOfferIdFragment
		...OfferVersionIndexFragment
		...OfferVersionOfferVersionQuotesFragment
		...OfferVersionOfferVersionAccessFragment
		...OfferVersionOfferVersionRulesFragment
		...LastOfferVersionCreationPriceModelCombinationFragment
		...OfferVersionCreatedAtFragment
		...OfferVersionUpdatedAtFragment
		...OfferVersionCreatedByFragment
		...OfferVersionUpdatedByFragment
	}
`;

export const LAST_OFFER_VERSION_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_RULES_FRAGMENT}
	fragment LastOfferVersionFragment on OfferVersion {
		...OfferVersionIdFragment
		...LastOfferVersionPriceModelCombinationFragment
		...OfferVersionOfferVersionRulesFragment
	}
`;

export const OFFER_VERSION_CONFIRMATION_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_OFFER_ID_FRAGMENT}
	fragment OfferVersionConfirmationFragment on OfferVersion {
		...OfferVersionIdFragment
		...OfferVersionOfferIdFragment
		offer {
			id
			minAmount
		}
	}
`;

export const OFFER_VERSION_PRICE_MODEL_COMBINATION_ID_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment OfferVersionPriceModelCombinationIdFragment on OfferVersion {
		...OfferVersionIdFragment
		priceModelCombination {
			...IdPriceModelCombinationFragment
		}
	}
`;
