import { gql } from "@apollo/client";
import {
	SPECIAL_RANGE_DISCOUNT_DISCOUNT_FRAGMENT,
	SPECIAL_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT,
	SPECIAL_RANGE_DISCOUNT_FRAGMENT,
} from "@graphQl/fragments/specialRangeDiscount";

export const SPECIAL_RANGE_SPECIAL_DISCOUNT_ID_FRAGMENT = gql`
	fragment SpecialRangeSpecialDiscountIdFragment on SpecialRange {
		id
		specialDiscountId
	}
`;

export const SPECIAL_RANGE_DESCRIPTION_FRAGMENT = gql`
	fragment SpecialRangeDescriptionFragment on SpecialRange {
		id
		description
	}
`;

export const SPECIAL_RANGE_NAME_FRAGMENT = gql`
	fragment SpecialRangeNameFragment on SpecialRange {
		id
		name
		errors {
			name
		}
	}
`;

export const NAME_SPECIAL_RANGE = gql`
	fragment NameSpecialRangeFragment on SpecialRange {
		id
		name
		uuid
	}
`;

export const INDEX_SPECIAL_RANGE = gql`
	fragment IndexSpecialRangeFragment on SpecialRange {
		id
		index
	}
`;

export const SPECIAL_RANGE_DEPENDENCIES = gql`
	fragment SpecialRangeDependencies on SpecialRange {
		id
		dependencies {
			requiredId
			desiredId
			parentDependencyId
			requiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
			desiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
		}
	}
`;

export const SPECIAL_RANGE_ID_AND_SPECIAL_DISCOUNT_RANGE_ID_FRAGMENT = gql`
	${SPECIAL_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT}
	fragment SpecialRangeIdAndSpecialDiscountRangeIdFragment on SpecialRange {
		id
		specialDiscountRange {
			...SpecialRangeDiscountEnableDiscountFragment
		}
	}
`;

export const SPECIAL_RANGE_FRAGMENT = gql`
	${SPECIAL_RANGE_DISCOUNT_FRAGMENT}
	${INDEX_SPECIAL_RANGE}
	${NAME_SPECIAL_RANGE}
	${SPECIAL_RANGE_DEPENDENCIES}
	fragment SpecialRangeFragment on SpecialRange {
		id
		uuid
		...IndexSpecialRangeFragment
		...NameSpecialRangeFragment
		...SpecialRangeDependencies
		specialDiscountId
		description
		specialDiscountRange {
			...SpecialRangeDiscountFragment
		}
	}
`;

export const SPECIAL_DISCOUNT_RANGE_DATA_TO_COMPUTE_SAVINGS_FRAGMENT = gql`
	${SPECIAL_RANGE_DISCOUNT_DISCOUNT_FRAGMENT}
	fragment SpecialDiscountRangeDataToComputeSavingsFragment on SpecialRange {
		id
		specialDiscountRange {
			...SpecialRangeDiscountDiscountFragment
		}
	}
`;

export const IS_DEFAULT_SELECTED_SPECIAL_RANGE_FRAGMENT = gql`
	fragment IsDefaultSelectedSpecialRangeFragment on SpecialRange {
		id
		isDefaultSelected
	}
`;
