import { gql } from "@apollo/client";
import {
	SPECIAL_DISCOUNT_RANGE_DATA_TO_COMPUTE_SAVINGS_FRAGMENT,
	SPECIAL_RANGE_DEPENDENCIES,
	SPECIAL_RANGE_FRAGMENT,
} from "@graphQl/fragments/specialRange";

export const DEFAULT_POSITION_FRAGMENT = gql`
	fragment DefaultPositionFragment on SpecialDiscountDefaultPosition {
		packId
		specialDiscountId
		specialRangeId
		isAddonExtraLocked
	}
`;

export const SPECIAL_DISCOUNT_ENABLE_COST_FRAGMENT = gql`
	fragment SpecialDiscountEnableCostFragment on SpecialDiscount {
		id
		enableCost
	}
`;

export const SPECIAL_DISCOUNT_TYPE_FRAGMENT = gql`
	fragment SpecialDiscountTypeFragment on SpecialDiscount {
		id
		type
	}
`;

export const SPECIAL_DISCOUNT_UNIT_METRIC_FRAGMENT = gql`
	fragment SpecialDiscountUnitMetricFragment on SpecialDiscount {
		id
		unitMetric
	}
`;

export const SPECIAL_DISCOUNT_TIME_METRIC_FRAGMENT = gql`
	fragment SpecialDiscountTimeMetricFragment on SpecialDiscount {
		id
		timeMetric
	}
`;

export const SPECIAL_DISCOUNT_SPECIAL_RANGES_FRAGMENT = gql`
	${SPECIAL_RANGE_FRAGMENT}
	fragment SpecialDiscountSpecialRangesFragment on SpecialDiscount {
		id
		specialRange {
			...SpecialRangeFragment
		}
	}
`;

export const SPECIAL_DISCOUNT_POSITION_FRAGMENT = gql`
	fragment SpecialDiscountPositionFragment on SpecialDiscount {
		id
		position
	}
`;

export const SPECIAL_DISCOUNT_IS_DISCOUNT_FRAGMENT = gql`
	fragment SpecialDiscountIsDiscountFragment on SpecialDiscount {
		id
		isDiscount
	}
`;

export const SPECIAL_DISCOUNT_TIME_AND_UNIT_METRIC_TYPE_AND_ENABLE_COST_FRAGMENT = gql`
	${SPECIAL_DISCOUNT_TIME_METRIC_FRAGMENT}
	${SPECIAL_DISCOUNT_UNIT_METRIC_FRAGMENT}
	${SPECIAL_DISCOUNT_TYPE_FRAGMENT}
	${SPECIAL_DISCOUNT_ENABLE_COST_FRAGMENT}
	${SPECIAL_DISCOUNT_IS_DISCOUNT_FRAGMENT}
	fragment SpecialDiscountTimeAndUnitMetricTypeAndEnableCostFragment on SpecialDiscount {
		...SpecialDiscountTimeMetricFragment
		...SpecialDiscountUnitMetricFragment
		...SpecialDiscountTypeFragment
		...SpecialDiscountEnableCostFragment
		...SpecialDiscountIsDiscountFragment
	}
`;

export const SPECIAL_DISCOUNT_FRAGMENT = gql`
	${SPECIAL_RANGE_DEPENDENCIES}
	fragment SpecialDiscountFragment on SpecialDiscount {
		id
		uuid
		isDiscount
		isSpecialCondition
		unitMetric
		timeMetric
		type
		isLocked
		position
		enableCost
		defaultPositions {
			packId
			specialDiscountId
			specialRangeId
			isAddonExtraLocked
		}
		specialRange {
			id
			uuid
			specialDiscountId
			description
			type
			name
			unitMetric
			timeMetric
			index
			isDefaultSelected
			uuid
			...SpecialRangeDependencies
			specialDiscountRange {
				id
				specialRangeId
				packId
				discount
				enableDiscount
				cost
				oppositeDiscount
				price
				warning
				uuid
			}
		}
	}
`;

export const SPECIAL_DISCOUNT_DATA_TO_COMPUTE_SAVINGS_FRAGMENT = gql`
	${SPECIAL_DISCOUNT_RANGE_DATA_TO_COMPUTE_SAVINGS_FRAGMENT}
	fragment SpecialDiscountDataToComputeSavingsFragment on SpecialDiscount {
		id
		unitMetric
		timeMetric
		specialRange {
			...SpecialDiscountRangeDataToComputeSavingsFragment
		}
	}
`;
