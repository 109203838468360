import { gql } from "@apollo/client";

export const TOKEN_ACTIVE_FRAGMENT = gql`
	fragment TokenActiveFragment on Token {
		id
		active
	}
`;

export const TOKEN_ENABLE_EXPIRATION_DATE_FRAGMENT = gql`
	fragment TokenEnableExpirationDateFragment on Token {
		id
		enableExpirationDate
	}
`;

export const TOKEN_EXPIRATION_DATE_FRAGMENT = gql`
	fragment TokenExpirationDateFragment on Token {
		id
		expirationDate
	}
`;

export const TOKEN_IS_EXPIRED_FRAGMENT = gql`
	fragment TokenIsExpiredFragment on Token {
		id
		isExpired
	}
`;

export const TOKEN_ENABLE_PASSWORD_FRAGMENT = gql`
	fragment TokenEnablePasswordFragment on Token {
		id
		enablePassword
	}
`;

export const TOKEN_HAS_PASSWORD_FRAGMENT = gql`
	fragment TokenHasPasswordFragment on Token {
		id
		hasPassword
	}
`;

export const ACTIVE_TOKEN_FRAGMENT = gql`
	fragment ActiveTokenFragment on Token {
		id
		active
	}
`;

export const TOKEN_TOKEN_FRAGMENT = gql`
	fragment TokenTokenFragment on Token {
		id
		token
	}
`;
export const HAS_PASSWORD_TOKEN_FRAGMENT = gql`
	fragment HasPasswordTokenFragment on Token {
		id
		hasPassword
	}
`;
export const PASSWORD_TOKEN_FRAGMENT = gql`
	fragment PasswordTokenFragment on Token {
		id
		password
	}
`;
export const IS_EXPIRED_TOKEN_FRAGMENT = gql`
	fragment IsExpiredTokenFragment on Token {
		id
		isExpired
	}
`;
export const TYPE_TOKEN_FRAGMENT = gql`
	fragment TypeTokenFragment on Token {
		id
		type
	}
`;

export const AVAILABILITY_DATA_TOKEN_FRAGMENT = gql`
	fragment AvailabilityDataTokenFragment on Token {
		id
		active
		enableExpirationDate
		expirationDate
	}
`;

export const TOKEN_FRAGMENT = gql`
	${TOKEN_EXPIRATION_DATE_FRAGMENT}
	${ACTIVE_TOKEN_FRAGMENT}
	${HAS_PASSWORD_TOKEN_FRAGMENT}
	${PASSWORD_TOKEN_FRAGMENT}
	${IS_EXPIRED_TOKEN_FRAGMENT}
	${TYPE_TOKEN_FRAGMENT}
	${TOKEN_TOKEN_FRAGMENT}
	${TOKEN_ENABLE_PASSWORD_FRAGMENT}
	${TOKEN_ENABLE_EXPIRATION_DATE_FRAGMENT}
	fragment TokenFragment on Token {
		...TokenExpirationDateFragment
		...ActiveTokenFragment
		...TokenTokenFragment
		...HasPasswordTokenFragment
		...PasswordTokenFragment
		...IsExpiredTokenFragment
		...TypeTokenFragment
		...TokenEnablePasswordFragment
		...TokenEnableExpirationDateFragment
	}
`;
