import { gql } from "@apollo/client";

export const PRICE_MODEL_COMBINATION_PROPERTIES_ID_FRAGMENT = gql`
	fragment PriceModelCombinationPropertiesIdFragment on PriceModelCombinationProperties {
		id
	}
`;

export const PRICE_MODEL_COMBINATION_PROPERTIES_NAME_FRAGMENT = gql`
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_FRAGMENT}
	fragment PriceModelCombinationPropertiesNameFragment on PriceModelCombinationProperties {
		...PriceModelCombinationPropertiesIdFragment
		name
		error {
			id
			name
		}
	}
`;

export const PRICE_MODEL_COMBINATION_PROPERTIES_ONLY_NAME_FRAGMENT = gql`
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_FRAGMENT}
	fragment PriceModelCombinationPropertiesOnlyNameFragment on PriceModelCombinationProperties {
		...PriceModelCombinationPropertiesIdFragment
		name
	}
`;
