import { gql } from "@apollo/client";
import { OFFER_VERSION_RULE_INTERFACE_FRAGMENT } from "@graphQl/fragments/offerVersionRule";

export const OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_PACK_NAME_FRAGMENT = gql`
	fragment OfferVersionRuleForRepaymentSchedulePackNameFragment on OfferVersionRuleForRepaymentSchedule {
		packName
	}
`;

export const OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_DURATION_FRAGMENT = gql`
	fragment OfferVersionRuleForRepaymentScheduleDurationFragment on OfferVersionRuleForRepaymentSchedule {
		duration
	}
`;

export const OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_DISCOUNT_APPLIED_FRAGMENT = gql`
	fragment OfferVersionRuleForRepaymentScheduleDiscountAppliedFragment on OfferVersionRuleForRepaymentSchedule {
		discountApplied
	}
`;

export const OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_IS_RECURRING_FRAGMENT = gql`
	fragment OfferVersionRuleForRepaymentScheduleIsRecurringFragment on OfferVersionRuleForRepaymentSchedule {
		isRecurring
	}
`;

export const OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_VALUE_TYPE_FRAGMENT = gql`
	fragment OfferVersionRuleForRepaymentScheduleValueTypeFragment on OfferVersionRuleForRepaymentSchedule {
		valueType
	}
`;

export const OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_METRIC_FRAGMENT = gql`
	fragment OfferVersionRuleForRepaymentScheduleMetricFragment on OfferVersionRuleForRepaymentSchedule {
		metric
	}
`;

//TODO: @SEB delete oneShotAmountAppliedOnExtra on OfferVersionRuleForRepaymentSchedule
//TODO: @SEB delete recurringDiscountApplied on OfferVersionRuleForRepaymentSchedule

//TODO: DUY check s'il faut utiliser ici
export const OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_FRAGMENT = gql`
	${OFFER_VERSION_RULE_INTERFACE_FRAGMENT}
	${OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_DISCOUNT_APPLIED_FRAGMENT}
	${OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_DURATION_FRAGMENT}
	${OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_VALUE_TYPE_FRAGMENT}
	${OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_METRIC_FRAGMENT}
	${OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_IS_RECURRING_FRAGMENT}
	${OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_PACK_NAME_FRAGMENT}
	fragment OfferVersionRuleForRepaymentScheduleFragment on OfferVersionRuleForRepaymentSchedule {
		...OfferVersionRuleInterfaceFragment
		...OfferVersionRuleForRepaymentScheduleDurationFragment
		...OfferVersionRuleForRepaymentScheduleDiscountAppliedFragment
		...OfferVersionRuleForRepaymentScheduleValueTypeFragment
		...OfferVersionRuleForRepaymentScheduleMetricFragment
		...OfferVersionRuleForRepaymentScheduleIsRecurringFragment
		...OfferVersionRuleForRepaymentSchedulePackNameFragment
	}
`;
