import { gql } from "@apollo/client";
import { ERROR_FRAGMENT } from "@graphQl/fragments/error";

export const LEVEL_DATA_PACK_KEY_FRAGMENT = gql`
	fragment LevelDataPackKeyFragment on LevelDataPack {
		packId
		levelDataId
	}
`;

export const LEVEL_DATA_PACK_PRICE_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	fragment LevelDataPackPriceFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		price
	}
`;

export const LEVEL_DATA_PACK_COST_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	fragment LevelDataPackCostFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		cost
	}
`;

export const LEVEL_DATA_PACK_IS_BOOSTER_ALLOWED_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	fragment LevelDataPackIsBoosterAllowedFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		isBoosterAllowed
	}
`;

export const LEVEL_DATA_PACK_ERRORS_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	${ERROR_FRAGMENT}
	fragment LevelDataPackErrorsFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		errors {
			...ErrorFragment
		}
	}
`;

export const LEVEL_DATA_PACK_EXTRA_FEE_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	fragment LevelDataPackExtraFeeFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		extraFee
	}
`;

export const LEVEL_DATA_PACK_EXTRA_FEE_COST_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	fragment LevelDataPackExtraFeeCostFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		extraFeeCost
	}
`;

export const LEVEL_DATA_PACK_EXTRA_FEE_PROFIT_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	fragment LevelDataPackExtraFeeProfitFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		extraFeeProfit
	}
`;

export const LEVEL_DATA_PACK_EXTRA_FEE_PROFIT_PERCENTAGE_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	fragment LevelDataPackExtraFeeProfitPercentageFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		extraFeeProfitPercentage
	}
`;

export const LEVEL_DATA_PACK_PROFIT_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	fragment LevelDataPackProfitFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		profit
	}
`;

export const LEVEL_DATA_PACK_PROFIT_PERCENTAGE_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	fragment LevelDataPackProfitPercentageFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		profitPercentage
	}
`;

export const LEVEL_DATA_PACK_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	${LEVEL_DATA_PACK_PRICE_FRAGMENT}
	${LEVEL_DATA_PACK_COST_FRAGMENT}
	${LEVEL_DATA_PACK_IS_BOOSTER_ALLOWED_FRAGMENT}
	${LEVEL_DATA_PACK_EXTRA_FEE_FRAGMENT}
	${LEVEL_DATA_PACK_EXTRA_FEE_COST_FRAGMENT}
	${LEVEL_DATA_PACK_EXTRA_FEE_PROFIT_FRAGMENT}
	${LEVEL_DATA_PACK_EXTRA_FEE_PROFIT_PERCENTAGE_FRAGMENT}
	${LEVEL_DATA_PACK_PROFIT_FRAGMENT}
	${LEVEL_DATA_PACK_PROFIT_PERCENTAGE_FRAGMENT}
	fragment LevelDataPackFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		...LevelDataPackPriceFragment
		...LevelDataPackCostFragment
		...LevelDataPackIsBoosterAllowedFragment
		...LevelDataPackExtraFeeFragment
		...LevelDataPackExtraFeeCostFragment
		...LevelDataPackExtraFeeProfitFragment
		...LevelDataPackExtraFeeProfitPercentageFragment
		...LevelDataPackProfitFragment
		...LevelDataPackProfitPercentageFragment
	}
`;

export const PUBLIC_LEVEL_DATA_PACK_FRAGMENT = gql`
	${LEVEL_DATA_PACK_KEY_FRAGMENT}
	${LEVEL_DATA_PACK_PRICE_FRAGMENT}
	${LEVEL_DATA_PACK_COST_FRAGMENT}
	${LEVEL_DATA_PACK_IS_BOOSTER_ALLOWED_FRAGMENT}
	${LEVEL_DATA_PACK_EXTRA_FEE_FRAGMENT}
	${LEVEL_DATA_PACK_EXTRA_FEE_COST_FRAGMENT}
	fragment PublicLevelDataPackFragment on LevelDataPack {
		...LevelDataPackKeyFragment
		...LevelDataPackPriceFragment
		...LevelDataPackCostFragment
		...LevelDataPackIsBoosterAllowedFragment
		...LevelDataPackExtraFeeFragment
		...LevelDataPackExtraFeeCostFragment
	}
`;
