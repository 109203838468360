import { gql } from "@apollo/client";

export const OFFER_STATUS_MAPPING_ID_FRAGMENT = gql`
	fragment OfferStatusMappingIdFragment on OfferStatusMapping {
		id
	}
`;

export const OFFER_STATUS_MAPPING_WORKSPACE_ID_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	fragment OfferStatusMappingWorkspaceIdFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		workspaceId
	}
`;

export const OFFER_STATUS_MAPPING_CRM_CONNECTION_ID_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	fragment OfferStatusMappingCrmConnectionIdFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		crmConnectionId
	}
`;

export const OFFER_STATUS_MAPPING_ACTIVE_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	fragment OfferStatusMappingActiveFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		active
	}
`;

export const OFFER_STATUS_MAPPING_CREATED_AT_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	fragment OfferStatusMappingCreatedAtFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		createdAt
	}
`;

export const OFFER_STATUS_MAPPING_UPDATED_AT_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	fragment OfferStatusMappingUpdatedAtFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		updatedAt
	}
`;

export const OFFER_STATUS_MAPPING_PIPELINE_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	fragment OfferStatusMappingPipelineFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		pipeline
	}
`;

export const OFFER_STATUS_MAPPING_INDEX_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	fragment OfferStatusMappingIndexFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		index
	}
`;

export const OFFER_STATUS_MAPPING_PIPELINE_NAME_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	fragment OfferStatusMappingPipelineNameFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		pipelineName
	}
`;

export const OFFER_STATUS_MAPPING_STATUS_MAPPING_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	fragment OfferStatusMappingStatusMappingFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		statusMapping {
			pipelineStages
			status
		}
	}
`;

export const OFFER_STATUS_MAPPING_PRICE_MODEL_COMBINATIONS_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	fragment OfferStatusMappingPriceModelCombinationsFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		priceModelCombinations {
			id
		}
	}
`;

export const OFFER_STATUS_MAPPING_FRAGMENT = gql`
	${OFFER_STATUS_MAPPING_ID_FRAGMENT}
	${OFFER_STATUS_MAPPING_WORKSPACE_ID_FRAGMENT}
	${OFFER_STATUS_MAPPING_CRM_CONNECTION_ID_FRAGMENT}
	${OFFER_STATUS_MAPPING_ACTIVE_FRAGMENT}
	${OFFER_STATUS_MAPPING_CREATED_AT_FRAGMENT}
	${OFFER_STATUS_MAPPING_UPDATED_AT_FRAGMENT}
	${OFFER_STATUS_MAPPING_PIPELINE_FRAGMENT}
	${OFFER_STATUS_MAPPING_INDEX_FRAGMENT}
	${OFFER_STATUS_MAPPING_PIPELINE_NAME_FRAGMENT}
	${OFFER_STATUS_MAPPING_STATUS_MAPPING_FRAGMENT}
	${OFFER_STATUS_MAPPING_PRICE_MODEL_COMBINATIONS_FRAGMENT}
	fragment OfferStatusMappingFragment on OfferStatusMapping {
		...OfferStatusMappingIdFragment
		...OfferStatusMappingWorkspaceIdFragment
		...OfferStatusMappingCrmConnectionIdFragment
		...OfferStatusMappingActiveFragment
		...OfferStatusMappingCreatedAtFragment
		...OfferStatusMappingUpdatedAtFragment
		...OfferStatusMappingPipelineFragment
		...OfferStatusMappingIndexFragment
		...OfferStatusMappingPipelineNameFragment
		...OfferStatusMappingStatusMappingFragment
		...OfferStatusMappingPriceModelCombinationsFragment
	}
`;
