import i18next from "i18next";
import { useTranslation } from "react-i18next";

import en from "../../translation/english/translate";
import fr from "../../translation/french/translate";
import nl from "../../translation/dutch/translate";
import LanguageDetector from "i18next-browser-languagedetector";
import { currencies } from "priceit-ui";

const i18n = i18next.createInstance();

i18n.use(LanguageDetector).init({
	resources: {
		en,
		fr,
		nl,
	},
	fallbackLng: "en",
	fallbackNS: "homePage",
	load: "languageOnly",
	debug: false,
	ns: ["homePage"],
	defaultNS: "homePage",
	keySeparator: false,
	interpolation: {
		escapeValue: false,
		formatSeparator: ",",
	},
	pluralSeparator: "_",
	react: {
		useSuspense: true,
	},
	nsSeparator: false,
});
export default i18n;

export const codeToCurrency = currencyCode =>
	currencies.find(x => x.code === currencyCode)?.currency || "€";

export const isSupportedCurrencyCode = currencyCode =>
	currencies.some(x => x.code === currencyCode);

export const currencyToLocal = currencyCode =>
	currencies.find(x => x.code === currencyCode)?.locale || "fr-FR";

export const languages = [
	{
		key: "English",
		color: "#fff",
		value: "en",
	},
	{
		key: "Français",
		color: "#fff",
		value: "fr",
	},
	{
		key: "Nederlands",
		color: "#fff",
		value: "nl",
	},
];

export const timeMetricsList = () => {
	const { t } = useTranslation("metric/list");
	return [
		{ key: "onlyOnce", value: t("Non-recurring"), color: "#E657DC", adjective: null },
		{ key: "month", value: t("Per month"), color: "#5790E6", adjective: "monthly" },
		{ key: "quarter", value: t("Per quarter"), color: "#E5DC5C", adjective: "quarterly" },
		{ key: "semester", value: t("Per semester"), color: "#78CE5E", adjective: "biannually" },
		{ key: "year", value: t("Per year"), color: "#FFA631", adjective: "annually" },
		{ key: "twoYear", value: t("Every 2 years"), color: "#F8083C", adjective: "biennially" },
		{ key: "threeYear", value: t("Every 3 years"), color: "#F8083C", adjective: "triennially" },
		{
			key: "fourYear",
			value: t("Every 4 years"),
			color: "#F8083C",
			adjective: "quadrennially",
		},
		{
			key: "fiveYear",
			value: t("Every 5 years"),
			color: "#F8083C",
			adjective: "quinquennially",
		},
	];
};

export const timeMetricsObj = () => {
	const { t } = useTranslation("metric/list");
	return {
		onlyOnce: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("only once", { count });
				case "withPer":
					return t("One shot");
			}
		},
		onlyShot: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("only once", { count });
				case "withPer":
					return t("One shot");
			}
		},
		oneShot: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("only once", { count });
				case "withPer":
					return t("One shot");
			}
		},
		month: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("month", { count });
				case "withPer":
					return t("Per month");
				case "period":
					return t("monthly");
				case "gender":
					return "male";
				case "next":
					return t("next month", { count });
				case "first":
					return t("first month", { count });
			}
		},
		quarter: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("quarter", { count });
				case "withPer":
					return t("Per quarter");
				case "period":
					return t("quarterly");
				case "gender":
					return "male";
				case "next":
					return t("next quarter", { count });
				case "first":
					return t("first quarter", { count });
			}
		},
		semester: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("semester", { count });
				case "withPer":
					return t("Per semester");
				case "period":
					return t("biannually");
				case "gender":
					return "male";
				case "next":
					return t("next semester", { count });
				case "first":
					return t("first semester", { count });
			}
		},
		year: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("year", { count });
				case "female":
					return t("year", { count, context: "female" });
				case "withPer":
					return t("Per year", { context: "female" });
				case "period":
					return t("annually");
				case "gender":
					return "male";
				case "next":
					return t("next year", { count });
				case "first":
					return t("first year", { count });
			}
		},
		twoYear: (count = 1, type = "withPer") => {
			switch (type) {
				case "withPer":
					return t("Every 2 years");
				case "period":
					return t("biennially");
			}
		},
		threeYear: (count = 1, type = "withPer") => {
			switch (type) {
				case "withPer":
					return t("Every 3 years");
				case "period":
					return t("triennially");
			}
		},
		fourYear: (count = 1, type = "withPer") => {
			switch (type) {
				case "withPer":
					return t("Every 4 years");
				case "period":
					return t("quadrennially");
			}
		},
		fiveYear: (count = 1, type = "withPer") => {
			switch (type) {
				case "withPer":
					return t("Every 5 years");
				case "period":
					return t("quinquennially");
			}
		},
	};
};

export const priceMetricTrad = ({ priceMetricList, priceMetric, count, type }) => {
	if (priceMetricList?.[priceMetric]) {
		return priceMetricList?.[priceMetric](count, type);
	}
	return priceMetric;
};

export const priceMetricsObj = (language = null) => {
	const { t } = useTranslation("metric/list", { lng: language ?? undefined });
	return {
		account: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("account", { count });
				case "numberOf":
					return t("Define number of accounts");
				case "of":
					return t("of accounts");
				case "withPer":
					return t("per account");
				case "gender":
					return "male";
			}
		},
		article: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("article", { count });
				case "numberOf":
					return t("Define number of articles");
				case "of":
					return t("of articles");
				case "withPer":
					return t("per article");
				case "gender":
					return "male";
			}
		},
		analysis: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("analysis", { count });
				case "numberOf":
					return t("Define number of analysis");
				case "of":
					return t("of analysis");
				case "withPer":
					return t("per analysis");
				case "gender":
					return "female";
			}
		},
		associate: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("associate", { count });
				case "numberOf":
					return t("Define number of associates");
				case "of":
					return t("of associates");
				case "withPer":
					return t("per associate");
				case "gender":
					return "male";
			}
		},
		client: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("client", { count });
				case "numberOf":
					return t("Define number of clients");
				case "of":
					return t("of clients");
				case "withPer":
					return t("per client");
				case "gender":
					return "male";
			}
		},
		company: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("company", { count });
				case "numberOf":
					return t("Define number of companies");
				case "of":
					return t("of companies");
				case "withPer":
					return t("per company");
				case "gender":
					return "female";
			}
		},
		calculation: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("calculation", { count });
				case "numberOf":
					return t("Define number of calculations");
				case "of":
					return t("of calculations");
				case "withPer":
					return t("per calculation");
				case "gender":
					return "male";
			}
		},
		collaborator: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("collaborator", { count });
				case "numberOf":
					return t("Define number of collaborators");
				case "of":
					return t("of collaborators");
				case "withPer":
					return t("per collaborator");
				case "gender":
					return "male";
			}
		},
		construction: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("construction", { count });
				case "numberOf":
					return t("Define number of constructions");
				case "of":
					return t("of constructions");
				case "withPer":
					return t("per construction");
				case "gender":
					return "male";
			}
		},

		constructionSite: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("construction site", { count });
				case "numberOf":
					return t("Define number of construction sites");
				case "of":
					return t("of construction sites");
				case "withPer":
					return t("per construction site");
				case "gender":
					return "male";
			}
		},

		combination: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("offers template", { count });
				case "numberOf":
					return t("Define number of offers templates");
				case "of":
					return t("of offers templates");
				case "withPer":
					return t("per offers template");
				case "gender":
					return "male";
			}
		},
		credit: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("credit", { count });
				case "numberOf":
					return t("Define number of credits");
				case "of":
					return t("of credits");
				case "withPer":
					return t("per credit");
				case "gender":
					return "male";
			}
		},
		day: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("day", { count });
				case "numberOf":
					return t("Define number of days");
				case "of":
					return t("of days");
				case "withPer":
					return t("per day");
				case "gender":
					return "male";
			}
		},
		device: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("device", { count });
				case "numberOf":
					return t("Define number of devices");
				case "of":
					return t("of devices");
				case "withPer":
					return t("per device");
				case "gender":
					return "male";
			}
		},
		distribution: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("distribution", { count });
				case "numberOf":
					return t("Define number of distributions");
				case "of":
					return t("of distributions");
				case "withPer":
					return t("per distribution");
				case "gender":
					return "female";
			}
		},
		event: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("event", { count });
				case "numberOf":
					return t("Define number of events");
				case "of":
					return t("of events");
				case "withPer":
					return t("per event");
				case "gender":
					return "male";
			}
		},
		email: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("email", { count });
				case "numberOf":
					return t("Define number of emails");
				case "of":
					return t("of emails");
				case "withPer":
					return t("per email");
				case "gender":
					return "male";
			}
		},
		file: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("file", { count });
				case "numberOf":
					return t("Define number of files");
				case "of":
					return t("of files");
				case "withPer":
					return t("per file");
				case "gender":
					return "male";
			}
		},
		favorite: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("favorite", { count });
				case "numberOf":
					return t("Define number of favorites");
				case "of":
					return t("of favorites");
				case "withPer":
					return t("per favorite");
				case "gender":
					return "male";
			}
		},
		favoriteMetric: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("favorite metric", { count });
				case "numberOf":
					return t("Define number of favorite metrics");
				case "of":
					return t("of favorite metrics");
				case "withPer":
					return t("per favorite metric");
			}
		},
		g: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("g", { count });
				case "numberOf":
					return t("Define number of grams");
				case "of":
					return t("of grams");
				case "withPer":
					return t("per gram");
				case "gender":
					return "male";
			}
		},
		game: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("game", { count });
				case "numberOf":
					return t("Define number of games");
				case "of":
					return t("of games");
				case "withPer":
					return t("per game");
				case "gender":
					return "male";
			}
		},
		gallon: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("gallon", { count });
				case "numberOf":
					return t("Define number of gallons");
				case "of":
					return t("of gallons");
				case "withPer":
					return t("per gallon");
				case "gender":
					return "male";
			}
		},
		Go: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("Go", { count });
				case "numberOf":
					return t("Define number of gigaoctets");
				case "of":
					return t("of gigaoctets");
				case "withPer":
					return t("per gigaoctet");
				case "gender":
					return "male";
			}
		},
		ha: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("ha", { count });
				case "numberOf":
					return t("Define number of hectares");
				case "of":
					return t("of hectares");
				case "withPer":
					return t("per hectare");
				case "gender":
					return "male";
			}
		},
		hour: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("hour", { count });
				case "numberOf":
					return t("Define number of hours");
				case "of":
					return t("of hours");
				case "withPer":
					return t("per hour");
				case "gender":
					return "female";
			}
		},
		kg: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("kg", { count });
				case "numberOf":
					return t("Define number of kilograms");
				case "of":
					return t("of kilograms");
				case "withPer":
					return t("per kilogram");
				case "gender":
					return "male";
			}
		},
		km: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("km", { count });
				case "numberOf":
					return t("Define number of kilometers");
				case "of":
					return t("of kilometers");
				case "withPer":
					return t("per kilometer");
				case "gender":
					return "male";
			}
		},
		Ko: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("Ko", { count });
				case "numberOf":
					return t("Define number of kilooctets");
				case "of":
					return t("of kilooctets");
				case "withPer":
					return t("per kilooctet");
				case "gender":
					return "male";
			}
		},
		kWh: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("kWh", { count });
				case "numberOf":
					return t("Define number of kilowatts");
				case "of":
					return t("of kilowatts");
				case "withPer":
					return t("per kilowatt");
				case "gender":
					return "male";
			}
		},
		intervention: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("intervention", { count });
				case "numberOf":
					return t("Define number of interventions");
				case "of":
					return t("of interventions");
				case "withPer":
					return t("per intervention");
				case "gender":
					return "female";
			}
		},
		invoice: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("invoice", { count });
				case "numberOf":
					return t("Define number of invoices");
				case "of":
					return t("of invoices");
				case "withPer":
					return t("per invoice");
				case "gender":
					return "female";
			}
		},
		L: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("L", { count });
				case "numberOf":
					return t("Define length");
				case "of":
					return t("of length");
				case "withPer":
					return t("per length");
				case "gender":
					return "female";
			}
		},
		lead: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("lead", { count });
				case "numberOf":
					return t("Define number of leads");
				case "of":
					return t("of leads");
				case "withPer":
					return t("per lead");
				case "gender":
					return "male";
			}
		},
		licence: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("licence", { count });
				case "numberOf":
					return t("Define number of licences");
				case "of":
					return t("of licences");
				case "withPer":
					return t("per licence");
				case "gender":
					return "female";
			}
		},
		line: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("line", { count });
				case "numberOf":
					return t("Define number of lines");
				case "of":
					return t("of lines");
				case "withPer":
					return t("per line");
				case "gender":
					return "female";
			}
		},
		selectedLine: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("selected line", { count });
				case "numberOf":
					return t("Define number of selected lines");
				case "of":
					return t("of selected lines");
				case "withPer":
					return t("per selected lines");
				case "gender":
					return "female";
			}
		},
		m: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("m", { count });
				case "numberOf":
					return t("Define number of meters");
				case "of":
					return t("of meters");
				case "withPer":
					return t("per meter");
				case "gender":
					return "male";
			}
		},
		m2: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("m2", { count });
				case "numberOf":
					return t("Define number of m²");
				case "of":
					return t("of m²");
				case "withPer":
					return t("per m²");
				case "gender":
					return "male";
			}
		},
		m3: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("m3", { count });
				case "numberOf":
					return t("Define number of m³");
				case "of":
					return t("of m³");
				case "withPer":
					return t("per m³");
				case "gender":
					return "male";
			}
		},
		matching: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("matching", { count });
				case "numberOf":
					return t("Define number of matchings");
				case "of":
					return t("of matchings");
				case "withPer":
					return t("per matching");
				case "gender":
					return "female";
			}
		},
		Mo: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("Mo", { count });
				case "numberOf":
					return t("Define number of megaoctets");
				case "of":
					return t("of megaoctets");
				case "withPer":
					return t("per megaoctet");
				case "gender":
					return "male";
			}
		},
		octet: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("octet", { count });
				case "numberOf":
					return t("Define number of octets");
				case "of":
					return t("of octets");
				case "withPer":
					return t("per octet");
				case "gender":
					return "male";
			}
		},
		order: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("order", { count });
				case "numberOf":
					return t("Define number of orders");
				case "of":
					return t("of orders");
				case "withPer":
					return t("per order");
				case "gender":
					return "female";
			}
		},
		pack: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("pack", { count });
				case "numberOf":
					return t("Define number of packs");
				case "of":
					return t("of packs");
				case "withPer":
					return t("per pack");
				case "gender":
					return "male";
			}
		},
		person: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("person", { count });
				case "numberOf":
					return t("Define number of people");
				case "of":
					return t("of people");
				case "withPer":
					return t("per person");
				case "gender":
					return "female";
			}
		},
		pricePoint: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("price point", { count });
				case "numberOf":
					return t("Define number of price points");
				case "of":
					return t("of price points");
				case "withPer":
					return t("per price point");
				case "gender":
					return "Male";
			}
		},
		placement: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("placement", { count });
				case "numberOf":
					return t("Define number of placements");
				case "of":
					return t("of placements");
				case "withPer":
					return t("per placement");
				case "gender":
					return "male";
			}
		},
		projection: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("projection", { count });
				case "numberOf":
					return t("Define number of projections");
				case "of":
					return t("of projections");
				case "withPer":
					return t("per projection");
				case "gender":
					return "female";
			}
		},
		proposition: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("proposition", { count });
				case "numberOf":
					return t("Define number of propositions");
				case "of":
					return t("of propositions");
				case "withPer":
					return t("per proposition");
				case "gender":
					return "female";
			}
		},
		series: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("series", { count });
				case "numberOf":
					return t("Define number of series");
				case "of":
					return t("of series");
				case "withPer":
					return t("per serie");
				case "gender":
					return "female";
			}
		},
		simulation: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("simulation", { count });
				case "numberOf":
					return t("Define number of simulations");
				case "of":
					return t("of simulations");
				case "withPer":
					return t("per simulation");
				case "gender":
					return "female";
			}
		},
		site: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("site", { count });
				case "numberOf":
					return t("Define number of sites");
				case "of":
					return t("of sites");
				case "withPer":
					return t("per site");
				case "gender":
					return "male";
			}
		},
		session: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("session", { count });
				case "numberOf":
					return t("Define number of sessions");
				case "of":
					return t("of sessions");
				case "withPer":
					return t("per session");
				case "gender":
					return "female";
			}
		},
		set: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("set", { count });
				case "numberOf":
					return t("Define number of sets");
				case "of":
					return t("of sets");
				case "withPer":
					return t("per set");
				case "gender":
					return "male";
			}
		},
		spectrum: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("spectrum", { count });
				case "numberOf":
					return t("Define number of spectrums");
				case "of":
					return t("of spectrums");
				case "withPer":
					return t("per spectrum");
				case "gender":
					return "male";
			}
		},
		sale: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("sale", { count });
				case "numberOf":
					return t("sale_numberOf");
				case "of":
					return t("sale_of");
				case "withPer":
					return t("sale_per");
				case "gender":
					return "male";
			}
		},
		broker: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("broker", { count });
				case "numberOf":
					return t("broker_numberOf");
				case "of":
					return t("broker_of");
				case "withPer":
					return t("broker_per");
				case "gender":
					return "male";
			}
		},
		ellie: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("ellie", { count });
				case "numberOf":
					return t("ellie_numberOf");
				case "of":
					return t("ellie_of");
				case "withPer":
					return t("ellie_per");
				case "gender":
					return "male";
			}
		},
		scenario: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("scenario", { count });
				case "numberOf":
					return t("Define number of scenarios");
				case "of":
					return t("of scenarios");
				case "withPer":
					return t("per scenario");
				case "gender":
					return "male";
			}
		},
		point: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("point", { count });
				case "numberOf":
					return t("Define number of points");
				case "of":
					return t("of points");
				case "withPer":
					return t("per point");
				case "gender":
					return "male";
			}
		},
		cost: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("cost", { count });
				case "numberOf":
					return t("Define costs");
				case "of":
					return t("of costs");
				case "withPer":
					return t("per cost");
				case "gender":
					return "male";
			}
		},
		respondent: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("respondent", { count });
				case "numberOf":
					return t("Define number of respondents");
				case "of":
					return t("of respondents");
				case "withPer":
					return t("per respondent");
				case "gender":
					return "male";
			}
		},
		team: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("team", { count });
				case "numberOf":
					return t("Define number of teams");
				case "of":
					return t("of teams");
				case "withPer":
					return t("per team");
				case "gender":
					return "female";
			}
		},
		transaction: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("transaction", { count });
				case "numberOf":
					return t("Define number of transactions");
				case "of":
					return t("of transactions");
				case "withPer":
					return t("per transaction");
				case "gender":
					return "female";
			}
		},
		tonne: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("tonne", { count });
				case "numberOf":
					return t("Define number of tonnes");
				case "of":
					return t("of tonnes");
				case "withPer":
					return t("per tonne");
				case "gender":
					return "female";
			}
		},
		unit: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("unit", { count });
				case "numberOf":
					return t("Define number of units");
				case "of":
					return t("of units");
				case "withPer":
					return t("per unit");
				case "gender":
					return "female";
			}
		},
		user: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("user", { count });
				case "numberOf":
					return t("Define number of users");
				case "of":
					return t("of users");
				case "withPer":
					return t("per user");
				case "gender":
					return "male";
			}
		},
		visit: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("visit", { count });
				case "numberOf":
					return t("Define number of visits");
				case "of":
					return t("of visits");
				case "withPer":
					return t("per visit");
				case "gender":
					return "male";
			}
		},
		W: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("W", { count });
				case "numberOf":
					return t("Define number of watts");
				case "of":
					return t("of watts");
				case "withPer":
					return t("per watt");
				case "gender":
					return "male";
			}
		},
		feature: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("feature", { count });
				case "numberOf":
					return t("Define number of features");
				case "of":
					return t("of features");
				case "withPer":
					return t("per feature");
				case "gender":
					return "female";
			}
		},
		attribute: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("attribute", { count });
				case "numberOf":
					return t("Define number of attributes");
				case "of":
					return t("of attributes");
				case "withPer":
					return t("per attribute");
				case "gender":
					return "male";
			}
		},
		workspace: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("workspace", { count });
				case "numberOf":
					return t("Define number of workspaces");
				case "of":
					return t("of workspaces");
				case "withPer":
					return t("per workspace");
				case "gender":
					return "male";
			}
		},
		week: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("week", { count });
				case "numberOf":
					return t("Define number of weeks");
				case "of":
					return t("of weeks");
				case "withPer":
					return t("per week");
				case "gender":
					return "male";
			}
		},
		element: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("element", { count });
				case "numberOf":
					return t("Define number of elements");
				case "of":
					return t("of elements");
				case "withPer":
					return t("per element");
				case "gender":
					return "male";
			}
		},
		request: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("request", { count });
				case "numberOf":
					return t("Define number of requests");
				case "of":
					return t("of requests");
				case "withPer":
					return t("per request");
				case "gender":
					return "female";
			}
		},
		apartment: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("apartment", { count });
				case "numberOf":
					return t("Define number of apartments");
				case "of":
					return t("of apartments");
				case "withPer":
					return t("per apartment");
				case "gender":
					return "male";
			}
		},
		bottle: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("bottle", { count });
				case "numberOf":
					return t("Define number of bottles");
				case "of":
					return t("of bottles");
				case "withPer":
					return t("per bottle");
				case "gender":
					return "female";
			}
		},
		car: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("car", { count });
				case "numberOf":
					return t("Define number of cars");
				case "of":
					return t("of cars");
				case "withPer":
					return t("per car");
				case "gender":
					return "female";
			}
		},
		captor: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("captor", { count });
				case "numberOf":
					return t("Define number of captors");
				case "of":
					return t("of captors");
				case "withPer":
					return t("per captor");
				case "gender":
					return "male";
			}
		},
		mail: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("mail", { count });
				case "numberOf":
					return t("Define number of mails");
				case "of":
					return t("of mails");
				case "withPer":
					return t("per mail");
				case "gender":
					return "male";
			}
		},
		store: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("store", { count });
				case "numberOf":
					return t("Define number of stores");
				case "of":
					return t("of stores");
				case "withPer":
					return t("per store");
				case "gender":
					return "male";
			}
		},
		room: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("room", { count });
				case "numberOf":
					return t("Define number of rooms");
				case "of":
					return t("of rooms");
				case "withPer":
					return t("per room");
				case "gender":
					return "female";
			}
		},
		seat: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("seat", { count });
				case "numberOf":
					return t("Define number of seats");
				case "of":
					return t("of seats");
				case "withPer":
					return t("per seat");
				case "gender":
					return "male";
			}
		},
		building: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("building", { count });
				case "numberOf":
					return t("Define number of buildings");
				case "of":
					return t("of buildings");
				case "withPer":
					return t("per building");
				case "gender":
					return "male";
			}
		},
		meeting: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("meeting", { count });
				case "numberOf":
					return t("Define number of meetings");
				case "of":
					return t("of meetings");
				case "withPer":
					return t("per meeting");
				case "gender":
					return "female";
			}
		},
		member: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("member", { count });
				case "numberOf":
					return t("Define number of members");
				case "of":
					return t("of members");
				case "withPer":
					return t("per member");
				case "gender":
					return "male";
			}
		},
		box: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("box", { count });
				case "numberOf":
					return t("Define number of boxes");
				case "of":
					return t("of boxes");
				case "withPer":
					return t("per box");
				case "gender":
					return "female";
			}
		},
		maintenance: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("maintenance", { count });
				case "numberOf":
					return t("Define number of maintenances");
				case "of":
					return t("of maintenances");
				case "withPer":
					return t("per maintenance");
				case "gender":
					return "female";
			}
		},
		contact: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("contact", { count });
				case "numberOf":
					return t("Define number of contacts");
				case "of":
					return t("of contacts");
				case "withPer":
					return t("per contact");
				case "gender":
					return "male";
			}
		},
		wash: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("wash", { count });
				case "numberOf":
					return t("Define number of washs");
				case "of":
					return t("of washs");
				case "withPer":
					return t("per wash");
				case "gender":
					return "male";
			}
		},
		plan: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("plan", { count });
				case "numberOf":
					return t("Define number of plans");
				case "of":
					return t("of plans");
				case "withPer":
					return t("per plan");
				case "gender":
					return "male";
			}
		},
		project: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("project", { count });
				case "numberOf":
					return t("Define number of projects");
				case "of":
					return t("of projects");
				case "withPer":
					return t("per project");
				case "gender":
					return "male";
			}
		},
		test: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("test", { count });
				case "numberOf":
					return t("Define number of tests");
				case "of":
					return t("of tests");
				case "withPer":
					return t("per test");
				case "gender":
					return "male";
			}
		},
		yogi: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("yogi", { count });
				case "numberOf":
					return t("Define number of yogis");
				case "of":
					return t("of yogis");
				case "withPer":
					return t("per yogi");
				case "gender":
					return "male";
			}
		},
		zone: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("zone", { count });
				case "numberOf":
					return t("Define number of zones");
				case "of":
					return t("of zones");
				case "withPer":
					return t("per zone");
				case "gender":
					return "female";
			}
		},
		citizen: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("citizen", { count });
				case "numberOf":
					return t("Define number of citizens");
				case "of":
					return t("of citizens");
				case "withPer":
					return t("per citizen");
				case "gender":
					return "male";
			}
		},
		pass: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("pass", { count });
				case "numberOf":
					return t("Define number of pass");
				case "of":
					return t("of pass");
				case "withPer":
					return t("per pass");
				case "gender":
					return "male";
			}
		},
		job: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("job", { count });
				case "numberOf":
					return t("Define number of jobs");
				case "of":
					return t("of jobs");
				case "withPer":
					return t("per job");
				case "gender":
					return "male";
			}
		},
		view: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("view", { count });
				case "numberOf":
					return t("Define number of views");
				case "of":
					return t("of views");
				case "withPer":
					return t("per view");
				case "gender":
					return "female";
			}
		},
		student: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("student", { count });
				case "numberOf":
					return t("Define number of students");
				case "of":
					return t("of students");
				case "withPer":
					return t("per student");
				case "gender":
					return "male";
			}
		},
		employee: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("employee", { count });
				case "numberOf":
					return t("Define number of employees");
				case "of":
					return t("of employees");
				case "withPer":
					return t("per employee");
				case "gender":
					return "male";
			}
		},
		worker: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("worker", { count });
				case "numberOf":
					return t("Define number of workers");
				case "of":
					return t("of workers");
				case "withPer":
					return t("per worker");
				case "gender":
					return "male";
			}
		},
		manager: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("manager", { count });
				case "numberOf":
					return t("Define number of managers");
				case "of":
					return t("of managers");
				case "withPer":
					return t("per manager");
				case "gender":
					return "male";
			}
		},
		family: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("family", { count });
				case "numberOf":
					return t("Define number of families");
				case "of":
					return t("of families");
				case "withPer":
					return t("per family");
				case "gender":
					return "female";
			}
		},
		payslip: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("payslip", { count });
				case "numberOf":
					return t("Define number of payslips");
				case "of":
					return t("of payslips");
				case "withPer":
					return t("by payslip");
				case "gender":
					return "female";
			}
		},
		payroll: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("payroll", { count });
				case "numberOf":
					return t("Define number of payrolls");
				case "of":
					return t("of payrolls");
				case "withPer":
					return t("by payroll");
				case "gender":
					return "female";
			}
		},
		loan: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("loan", { count });
				case "numberOf":
					return t("Define number of loans");
				case "of":
					return t("of loans");
				case "withPer":
					return t("per loan");
				case "gender":
					return "male";
			}
		},
		entity: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("entity", { count });
				case "numberOf":
					return t("Define number of entities");
				case "of":
					return t("of entities");
				case "withPer":
					return t("per entity");
				case "gender":
					return "female";
			}
		},
		lesson: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("lesson", { count });
				case "numberOf":
					return t("Define number of lessons");
				case "of":
					return t("of lessons");
				case "withPer":
					return t("per lesson");
				case "gender":
					return "female";
			}
		},
		representative: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("representative", { count });
				case "numberOf":
					return t("Define number of representatives");
				case "of":
					return t("of representatives");
				case "withPer":
					return t("per representative");
				case "gender":
					return "male";
			}
		},
		agent: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("agent", { count });
				case "numberOf":
					return t("Define number of agents");
				case "of":
					return t("of agents");
				case "withPer":
					return t("per agent");
				case "gender":
					return "male";
			}
		},
		host: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("host", { count });
				case "numberOf":
					return t("Define number of hosts");
				case "of":
					return t("of hosts");
				case "withPer":
					return t("per host");
				case "gender":
					return "male";
			}
		},
		report: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("report", { count });
				case "numberOf":
					return t("Define number of reports");
				case "of":
					return t("of reports");
				case "withPer":
					return t("per report");
				case "gender":
					return "male";
			}
		},
		shareclass: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("shareclass", { count });
				case "numberOf":
					return t("Define number of shareclasses");
				case "of":
					return t("of shareclasses");
				case "withPer":
					return t("per shareclass");
				case "gender":
					return "female";
			}
		},
		yearlyReport: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("yearly report", { count });
				case "numberOf":
					return t("Define number of yearly reports");
				case "of":
					return t("of yearly reports");
				case "withPer":
					return t("per yearly report");
				case "gender":
					return "male";
			}
		},
		holding: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("holding", { count });
				case "numberOf":
					return t("Define number of holdings");
				case "of":
					return t("of holdings");
				case "withPer":
					return t("per holding");
				case "gender":
					return "male";
			}
		},
		workman: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("workman", { count });
				case "numberOf":
					return t("Define number of workmen");
				case "of":
					return t("of workmen");
				case "withPer":
					return t("per workman");
				case "gender":
					return "male";
			}
		},
		advert: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("advert", { count });
				case "numberOf":
					return t("Define number of adverts");
				case "of":
					return t("of adverts");
				case "withPer":
					return t("per advert");
				case "gender":
					return "male";
			}
		},
		bike: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("bike", { count });
				case "numberOf":
					return t("Define number of bikes");
				case "of":
					return t("of bikes");
				case "withPer":
					return t("per bike");
				case "gender":
					return "male";
			}
		},
		eBike: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("e-bike", { count });
				case "numberOf":
					return t("Define number of e-bikes");
				case "of":
					return t("of e-bikes");
				case "withPer":
					return t("per e-bike");
				case "gender":
					return "male";
			}
		},
		street: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("street", { count });
				case "numberOf":
					return t("Define number of streets");
				case "of":
					return t("of streets");
				case "withPer":
					return t("per street");
				case "gender":
					return "female";
			}
		},
		video: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("video", { count });
				case "numberOf":
					return t("Define number of videos");
				case "of":
					return t("of videos");
				case "withPer":
					return t("per video");
				case "gender":
					return "female";
			}
		},
		ft2: (count = 1, type = "default") => {
			switch (type) {
				case "default":
					return t("ft²", { count });
				case "numberOf":
					return t("Define number of ft²");
				case "of":
					return t("of ft²");
				case "withPer":
					return t("per ft²");
				case "gender":
					return "female";
			}
		},
		null: () => "",
	};
};

export const modulesAndOptionsNamesObj = () => {
	const { t } = useTranslation("moduleNames/list");
	return {
		resources: (count = 1) => t("Resources", { count }),
		attributesLibrary: (count = 1) => t("Value attributes", { count }),
		financialModel: (count = 1) => t("Financial Model", { count }),
		businessCasing: (count = 1) => t("Business Casing", { count }),
		learningTools: (count = 1) => t("Learning Tools", { count }),
		specificAnalyses: (count = 1) => t("Specific Analyses", { count }),
		pricingTools: (count = 1) => t("Pricing Tools", { count }),
		offersPipeline: (count = 1) => t("Offers pipeline", { count }),
		hideItemsOnOffer: (count = 1) =>
			t("Hide items on the offer (rules, packs, prices)", { count }),
		pricingRoadmap: (count = 1) => t("Pricing Roadmap", { count }),
		revenue: (count = 1) => t("Revenue", { count }),
		variableCosts: (count = 1) => t("Variable Costs", { count }),
		fixedCosts: (count = 1) => t("Fixed Costs", { count }),
		profitability: (count = 1) => t("Profitability", { count }),
		constraints: (count = 1) => t("Constraints", { count }),
		designAndSettings: (count = 1) => t("Design and settings", { count }),
		projection: (count = 1) => t("Projections", { count }),
		potential: (count = 1) => t("Potential", { count }),
		distribution: (count = 1) => t("Distributions", { count }),
		priceSensitivityMeter: (count = 1) => t("Price Sensitivity Analyser", { count }),
		priceSensitivityAnalyser: (count = 1) => t("Price Sensitivity Analyser", { count }),
		dashboard: (count = 1) => t("Dashboard", { count }),
		pricingScore: (count = 1) => t("Pricing score", { count }),
		packagePricing: (count = 1) => t("Package builder", { count }),
		packageBuilder: (count = 1) => t("Package builder", { count }),
		pricingPlansLibrary: (count = 1) => t("Pack & pricing pages", { count }),
		offerTemplatesLibrary: (count = 1) => t("Offer templates", { count }),
		costBased: (count = 1) => t("Markup simulation", { count }),
		valuePerceptionModeling: (count = 1) => t("Value perception modeling", { count }),
		psychologicalTricks: (count = 1) => t("Psychological tricks", { count }),
		valueMap: (count = 1) => t("Value map", { count }),
		settings: (count = 1) => t("Settings", { count }),
		support: (count = 1) => t("Support", { count }),
		sponsorship: (count = 1) => t("Become a sponsor Givemefive", { count }),
		logOut: (count = 1) => t("Log out", { count }),
		workspaces: (count = 1) => t("Workspaces", { count }),
		team: (count = 1) => t("Team", { count }),
		plan: (count = 1) => t("Plan", { count }),
		integrations: (count = 2) => t("Integration", { count }),
		profile: (count = 1) => t("Profile", { count }),
		null: () => "",
		removablePriceitBranding: (count = 1) => t("Removable Givemefive branding", { count }),
		clientsValuePricePerception: (count = 1) => t("Clients value-price perception", { count }),
		profitabilityAndCosts: (count = 1) => t("Profitability", { count }),
		offerConfigurationTab: (count = 1) => t("Offer configuration", { count }),
		priceDifferentiationRulesTab: (count = 1) => t("Price differentiation rules", { count }),
		offerDesignAndSettingsTab: (count = 1) => t("Design", { count }),
		advancedCustomColor: (count = 1) => t("Enable advanced colors personalization", { count }),
		multiCurrency: (count = 1) => t("Allow multi-currency", { count }),
		temporaryDiscount: (count = 1) => t("Temporary discount", { count }),
		indicators: (count = 1) => t("Indicators", { count }),
		keyboardShortcuts: (count = 1) => t("Keyboard shortcuts", { count }),
		givemefiveAI: (count = 1) => t("Givemefive AI", { count }),
		quoteGeneration: (count = 1) => t("PDF generator", { count }),
	};
};

export const permissionsNamesObj = () => {
	const { t } = useTranslation("permissionNames/list");
	return {
		0: (count = 1) => t("Owner", { count }),
		1: (count = 1) => t("Admin", { count }),
	};
};

export const useWebhookEventsNamesObj = () => {
	const { t } = useTranslation("webhookEventsNames/list");
	return {
		CreateOffer: (count = 1) => t("On offer creation event", { count }),
		OpenQuote: (count = 1) => t("On quote opening event", { count }),
		GenerateQuote: (count = 1) => t("On quote request event", { count }),
		SignQuote: (count = 1) => t("On signature event", { count }),
	};
};
