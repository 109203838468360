import { gql } from "@apollo/client";
import {
	PRICE_MODEL_FRAGMENT,
	PRICE_MODEL_METRICS_FRAGMENT,
	PRICE_MODEL_VOLUME_INFORMATION_FRAGMENT,
	PRICE_MODEL_NAME_FRAGMENT,
	PRICE_MODEL_PACKS_FRAGMENT,
} from "@graphQl/fragments/priceModel";

export const ID_PRICE_MODEL_LINK_COMBINATION_FRAGMENT = gql`
	fragment IdPriceModelLinkCombinationFragment on PriceModelLinkCombination {
		id
	}
`;

export const NAME_PRICE_MODEL_LINK_COMBINATION_FRAGMENT = gql`
	fragment NamePriceModelLinkCombinationFragment on PriceModelLinkCombination {
		id
		name
	}
`;

export const VAT_ID_PRICE_MODEL_LINK_COMBINATION_FRAGMENT = gql`
	fragment VatIdPriceModelLinkCombinationFragment on PriceModelLinkCombination {
		id
		vATId
	}
`;

export const INDEX_PRICE_MODEL_LINK_COMBINATION_FRAGMENT = gql`
	fragment IndexPriceModelLinkCombinationFragment on PriceModelLinkCombination {
		id
		index
	}
`;

export const PRICE_MODEL_ID_PRICE_MODEL_LINK_COMBINATION_FRAGMENT = gql`
	fragment PriceModelIdPriceModelLinkCombinationFragment on PriceModelLinkCombination {
		id
		priceModelId
	}
`;

export const PRICE_MODEL_COMBINATION_ID_PRICE_MODEL_LINK_COMBINATION_FRAGMENT = gql`
	fragment PriceModelCombinationIdPriceModelLinkCombinationFragment on PriceModelLinkCombination {
		id
		priceModelCombinationId
	}
`;

export const MANDATORY_PRICE_MODEL_LINK_COMBINATION_FRAGMENT = gql`
	fragment MandatoryPriceModelLinkCombinationFragment on PriceModelLinkCombination {
		id
		mandatory
	}
`;

export const PRICE_MODEL_LINKED_LANGUAGE_COMBINATION_FRAGMENT = gql`
	fragment PriceModelLinkedLanguageCombinationFragment on PriceModelLinkCombination {
		id
		priceModel {
			id
			language
		}
	}
`;

export const PRICE_MODEL_LINKED_METRICS_COMBINATION_FRAGMENT = gql`
	${PRICE_MODEL_METRICS_FRAGMENT}
	${PRICE_MODEL_NAME_FRAGMENT}
	fragment PriceModelLinkedMetricsCombinationFragment on PriceModelLinkCombination {
		id
		priceModel {
			...PriceModelMetricsFragment
			...PriceModelNameFragment
		}
	}
`;

export const PRICE_MODEL_LINKED_PRICE_MODELS_COMBINATION_FRAGMENT = gql`
	${PRICE_MODEL_PACKS_FRAGMENT}
	fragment PriceModelLinkedPriceModelsCombinationFragment on PriceModelLinkCombination {
		id
		priceModel {
			...PriceModelPacksFragment
		}
	}
`;

export const PRICE_MODEL_VOLUMES_INFORMATION_COMBINATION_FRAGMENT = gql`
	${PRICE_MODEL_METRICS_FRAGMENT}
	${PRICE_MODEL_VOLUME_INFORMATION_FRAGMENT}
	fragment PriceModelVolumeInformationsCombinationFragment on PriceModelLinkCombination {
		id
		priceModel {
			...PriceModelMetricsFragment
			...PriceModelVolumeInformationFragment
		}
	}
`;

export const PRICE_MODEL_LINK_COMBINATION_FRAGMENT = gql`
	${ID_PRICE_MODEL_LINK_COMBINATION_FRAGMENT}
	${NAME_PRICE_MODEL_LINK_COMBINATION_FRAGMENT}
	${VAT_ID_PRICE_MODEL_LINK_COMBINATION_FRAGMENT}
	${MANDATORY_PRICE_MODEL_LINK_COMBINATION_FRAGMENT}
	${INDEX_PRICE_MODEL_LINK_COMBINATION_FRAGMENT}
	${PRICE_MODEL_ID_PRICE_MODEL_LINK_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_ID_PRICE_MODEL_LINK_COMBINATION_FRAGMENT}
	${PRICE_MODEL_FRAGMENT}
	fragment PriceModelLinkCombinationFragment on PriceModelLinkCombination {
		...IdPriceModelLinkCombinationFragment
		...NamePriceModelLinkCombinationFragment
		...VatIdPriceModelLinkCombinationFragment
		...MandatoryPriceModelLinkCombinationFragment
		...IndexPriceModelLinkCombinationFragment
		...PriceModelIdPriceModelLinkCombinationFragment
		...PriceModelCombinationIdPriceModelLinkCombinationFragment
		priceModel {
			...PriceModelFragment
		}
	}
`;
