import { gql } from "@apollo/client";
import { PRICE_MODEL_METRIC_FRAGMENT } from "./priceModelMetric";
import { PRICE_MODEL_TIME_METRIC_DATA_FRAGMENT } from "./priceModelTimeMetric";

export const PRICE_COMMUNICATION_DISPLAYED_GENERAL_TITLE_FRAGMENT = gql`
	fragment PriceCommunicationDisplayedGeneralTitleFragment on PriceModel {
		id
		displayedGeneralTitle
	}
`;

export const PRICE_COMMUNICATION_DISPLAYED_CUSTOM_OFFER_LABEL_FRAGMENT = gql`
	fragment PriceCommunicationDisplayedCustomOfferLabelFragment on PriceModelDesignAndSetting {
		id
		displayedCustomOfferLabel
		priceModelId
	}
`;

export const PRICE_COMMUNICATION_DISPLAYED_DESCRIPTION_FRAGMENT = gql`
	fragment PriceCommunicationDisplayedDescriptionFragment on PriceModel {
		id
		displayedDescription
	}
`;

export const PRICE_COMMUNICATION_LANGUAGE_FRAGMENT = gql`
	fragment PriceCommunicationLanguageFragment on PriceModel {
		id
		language
	}
`;

export const PRICE_COMMUNICATION_SIMPLIFY_VIEW_FRAGMENT = gql`
	fragment PriceCommunicationSimplifyViewFragment on PriceModel {
		id
		simplifyViewSectionTitles
	}
`;

export const PRICE_COMMUNICATION_BACKGROUND_PRIMARY_COLOR_FRAGMENT = gql`
	fragment PriceCommunicationBackgroundPrimaryColorFragment on PriceModel {
		id
		backgroundPrimaryColor
	}
`;

export const PRICE_COMMUNICATION_PACK_HIGHLIGHTED_FRAGMENT = gql`
	fragment PriceCommunicationPackHighlightedFragment on PriceModel {
		id
		packHighlighted
	}
`;

export const PRICE_COMMUNICATION_FLAG_FRAGMENT = gql`
	fragment PriceCommunicationFlagFragment on PriceModelDesignAndSetting {
		id
		flag
		priceModelId
	}
`;

export const PRICE_COMMUNICATION_CORNER_RADIUS_FRAGMENT = gql`
	fragment PriceCommunicationCornerRadiusFragment on PriceModelDesignAndSetting {
		id
		cornerRadius
		priceModelId
	}
`;

export const PRICE_COMMUNICATION_CORNER_RADIUS_AND_FLAG_INFO_FRAGMENT = gql`
	${PRICE_COMMUNICATION_CORNER_RADIUS_FRAGMENT}
	${PRICE_COMMUNICATION_FLAG_FRAGMENT}
	fragment PriceCommunicationCornerRadiusAndFlagInfoFragment on PriceModelDesignAndSetting {
		id
		...PriceCommunicationCornerRadiusFragment
		...PriceCommunicationFlagFragment
	}
`;

export const TEXT_AND_STYLE_OPTIONS_FOR_PRICE_MODELS_FRAGMENT = gql`
	${PRICE_COMMUNICATION_DISPLAYED_GENERAL_TITLE_FRAGMENT}
	fragment TextAndStyleOptionsPriceCommunicationFragment on PriceModel {
		id
		...PriceCommunicationDisplayedGeneralTitleFragment
	}
`;

export const LANGUAGE_OPTIONS_FOR_PRICE_MODELS_FRAGMENT = gql`
	${PRICE_COMMUNICATION_LANGUAGE_FRAGMENT}
	fragment LanguageOptionsPriceCommunicationFragment on PriceModel {
		id
		...PriceCommunicationLanguageFragment
	}
`;

export const SIMPLIFY_VIEW_OPTIONS_FOR_PRICE_MODELS_FRAGMENT = gql`
	${PRICE_COMMUNICATION_SIMPLIFY_VIEW_FRAGMENT}
	fragment SimplifyViewOptionsPriceCommunicationFragment on PriceModel {
		id
		...PriceCommunicationSimplifyViewFragment
	}
`;

export const PRICE_MODEL_DISPLAYED_DESCRIPTION_FRAGMENT = gql`
	fragment PriceModelDisplayedDescriptionFragment on PriceModelDesignAndSetting {
		id
		displayedDescription
		priceModelId
	}
`;

export const PRICE_MODEL_FEATURES_FRAGMENT = gql`
	fragment PriceModelFeaturesFragment on PriceModel {
		id
		features {
			id
			isTemplate
			description
			name
		}
	}
`;

export const PRICE_MODEL_PRIMARY_TIME_METRIC_FRAGMENT = gql`
	${PRICE_MODEL_TIME_METRIC_DATA_FRAGMENT}
	fragment PriceModelPrimaryTimeMetricFragment on PriceModel {
		id
		primaryTimeMetric {
			...PriceModelTimeMetricDataFragment
		}
	}
`;

export const PRICE_MODEL_SECONDARY_TIME_METRIC_FRAGMENT = gql`
	${PRICE_MODEL_TIME_METRIC_DATA_FRAGMENT}
	fragment PriceModelSecondaryTimeMetricFragment on PriceModel {
		id
		secondaryTimeMetric {
			...PriceModelTimeMetricDataFragment
		}
	}
`;

export const PRICE_MODEL_ENABLE_SECONDARY_TIME_METRIC_FRAGMENT = gql`
	fragment PriceModelEnableSecondaryTimeMetricFragment on PriceModel {
		id
		enableSecondaryPriceDisplay
	}
`;

export const PRICE_MODEL_PRIMARY_METRICS_FRAGMENT = gql`
	${PRICE_MODEL_METRIC_FRAGMENT}
	fragment PriceModelPrimaryMetricsFragment on PriceModel {
		id
		primaryMetrics {
			...PriceModelMetric
		}
	}
`;

export const PRICE_MODEL_SECONDARY_METRICS_FRAGMENT = gql`
	${PRICE_MODEL_METRIC_FRAGMENT}
	fragment PriceModelSecondaryMetricsFragment on PriceModel {
		id
		secondaryMetrics {
			...PriceModelMetric
		}
	}
`;

export const PRICE_MODEL_SHOW_SAVINGS_FRAGMENT = gql`
	fragment PriceModelShowSavingsFragment on PriceModel {
		id
		showSavings
	}
`;

export const PRICE_MODEL_DISPLAYED_COMMITMENT_DEFAULT_FRAGMENT = gql`
	fragment PriceModelDisplayedCommitmentDefaultFragment on PriceModel {
		id
		displayedCommitmentInputDefault
	}
`;

export const PRICE_MODEL_DISPLAYED_VOLUME_DEFAULT_FRAGMENT = gql`
	fragment PriceModelDisplayedVolumeDefaultFragment on PriceModel {
		id
		displayedVolumeInputDefault
	}
`;
