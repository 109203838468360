import { gql } from "@apollo/client";
import {
	USER_EMAIL_FRAGMENT,
	USER_FRAGMENT,
	USER_IMAGE_PATH_FRAGMENT,
	USER_SURNAME_FRAGMENT,
	USER_USERNAME_FRAGMENT,
} from "@graphQl/fragments/user";
import { TOKEN_FRAGMENT } from "@graphQl/fragments/token";
import { WORKSPACE_NAME_FRAGMENT } from "@graphQl/fragments/workspace";
import {
	LAST_OFFER_VERSION_FRAGMENT,
	LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_ENABLE_MAX_GLOBAL_DISCOUNT_FRAGMENT,
	LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_MAX_GLOBAL_DISCOUNT_DATA_FRAGMENT,
	OFFER_VERSION_FRAGMENT,
	OFFER_VERSION_OFFER_VERSION_QUOTE_SIGNED_AT_FRAGMENT,
	OFFER_VERSION_OFFER_VERSION_QUOTES_FRAGMENT,
	OFFER_VERSION_OFFER_VERSION_RULES_FRAGMENT,
	OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT,
	OFFER_VERSION_PRICE_MODEL_COMBINATION_ID_FRAGMENT,
	SIGNATURE_LINK_LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT,
} from "@graphQl/fragments/offerVersion";
import { CRM_CONNECTION_SERVICE_INFOS_FRAGMENT } from "@src/graphQl/fragments/crmConnection";
import { SIGNATURE_CONNECTION_SERVICE_INFOS_FRAGMENT } from "@graphQl/fragments/signature";
import { STRIPE_CONNECTION_SERVICE_INFOS_FRAGMENT } from "@graphQl/fragments/paymentConnection";
import { OFFER_CONTACT_FRAGMENT, OFFER_CONTACT_PUBLIC_FRAGMENT } from "./offerContact";
import { ADDRESS_FRAGMENT } from "./address";
import { OFFER_STATUS_FRAGMENT } from "@graphQl/fragments/offerStatus";
import { OFFER_PROPERTIES_FOR_OFFER_FRAGMENT } from "./offerProperties";

export const OFFER_ID_FRAGMENT = gql`
	fragment OfferIdFragment on Offer {
		id
	}
`;

export const OFFER_NAME_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferNameFragment on Offer {
		...OfferIdFragment
		name
	}
`;

export const OFFER_COMPANY_NAME_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferCompanyNameFragment on Offer {
		...OfferIdFragment
		companyName
	}
`;

export const OFFER_OWNER_ID_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferOwnerIdFragment on Offer {
		...OfferIdFragment
		ownerId
	}
`;

export const OFFER_OWNER_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${USER_FRAGMENT}
	fragment OfferOwnerFragment on Offer {
		...OfferIdFragment
		owner {
			...UserFragment
		}
	}
`;

export const OFFER_OWNER_FOR_OWNER_COLUMN_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	${USER_USERNAME_FRAGMENT}
	${USER_SURNAME_FRAGMENT}
	${USER_EMAIL_FRAGMENT}
	fragment OfferOwnerForOwnerColumnFragment on Offer {
		...OfferIdFragment
		owner {
			...UserImagePathFragment
			...UserUserNameFragment
			...UserSurnameFragment
			...UserEmailFragment
		}
	}
`;

export const OFFER_OWNER_FOR_OWNER_FILTER_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	${USER_USERNAME_FRAGMENT}
	fragment OfferOwnerForOwnerFilterFragment on Offer {
		...OfferIdFragment
		owner {
			...UserImagePathFragment
			...UserUserNameFragment
		}
	}
`;

export const OFFER_CREATED_AT_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferCreatedAtFragment on Offer {
		...OfferIdFragment
		createdAt
	}
`;

export const OFFER_UPDATED_AT_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferUpdatedAtFragment on Offer {
		...OfferIdFragment
		updatedAt
	}
`;

export const OFFER_CREATED_BY_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferCreatedByFragment on Offer {
		...OfferIdFragment
		createdBy
	}
`;

export const OFFER_UPDATED_BY_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferUpdatedByFragment on Offer {
		...OfferIdFragment
		updatedBy
	}
`;

export const OFFER_TOKEN_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${TOKEN_FRAGMENT}
	fragment OfferTokenFragment on Offer {
		...OfferIdFragment
		tokenObj {
			...TokenFragment
		}
	}
`;

export const OFFER_WORKSPACE_ID_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferWorkspaceIdFragment on Offer {
		...OfferIdFragment
		workspaceId
	}
`;

export const OFFER_EMAIL_REQUIRED_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferEmailRequiredFragment on Offer {
		...OfferIdFragment
		emailRequired
	}
`;

export const OFFER_VAT_NUMBER_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferVATNumberFragment on Offer {
		...OfferIdFragment
		VATNumber
	}
`;

export const OFFER_LICENSE_START_DATE_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferLicenseStartDateFragment on Offer {
		...OfferIdFragment
		licenseStartDate
	}
`;

export const OFFER_MIN_AMOUNT_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferMinAmountFragment on Offer {
		...OfferIdFragment
		minAmount
	}
`;

export const OFFER_OFFER_VERSIONS_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_VERSION_FRAGMENT}
	fragment OfferOfferVersionsFragment on Offer {
		...OfferIdFragment
		offerVersions {
			...OfferVersionFragment
		}
	}
`;

export const OFFER_OFFER_VERSIONS_QUOTE_SIGNED_AT_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_QUOTE_SIGNED_AT_FRAGMENT}
	fragment OfferOfferVersionsQuoteSignedAtFragment on Offer {
		...OfferIdFragment
		offerVersions {
			...OfferVersionOfferVersionQuoteSignedAtFragment
		}
	}
`;

export const OFFER_OFFER_VERSIONS_FOR_LAST_QUOTE_COLUMN_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_QUOTES_FRAGMENT}
	fragment OfferOfferVersionsForLastQuoteColumnFragment on Offer {
		...OfferIdFragment
		offerVersions {
			...OfferVersionOfferVersionQuotesFragment
		}
	}
`;

export const OFFER_OFFER_VERSIONS_FOR_PRICING_PAGE_TEMPLATE_COLUMN_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment OfferOfferVersionsForPricingPageTemplateColumnFragment on Offer {
		...OfferIdFragment
		offerVersions {
			...OfferVersionPriceModelCombinationFragment
		}
	}
`;

export const OFFER_QUOTE_OFFER_EMAILS_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${USER_FRAGMENT}
	fragment OfferQuoteOfferEmailsFragment on Offer {
		...OfferIdFragment
		quoteOfferEmails {
			...UserFragment
		}
	}
`;

export const OFFER_OPEN_OFFER_EMAILS_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${USER_FRAGMENT}
	fragment OfferOpenOfferEmailsFragment on Offer {
		...OfferIdFragment
		openOfferEmails {
			...UserFragment
		}
	}
`;

export const OFFER_QUOTE_LAYOUT_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferQuoteLayoutFragment on Offer {
		...OfferIdFragment
		quoteLayout
	}
`;

export const OFFER_SEND_DATE_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferSendDateFragment on Offer {
		...OfferIdFragment
		sendDate
	}
`;

export const OFFER_CLOSING_DATE_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferClosingDateFragment on Offer {
		...OfferIdFragment
		closingDate
	}
`;

export const OFFER_HEAT_SCORE_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferHeatScoreFragment on Offer {
		...OfferIdFragment
		heatScore
	}
`;

export const OFFER_NB_VIEWS_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferNbViewsFragment on Offer {
		...OfferIdFragment
		nbViews
	}
`;

export const OFFER_NB_UNIQUE_EMAIL_VIEWS_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferNbUniqueEmailViewsFragment on Offer {
		...OfferIdFragment
		nbUniqueEmailViews
	}
`;

export const OFFER_ADDRESS_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${ADDRESS_FRAGMENT}
	fragment OfferAddressFragment on Offer {
		...OfferIdFragment
		address {
			...AddressFragment
		}
	}
`;

export const OFFER_NB_DAYS_SINCE_LAST_OPENING_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferNbDaysSinceLastOpeningFragment on Offer {
		...OfferIdFragment
		nbDaysSinceLastOpening
	}
`;

export const OFFER_NB_DAYS_BETWEEN_SENDING_AND_FIRST_OPENING_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferNbDaysBetweenSendingAndFirstOpeningFragment on Offer {
		...OfferIdFragment
		nbDaysBetweenSendingAndFirstOpening
	}
`;

export const OFFER_NAME_AND_COMPANY_NAME_AND_ENABLE_AND_HAS_PASSWORD_FRAGMENT = gql`
	${OFFER_NAME_FRAGMENT}
	${OFFER_COMPANY_NAME_FRAGMENT}
	${OFFER_TOKEN_FRAGMENT}
	fragment OfferNameAndCompanyNameAndEnableAndHasPasswordFragment on Offer {
		...OfferNameFragment
		...OfferCompanyNameFragment
		...OfferTokenFragment
	}
`;

export const OFFER_CRM_DEAL_ID_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferCrmDealIdFragment on Offer {
		...OfferIdFragment
		crmDealId
	}
`;

export const OFFER_CRM_DEAL_ID_AND_SERVICE_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_CRM_DEAL_ID_FRAGMENT}
	fragment OfferCrmDealIdAndServiceFragment on Offer {
		...OfferIdFragment
		...OfferCrmDealIdFragment
		crmDealConnection {
			id
			service {
				id
			}
		}
	}
`;

export const OFFER_OFFER_STATUS_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_STATUS_FRAGMENT}
	fragment OfferOfferStatusFragment on Offer {
		...OfferIdFragment
		status {
			...OfferStatusFragment
		}
	}
`;

export const OFFER_MANDATORY_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_OWNER_ID_FRAGMENT}
	${OFFER_NAME_FRAGMENT}
	${OFFER_COMPANY_NAME_FRAGMENT}
	${OFFER_OWNER_FRAGMENT}
	${OFFER_ADDRESS_FRAGMENT}
	fragment OfferMandatoryFragment on Offer {
		...OfferIdFragment
		...OfferOwnerIdFragment
		...OfferNameFragment
		...OfferCompanyNameFragment
		...OfferOwnerFragment
		...OfferAddressFragment
	}
`;

export const OFFER_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_OWNER_ID_FRAGMENT}
	${OFFER_NAME_FRAGMENT}
	${OFFER_COMPANY_NAME_FRAGMENT}
	${OFFER_OWNER_FRAGMENT}
	${OFFER_WORKSPACE_ID_FRAGMENT}
	${OFFER_OFFER_VERSIONS_FRAGMENT}
	${OFFER_TOKEN_FRAGMENT}
	${OFFER_EMAIL_REQUIRED_FRAGMENT}
	${OFFER_QUOTE_OFFER_EMAILS_FRAGMENT}
	${OFFER_OPEN_OFFER_EMAILS_FRAGMENT}
	${OFFER_CREATED_AT_FRAGMENT}
	${OFFER_UPDATED_AT_FRAGMENT}
	${OFFER_CREATED_BY_FRAGMENT}
	${OFFER_UPDATED_BY_FRAGMENT}
	${OFFER_CRM_DEAL_ID_AND_SERVICE_FRAGMENT}
	${OFFER_CONTACT_FRAGMENT}
	${OFFER_QUOTE_LAYOUT_FRAGMENT}
	${OFFER_VAT_NUMBER_FRAGMENT}
	${OFFER_SEND_DATE_FRAGMENT}
	${OFFER_CLOSING_DATE_FRAGMENT}
	${OFFER_HEAT_SCORE_FRAGMENT}
	${OFFER_NB_VIEWS_FRAGMENT}
	${OFFER_NB_UNIQUE_EMAIL_VIEWS_FRAGMENT}
	${OFFER_NB_DAYS_SINCE_LAST_OPENING_FRAGMENT}
	${OFFER_NB_DAYS_BETWEEN_SENDING_AND_FIRST_OPENING_FRAGMENT}
	${OFFER_ADDRESS_FRAGMENT}
	${OFFER_OFFER_STATUS_FRAGMENT}
	${OFFER_LICENSE_START_DATE_FRAGMENT}
	${OFFER_PROPERTIES_FOR_OFFER_FRAGMENT}
	${OFFER_MIN_AMOUNT_FRAGMENT}
	fragment OfferFragment on Offer {
		...OfferIdFragment
		...OfferOwnerIdFragment
		...OfferNameFragment
		...OfferCompanyNameFragment
		...OfferOwnerFragment
		...OfferWorkspaceIdFragment
		...OfferOfferVersionsFragment
		...OfferTokenFragment
		...OfferEmailRequiredFragment
		...OfferQuoteOfferEmailsFragment
		...OfferOpenOfferEmailsFragment
		...OfferCreatedAtFragment
		...OfferUpdatedAtFragment
		...OfferCreatedByFragment
		...OfferUpdatedByFragment
		...OfferCrmDealIdAndServiceFragment
		...OfferAddressFragment
		offerContacts {
			...OfferContactFragment
		}
		...OfferQuoteLayoutFragment
		...OfferVATNumberFragment
		...OfferSendDateFragment
		...OfferClosingDateFragment
		...OfferHeatScoreFragment
		...OfferNbViewsFragment
		...OfferNbUniqueEmailViewsFragment
		...OfferNbDaysSinceLastOpeningFragment
		...OfferNbDaysBetweenSendingAndFirstOpeningFragment
		...OfferOfferStatusFragment
		...OfferLicenseStartDateFragment
		...OfferMinAmountFragment
		offerProperties {
			...OfferPropertiesForOfferFragment
		}
	}
`;

export const PUBLIC_OFFER_ID_FRAGMENT = gql`
	fragment PublicOfferIdFragment on PublicOffer {
		id
		tokenObj {
			token
		}
	}
`;

export const PUBLIC_OFFER_WORKSPACE_ID_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	fragment PublicOfferWorkspaceIdFragment on PublicOffer {
		...PublicOfferIdFragment
		workspaceId
	}
`;

export const PUBLIC_OFFER_NAME_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	fragment PublicOfferNameFragment on PublicOffer {
		...PublicOfferIdFragment
		name
	}
`;

export const PUBLIC_OFFER_QUOTE_LAYOUT_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	fragment PublicOfferQuoteLayoutFragment on PublicOffer {
		...PublicOfferIdFragment
		quoteLayout
	}
`;

export const PUBLIC_OFFER_VAT_NUMBER_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	fragment PublicOfferVATNumberFragment on PublicOffer {
		...PublicOfferIdFragment
		VATNumber
	}
`;

export const PUBLIC_OFFER_ADDRESS_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${ADDRESS_FRAGMENT}
	fragment PublicOfferAddressFragment on PublicOffer {
		...PublicOfferIdFragment
		address {
			...AddressFragment
		}
	}
`;

export const PUBLIC_OFFER_LICENSE_START_DATE_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	fragment PublicOfferLicenseStartDateFragment on PublicOffer {
		...PublicOfferIdFragment
		licenseStartDate
	}
`;

export const PUBLIC_OFFER_LAST_OFFER_VERSIONS_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${LAST_OFFER_VERSION_FRAGMENT}
	fragment PublicOfferLastOfferVersionsFragment on PublicOffer {
		...PublicOfferIdFragment
		lastVersion {
			...LastOfferVersionFragment
		}
	}
`;

export const PUBLIC_OFFER_TOKEN_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${TOKEN_FRAGMENT}
	fragment PublicOfferTokenFragment on PublicOffer {
		...PublicOfferIdFragment
		tokenObj {
			...TokenFragment
		}
	}
`;

export const PUBLIC_OFFER_STATUS_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${OFFER_STATUS_FRAGMENT}
	fragment PublicOfferStatusFragment on PublicOffer {
		...PublicOfferIdFragment
		status {
			...OfferStatusFragment
		}
	}
`;

export const SIGNATURE_LINK_PUBLIC_OFFER_LAST_OFFER_VERSIONS_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${PUBLIC_OFFER_NAME_FRAGMENT}
	${PUBLIC_OFFER_TOKEN_FRAGMENT}
	${SIGNATURE_LINK_LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment SignatureLinkPublicOfferLastOfferVersionsFragment on PublicOffer {
		...PublicOfferIdFragment
		...PublicOfferNameFragment
		...PublicOfferTokenFragment
		lastVersion {
			...SignatureLinkLastOfferVersionPriceModelCombinationFragment
		}
	}
`;

export const PUBLIC_OFFER_ENABLE_MAX_GLOBAL_DISCOUNT_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_ENABLE_MAX_GLOBAL_DISCOUNT_FRAGMENT}
	fragment PublicOfferEnableMaxGlobalDiscountFragment on PublicOffer {
		...PublicOfferIdFragment
		lastVersion {
			...LastOfferVersionPriceModelCombinationEnableMaxGlobalDiscountFragment
		}
	}
`;

export const PUBLIC_OFFER_MAX_GLOBAL_DISCOUNT_DATA_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_MAX_GLOBAL_DISCOUNT_DATA_FRAGMENT}
	fragment PublicOfferMaxGlobalDiscountDataFragment on PublicOffer {
		...PublicOfferIdFragment
		lastVersion {
			...LastOfferVersionPriceModelCombinationMaxGlobalDiscountDataFragment
		}
	}
`;

export const PUBLIC_OFFER_OWNER_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${USER_FRAGMENT}
	fragment PublicOfferOwnerFragment on PublicOffer {
		...PublicOfferIdFragment
		owner {
			...UserFragment
		}
	}
`;

export const PUBLIC_OFFER_CONTACT_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${OFFER_CONTACT_PUBLIC_FRAGMENT}
	fragment PublicOfferContactFragment on PublicOffer {
		...PublicOfferIdFragment
		offerContacts {
			...OfferContactPublicFragment
		}
	}
`;

export const PUBLIC_OFFER_OFFER_PROPERTIES_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${OFFER_PROPERTIES_FOR_OFFER_FRAGMENT}
	fragment PublicOfferOfferPropertiesFragment on PublicOffer {
		...PublicOfferIdFragment
		offerProperties {
			...OfferPropertiesForOfferFragment
		}
	}
`;

export const PUBLIC_OFFER_LAST_OFFER_VERSION_OFFER_VERSION_QUOTE_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_QUOTES_FRAGMENT}
	${PUBLIC_OFFER_STATUS_FRAGMENT}
	fragment PublicOfferLastOfferVersionOfferVersionQuoteFragment on PublicOffer {
		...PublicOfferIdFragment
		...PublicOfferStatusFragment
		lastVersion {
			...OfferVersionOfferVersionQuotesFragment
		}
	}
`;

export const OFFER_PUBLIC_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${PUBLIC_OFFER_NAME_FRAGMENT}
	${PUBLIC_OFFER_WORKSPACE_ID_FRAGMENT}
	${PUBLIC_OFFER_VAT_NUMBER_FRAGMENT}
	${PUBLIC_OFFER_LICENSE_START_DATE_FRAGMENT}
	fragment PublicOfferFragment on PublicOffer {
		...PublicOfferIdFragment
		...PublicOfferNameFragment
		...PublicOfferWorkspaceIdFragment
		...PublicOfferVATNumberFragment
		...PublicOfferLicenseStartDateFragment
		offerPropertiesId
		ownerId
	}
`;

export const OFFER_FULL_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_NAME_FRAGMENT}
	${OFFER_COMPANY_NAME_FRAGMENT}
	${OFFER_OWNER_FRAGMENT}
	${OFFER_WORKSPACE_ID_FRAGMENT}
	${OFFER_TOKEN_FRAGMENT}
	${OFFER_CONTACT_FRAGMENT}
	${LAST_OFFER_VERSION_FRAGMENT}
	${OFFER_QUOTE_LAYOUT_FRAGMENT}
	${OFFER_VAT_NUMBER_FRAGMENT}
	${OFFER_ADDRESS_FRAGMENT}
	${OFFER_LICENSE_START_DATE_FRAGMENT}
	${OFFER_STATUS_FRAGMENT}
	${OFFER_PROPERTIES_FOR_OFFER_FRAGMENT}
	fragment OfferFullFragment on Offer {
		...OfferIdFragment
		...OfferNameFragment
		...OfferCompanyNameFragment
		...OfferOwnerFragment
		...OfferWorkspaceIdFragment
		...OfferTokenFragment
		...OfferQuoteLayoutFragment
		offerContacts {
			...OfferContactFragment
		}
		offerVersions {
			...LastOfferVersionFragment
		}
		...OfferVATNumberFragment
		...OfferAddressFragment
		...OfferLicenseStartDateFragment
		status {
			...OfferStatusFragment
		}
		offerProperties {
			...OfferPropertiesForOfferFragment
		}
	}
`;

export const OFFER_CRM_DEAL_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_CRM_DEAL_ID_FRAGMENT}
	${OFFER_NAME_FRAGMENT}
	${CRM_CONNECTION_SERVICE_INFOS_FRAGMENT}
	fragment OfferCrmDealFragment on Offer {
		...OfferIdFragment
		...OfferNameFragment
		...OfferCrmDealIdFragment
		crmDealConnection {
			...CrmConnectionServiceInfosFragment
		}
	}
`;

export const OFFER_DRAWER_INFOS_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_CRM_DEAL_ID_FRAGMENT}
	${CRM_CONNECTION_SERVICE_INFOS_FRAGMENT}
	${OFFER_ADDRESS_FRAGMENT}
	fragment OfferDrawerInfosFragment on Offer {
		...OfferIdFragment
		name
		...OfferCrmDealIdFragment
		...OfferAddressFragment
		crmDealConnection {
			...CrmConnectionServiceInfosFragment
		}
	}
`;

export const OFFER_CONFIRMATION_FRAGMENT = gql`
	${OFFER_WORKSPACE_ID_FRAGMENT}
	fragment OfferConfirmationFragment on Offer {
		...OfferWorkspaceIdFragment
		name
	}
`;

export const OFFER_SIGNATURE_CONNECTION_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${SIGNATURE_CONNECTION_SERVICE_INFOS_FRAGMENT}
	fragment OfferSignatureConnectionFragment on Offer {
		...OfferIdFragment
		signatureConnection {
			...SignatureConnectionServiceInfosFragment
		}
	}
`;

export const OFFER_PAYMENT_CONNECTION_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${STRIPE_CONNECTION_SERVICE_INFOS_FRAGMENT}
	fragment OfferPaymentConnectionFragment on Offer {
		...OfferIdFragment
		stripeConnection {
			...StripeConnectionServiceInfosFragment
		}
	}
`;

export const OFFER_WORKSPACE_NAME_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${WORKSPACE_NAME_FRAGMENT}
	fragment OfferWorkspaceNameFragment on Offer {
		...OfferIdFragment
		workspace {
			...WorkspaceNameFragment
		}
	}
`;

export const OFFER_OFFER_VERSION_RULES_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_RULES_FRAGMENT}
	${LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_MAX_GLOBAL_DISCOUNT_DATA_FRAGMENT}
	fragment OfferOfferVersionRulesFragment on Offer {
		...OfferIdFragment
		offerVersions {
			...OfferVersionOfferVersionRulesFragment
			...LastOfferVersionPriceModelCombinationMaxGlobalDiscountDataFragment
		}
	}
`;

export const OFFER_OFFER_VERSION_PRICE_MODEL_COMBINATION_QUOTE_FIELDS_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_VERSION_PRICE_MODEL_COMBINATION_ID_FRAGMENT}
	fragment OfferOfferVersionPriceModelCombinationQuoteFieldsFragment on Offer {
		...OfferIdFragment
		offerVersions {
			...OfferVersionPriceModelCombinationIdFragment
		}
	}
`;
