import { gql, useMutation } from "@apollo/client";

const CREATE_EVENT_ANALYTICS = gql`
    mutation CreateEventAnalytics($createEventAnalytics: CreateEventAnalytics!) {
        createEventAnalytics(eventAnalytics: $createEventAnalytics){
            createdAt
        }
    }
`;

export const createEventAnalyticsMutation = () => {
    const [createEventAnalytics] = useMutation(CREATE_EVENT_ANALYTICS, {fetchPolicy: "no-cache", ignoreResults: true});
    return createEventAnalytics;
}