import { gql } from "@apollo/client";
import { ERROR_FRAGMENT } from "./error";

export const OFFER_CONTACT_ID_FRAGMENT = gql`
	fragment OfferContactIdFragment on OfferContact {
		id
	}
`;

export const OFFER_CONTACT_OFFER_ID_FRAGMENT = gql`
	fragment OfferContactOfferIdFragment on OfferContact {
		id
		offerId
	}
`;

export const OFFER_CONTACT_EMAIL_FRAGMENT = gql`
	${OFFER_CONTACT_ID_FRAGMENT}
	fragment OfferContactEmailFragment on OfferContact {
		...OfferContactIdFragment
		email
	}
`;

export const OFFER_CONTACT_FIRST_NAME_FRAGMENT = gql`
	${OFFER_CONTACT_ID_FRAGMENT}
	fragment OfferContactFirstNameFragment on OfferContact {
		...OfferContactIdFragment
		firstName
	}
`;

export const OFFER_CONTACT_LAST_NAME_FRAGMENT = gql`
	${OFFER_CONTACT_ID_FRAGMENT}
	fragment OfferContactLastNameFragment on OfferContact {
		...OfferContactIdFragment
		lastName
	}
`;

export const OFFER_CONTACT_FRAGMENT = gql`
	${OFFER_CONTACT_ID_FRAGMENT}
	${OFFER_CONTACT_EMAIL_FRAGMENT}
	${OFFER_CONTACT_OFFER_ID_FRAGMENT}
	${ERROR_FRAGMENT}
	${OFFER_CONTACT_FIRST_NAME_FRAGMENT}
	${OFFER_CONTACT_LAST_NAME_FRAGMENT}
	fragment OfferContactFragment on OfferContact {
		...OfferContactIdFragment
		...OfferContactEmailFragment
		...OfferContactOfferIdFragment
		errors {
			...ErrorFragment
		}
		...OfferContactFirstNameFragment
		...OfferContactLastNameFragment
	}
`;

export const OFFER_CONTACT_PUBLIC_FRAGMENT = gql`
	${OFFER_CONTACT_ID_FRAGMENT}
	${OFFER_CONTACT_EMAIL_FRAGMENT}
	${OFFER_CONTACT_OFFER_ID_FRAGMENT}
	${OFFER_CONTACT_FIRST_NAME_FRAGMENT}
	${OFFER_CONTACT_LAST_NAME_FRAGMENT}
	fragment OfferContactPublicFragment on OfferContact {
		...OfferContactIdFragment
		...OfferContactEmailFragment
		...OfferContactOfferIdFragment
		...OfferContactFirstNameFragment
		...OfferContactLastNameFragment
	}
`;
