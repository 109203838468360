import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";

const myPlugin = {
	name: "debugPlugin",
	page: ({ payload }) => {
		console.log("page view fired", payload);
	},
	track: ({ payload }) => {
		console.log("track event payload", payload);
	},
	identify: ({ payload }) => {
		console.log("identify", payload);
	},
};

const analytics = Analytics({
	app: "priceit",
	// debug: process.env.NODE_ENV !== "production",
	plugins: [
		myPlugin,
		segmentPlugin({
			writeKey: process.env.SEGMENT_KEY_CLIENT,
			enabled: true, // pass to true because error on parent node with light app
		}),
	],
});
if (process.env.NODE_ENV === "production") {
	analytics.plugins.disable(["debugPlugin"]);
}

export default analytics;
