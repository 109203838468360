import { gql } from "@apollo/client";

export const SPECIAL_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT = gql`
	fragment SpecialRangeDiscountEnableDiscountFragment on SpecialRangeDiscount {
		id
		packId
		specialRangeId
		enableDiscount
	}
`;

export const SPECIAL_RANGE_DISCOUNT_DIFF_PREVIOUS_RANGE_FRAGMENT = gql`
	fragment SpecialRangeDiscountDiffPreviousRangeFragment on SpecialRangeDiscount {
		id
		packId
		specialRangeId
		diffPreviousRange
	}
`;

export const SPECIAL_RANGE_DISCOUNT_WARNING_FRAGMENT = gql`
	fragment SpecialRangeDiscountWarningFragment on SpecialRangeDiscount {
		id
		packId
		specialRangeId
		warning
	}
`;

export const SPECIAL_RANGE_DISCOUNT_PRICE_FRAGMENT = gql`
	fragment SpecialRangeDiscountPriceFragment on SpecialRangeDiscount {
		id
		packId
		specialRangeId
		price
	}
`;

export const SPECIAL_RANGE_DISCOUNT_DISCOUNT_FRAGMENT = gql`
	fragment SpecialRangeDiscountDiscountFragment on SpecialRangeDiscount {
		id
		packId
		specialRangeId
		discount
	}
`;

export const SPECIAL_RANGE_DISCOUNT_OPPOSITE_FRAGMENT = gql`
	fragment SpecialRangeDiscountOppositeFragment on SpecialRangeDiscount {
		id
		packId
		specialRangeId
		oppositeDiscount
	}
`;

export const SPECIAL_RANGE_DISCOUNT_COST_FRAGMENT = gql`
	fragment SpecialRangeDiscountCostFragment on SpecialRangeDiscount {
		id
		packId
		specialRangeId
		cost
	}
`;

export const SPECIAL_RANGE_DISCOUNT_DISCOUNT_OPPOSITE_DISCOUNT_ENABLE_DISCOUNT_WARNING_FRAGMENT = gql`
	fragment SpecialRangeDiscountDiscountOppositeDiscountEnableDiscountWarningFragment on SpecialRangeDiscount {
		id
		discount
		oppositeDiscount
		enableDiscount
		warning
	}
`;

export const SPECIAL_RANGE_DISCOUNT_ENABLE_DISCOUNT_PRICE_WARNING_FRAGMENT = gql`
	fragment SpecialRangeDiscountEnableDiscountPriceWarningFragment on SpecialRangeDiscount {
		id
		enableDiscount
		price
		warning
		packId
		specialRangeId
	}
`;

export const SPECIAL_RANGE_DISCOUNT_FRAGMENT = gql`
	${SPECIAL_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT}
	${SPECIAL_RANGE_DISCOUNT_PRICE_FRAGMENT}
	${SPECIAL_RANGE_DISCOUNT_DISCOUNT_FRAGMENT}
	${SPECIAL_RANGE_DISCOUNT_WARNING_FRAGMENT}
	${SPECIAL_RANGE_DISCOUNT_OPPOSITE_FRAGMENT}
	${SPECIAL_RANGE_DISCOUNT_DIFF_PREVIOUS_RANGE_FRAGMENT}
	${SPECIAL_RANGE_DISCOUNT_COST_FRAGMENT}
	fragment SpecialRangeDiscountFragment on SpecialRangeDiscount {
		uuid
		...SpecialRangeDiscountEnableDiscountFragment
		...SpecialRangeDiscountPriceFragment
		...SpecialRangeDiscountWarningFragment
		...SpecialRangeDiscountDiscountFragment
		...SpecialRangeDiscountOppositeFragment
		...SpecialRangeDiscountDiffPreviousRangeFragment
		...SpecialRangeDiscountCostFragment
		isAddonExtraLocked
	}
`;
