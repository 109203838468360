import { gql } from "@apollo/client";

export const NAME_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT = gql`
	fragment NamePriceModelCombinationQuoteFieldFragment on PriceModelCombinationQuoteField {
		id
		name
	}
`;

export const PRICE_MODEL_COMBINATION_ID_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT = gql`
	fragment PriceModelCombinationIdPriceModelCombinationQuoteFieldFragment on PriceModelCombinationQuoteField {
		id
		priceModelCombinationId
	}
`;

export const DISPLAY_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT = gql`
	fragment DisplayPriceModelCombinationQuoteFieldFragment on PriceModelCombinationQuoteField {
		id
		display
	}
`;

export const LABEL_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT = gql`
	fragment LabelPriceModelCombinationQuoteFieldFragment on PriceModelCombinationQuoteField {
		id
		label
	}
`;

export const MANDATORY_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT = gql`
	fragment MandatoryPriceModelCombinationQuoteFieldFragment on PriceModelCombinationQuoteField {
		id
		mandatory
	}
`;

export const EDITABLE_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT = gql`
	fragment EditablePriceModelCombinationQuoteFieldFragment on PriceModelCombinationQuoteField {
		id
		editable
	}
`;

export const UNIQUE_KEY_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT = gql`
	fragment UniqueKeyPriceModelCombinationQuoteFieldFragment on PriceModelCombinationQuoteField {
		id
		uniqueKey
	}
`;
export const IS_ADDED_QUOTE_FIELDS_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT = gql`
	fragment IsAddedQuoteFieldsPriceModelCombinationQuoteFieldFragment on PriceModelCombinationQuoteField {
		id
		isAddedQuoteFields
	}
`;

export const INDEX_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT = gql`
	fragment IndexPriceModelCombinationQuoteFieldFragment on PriceModelCombinationQuoteField {
		id
		index
	}
`;

export const PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT = gql`
	${NAME_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	${DISPLAY_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	${LABEL_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	${MANDATORY_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	${PRICE_MODEL_COMBINATION_ID_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	${EDITABLE_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	${UNIQUE_KEY_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	${IS_ADDED_QUOTE_FIELDS_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	${INDEX_PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	fragment PriceModelCombinationQuoteFieldFragment on PriceModelCombinationQuoteField {
		...NamePriceModelCombinationQuoteFieldFragment
		...DisplayPriceModelCombinationQuoteFieldFragment
		...LabelPriceModelCombinationQuoteFieldFragment
		...MandatoryPriceModelCombinationQuoteFieldFragment
		...PriceModelCombinationIdPriceModelCombinationQuoteFieldFragment
		...EditablePriceModelCombinationQuoteFieldFragment
		...IsAddedQuoteFieldsPriceModelCombinationQuoteFieldFragment
		...UniqueKeyPriceModelCombinationQuoteFieldFragment
		...IndexPriceModelCombinationQuoteFieldFragment
	}
`;
