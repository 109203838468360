import { gql } from "@apollo/client";

export const SWITCH_OPTION_PRICE_ERROR_BOOSTER_FRAGMENT = gql`
	fragment SwitchOptionPriceErrorBoosterFragment on SwitchOptionPriceError {
		id
		isBoosterAllowed
	}
`;

export const SWITCH_OPTION_PRICE_ERROR_LOWER_THAN_PREVIOUS_FRAGMENT = gql`
	fragment SwitchOptionPriceErrorLowerThanPreviousFragment on SwitchOptionPriceError {
		id
		isLowerThanPrevious
	}
`;

export const SWITCH_OPTION_PRICE_ERROR_FRAGMENT = gql`
	${SWITCH_OPTION_PRICE_ERROR_BOOSTER_FRAGMENT}
	${SWITCH_OPTION_PRICE_ERROR_LOWER_THAN_PREVIOUS_FRAGMENT}
	fragment SwitchOptionPriceErrorFragment on SwitchOptionError {
		id
		price {
			...SwitchOptionPriceErrorBoosterFragment
			...SwitchOptionPriceErrorLowerThanPreviousFragment
		}
	}
`;

export const SWITCH_OPTION_SCORE_WARNING_FRAGMENT = gql`
	fragment SwitchOptionScoreWarningFragment on SwitchOption {
		id
		warnings {
			id
			score
		}
	}
`;

export const SWITCH_OPTION_VALUE_FRAGMENT = gql`
	fragment SwitchOptionValueFragment on SwitchOption {
		id
		value
	}
`;

export const SWITCH_OPTION_INDEX_FRAGMENT = gql`
	fragment SwitchOptionIndexFragment on SwitchOption {
		id
		index
	}
`;

// export const SWITCH_OPTION_IS_BOOSTER_ALLOWED_FRAGMENT = gql`
//     fragment SwitchOptionIsBoosterAllowedFragment on SwitchOption {
//         id
//         isBoosterAllowed
//     }
// `;
//
// export const SWITCH_OPTION_SCORE_FRAGMENT = gql`
// 	fragment SwitchOptionScoreFragment on SwitchOption {
// 		id
// 		score
// 	}
// `;
//
// export const SWITCH_OPTION_PRICE_FRAGMENT = gql`
// 	fragment SwitchOptionPriceFragment on SwitchOption {
// 		id
// 		price
// 	}
// `;
//
// export const SWITCH_OPTION_COST_FRAGMENT = gql`
// 	fragment SwitchOptionCostFragment on SwitchOption {
// 		id
// 		cost
// 	}
// `;
//
// export const SWITCH_OPTION_PROFIT_FRAGMENT = gql`
//     fragment SwitchOptionProfitFragment on SwitchOption {
//         id
//         profit
//     }
// `;

export const SWITCH_OPTION_SWITCH_ID_FRAGMENT = gql`
	fragment SwitchOptionSwitchIdFragment on SwitchOption {
		id
		switchId
	}
`;

export const SWITCH_OPTION_DESCRIPTION_FRAGMENT = gql`
	fragment SwitchOptionDescriptionFragment on SwitchOption {
		id
		description
	}
`;

export const SWITCH_OPTION_INDEX_AND_SWITCH_ID_FRAGMENT = gql`
	${SWITCH_OPTION_SWITCH_ID_FRAGMENT}
	${SWITCH_OPTION_INDEX_FRAGMENT}
	fragment SwitchOptionIndexAndSwitchIdFragment on SwitchOption {
		id
		...SwitchOptionSwitchIdFragment
		...SwitchOptionIndexFragment
	}
`;

export const SWITCH_OPTION_WITHOUT_INDEX_FRAGMENT = gql`
	${SWITCH_OPTION_VALUE_FRAGMENT}
	${SWITCH_OPTION_PRICE_ERROR_FRAGMENT}
	${SWITCH_OPTION_SCORE_WARNING_FRAGMENT}
	${SWITCH_OPTION_DESCRIPTION_FRAGMENT}
	fragment SwitchOptionWithoutIndexFragment on SwitchOption {
		id
		...SwitchOptionValueFragment
		...SwitchOptionScoreWarningFragment
		...SwitchOptionDescriptionFragment
		errors {
			...SwitchOptionPriceErrorFragment
		}
	}
`;

export const SWITCH_OPTION_FRAGMENT = gql`
	${SWITCH_OPTION_VALUE_FRAGMENT}
	${SWITCH_OPTION_INDEX_FRAGMENT}
	${SWITCH_OPTION_DESCRIPTION_FRAGMENT}
	${SWITCH_OPTION_SWITCH_ID_FRAGMENT}
	fragment SwitchOptionFragment on SwitchOption {
		id
		...SwitchOptionSwitchIdFragment
		...SwitchOptionValueFragment
		...SwitchOptionIndexFragment
		...SwitchOptionDescriptionFragment
	}
`;

export const PUBLIC_SWITCH_OPTION_FRAGMENT = gql`
	${SWITCH_OPTION_VALUE_FRAGMENT}
	${SWITCH_OPTION_INDEX_FRAGMENT}
	${SWITCH_OPTION_DESCRIPTION_FRAGMENT}
	${SWITCH_OPTION_SWITCH_ID_FRAGMENT}
	fragment PublicSwitchOptionFragment on SwitchOption {
		id
		...SwitchOptionSwitchIdFragment
		...SwitchOptionValueFragment
		...SwitchOptionIndexFragment
		...SwitchOptionDescriptionFragment
	}
`;
