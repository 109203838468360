import { gql } from "@apollo/client";

export const QUOTE_CONFIGURATION_SHOW_FIRST_PAYMENT_BOX_FRAGMENT = gql`
	fragment QuoteConfigurationShowFirstPaymentBoxFragment on QuoteConfiguration {
		id
		showFirstPaymentBox
	}
`;

export const QUOTE_CONFIGURATION_SHOW_ATTRIBUTES_DESCRIPTION_FRAGMENT = gql`
	fragment QuoteConfigurationShowAttributesDescriptionFragment on QuoteConfiguration {
		id
		showAttributesDescription
	}
`;

export const QUOTE_CONFIGURATION_SHOW_PAYMENT_SCHEDULE_FRAGMENT = gql`
	fragment QuoteConfigurationShowPaymentScheduleFragment on QuoteConfiguration {
		id
		showPaymentSchedule
	}
`;

export const QUOTE_CONFIGURATION_SHOW_INTRODUCTION_TEXT_FRAGMENT = gql`
	fragment QuoteConfigurationShowIntroductionTextFragment on QuoteConfiguration {
		id
		showIntroductionText
	}
`;

export const QUOTE_CONFIGURATION_SHOW_INCLUDED_MENTION_FRAGMENT = gql`
	fragment QuoteConfigurationShowIncludedMentionFragment on QuoteConfiguration {
		id
		showIncludedMention
	}
`;

export const QUOTE_CONFIGURATION_SHOW_VAT_DETAIL_FRAGMENT = gql`
	fragment QuoteConfigurationShowVatDetailFragment on QuoteConfiguration {
		id
		showVatDetail
	}
`;

export const QUOTE_CONFIGURATION_INTRODUCTION_TEXT_FRAGMENT = gql`
	fragment QuoteConfigurationIntroductionTextFragment on QuoteConfiguration {
		id
		introductionText
	}
`;

export const QUOTE_CONFIGURATION_USE_INTRO_TEXT_FROM_INTRO_PAGE_FRAGMENT = gql`
	fragment QuoteConfigurationUseIntroTextFromIntroPageFragment on QuoteConfiguration {
		id
		useIntroTextFromIntroPage
	}
`;

export const QUOTE_CONFIGURATION_SHOW_TOTAL_BOXES_FRAGMENT = gql`
	fragment QuoteConfigurationShowTotalBoxesFragment on QuoteConfiguration {
		id
		showTotalBoxes
	}
`;

export const QUOTE_CONFIGURATION_LAYOUT_FRAGMENT = gql`
	fragment QuoteConfigurationLayoutFragment on QuoteConfiguration {
		id
		layout
	}
`;

export const QUOTE_CONFIGURATION_HEADER_BACKGROUND_COLOR_FRAGMENT = gql`
	fragment QuoteConfigurationHeaderBackgroundColorFragment on QuoteConfiguration {
		id
		headerBackgroundColor
	}
`;

export const QUOTE_CONFIGURATION_HEADER_FONT_COLOR_FRAGMENT = gql`
	fragment QuoteConfigurationHeaderFontColorFragment on QuoteConfiguration {
		id
		headerFontColor
	}
`;

export const QUOTE_CONFIGURATION_HEADER_LOGO_FRAGMENT = gql`
	fragment QuoteConfigurationHeaderLogoFragment on QuoteConfiguration {
		id
		headerLogo
	}
`;

export const QUOTE_CONFIGURATION_SHOW_BRAND_GENERATED_BY_FRAGMENT = gql`
	fragment QuoteConfigurationShowBrandGeneratedBy on QuoteConfiguration {
		id
		showBrandGeneratedBy
	}
`;

export const QUOTE_CONFIGURATION_QUOTE_LAYOUT_FRAGMENT = gql`
	${QUOTE_CONFIGURATION_SHOW_FIRST_PAYMENT_BOX_FRAGMENT}
	${QUOTE_CONFIGURATION_SHOW_PAYMENT_SCHEDULE_FRAGMENT}
	${QUOTE_CONFIGURATION_SHOW_ATTRIBUTES_DESCRIPTION_FRAGMENT}
	${QUOTE_CONFIGURATION_LAYOUT_FRAGMENT}
	${QUOTE_CONFIGURATION_SHOW_INTRODUCTION_TEXT_FRAGMENT}
	${QUOTE_CONFIGURATION_USE_INTRO_TEXT_FROM_INTRO_PAGE_FRAGMENT}
	${QUOTE_CONFIGURATION_INTRODUCTION_TEXT_FRAGMENT}
	${QUOTE_CONFIGURATION_SHOW_VAT_DETAIL_FRAGMENT}
	${QUOTE_CONFIGURATION_SHOW_INCLUDED_MENTION_FRAGMENT}
	${QUOTE_CONFIGURATION_SHOW_TOTAL_BOXES_FRAGMENT}
	fragment QuoteConfigurationQuoteLayoutFragment on QuoteConfiguration {
		id
		priceModelCombinationId
		offerId
		...QuoteConfigurationShowFirstPaymentBoxFragment
		...QuoteConfigurationShowPaymentScheduleFragment
		...QuoteConfigurationLayoutFragment
		...QuoteConfigurationShowAttributesDescriptionFragment
		...QuoteConfigurationShowIntroductionTextFragment
		...QuoteConfigurationUseIntroTextFromIntroPageFragment
		...QuoteConfigurationIntroductionTextFragment
		...QuoteConfigurationShowVatDetailFragment
		...QuoteConfigurationShowIncludedMentionFragment
		...QuoteConfigurationShowTotalBoxesFragment
	}
`;

export const QUOTE_CONFIGURATION_HEADER_FRAGMENT = gql`
	${QUOTE_CONFIGURATION_HEADER_BACKGROUND_COLOR_FRAGMENT}
	${QUOTE_CONFIGURATION_HEADER_FONT_COLOR_FRAGMENT}
	${QUOTE_CONFIGURATION_HEADER_LOGO_FRAGMENT}
	${QUOTE_CONFIGURATION_SHOW_BRAND_GENERATED_BY_FRAGMENT}
	fragment QuoteConfigurationHeaderFragment on QuoteConfiguration {
		id
		priceModelCombinationId
		offerId
		...QuoteConfigurationHeaderBackgroundColorFragment
		...QuoteConfigurationHeaderFontColorFragment
		...QuoteConfigurationHeaderLogoFragment
		...QuoteConfigurationShowBrandGeneratedBy
	}
`;

export const QUOTE_CONFIGURATION_FULL_FRAGMENT = gql`
	${QUOTE_CONFIGURATION_HEADER_FRAGMENT}
	${QUOTE_CONFIGURATION_QUOTE_LAYOUT_FRAGMENT}
	fragment QuoteConfigurationFullFragment on QuoteConfiguration {
		...QuoteConfigurationHeaderFragment
		...QuoteConfigurationQuoteLayoutFragment
	}
`;
