import { gql } from "@apollo/client";
import { VARIABLE_TIME_RANGE_FRAGMENT } from "./variableTimeRange";
import {
	PAYMENT_TERM_RANGE_FRAGMENT,
	TERM_PAYMENT_TERM_RANGE_FRAGMENT,
} from "./paymentTermRangeFragment";

export const VARIABLE_TIME_DISCOUNT_PRICE_MODEL_ID_FRAGMENT = gql`
	fragment VariableTimeDiscountPriceModelIdFragment on VariableTimeDiscount {
		id
		priceModelId
	}
`;

export const VARIABLE_TIME_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_FRAGMENT}
	fragment VariableTimeDiscountPaymentTermRangeIdFragment on VariableTimeDiscount {
		id
		paymentTermRange {
			...PaymentTermRangeFragment
		}
	}
`;

export const VARIABLE_TIME_DISCOUNT_PAYMENT_TERM_RANGE_TERM_FRAGMENT = gql`
	${TERM_PAYMENT_TERM_RANGE_FRAGMENT}
	fragment VariableTimeDiscountPaymentTermRangeTermFragment on VariableTimeDiscount {
		id
		paymentTermRange {
			...TermPaymentTermRangeFragment
		}
	}
`;

export const VARIABLE_TIME_DISCOUNT_FRAGMENT = gql`
	${VARIABLE_TIME_RANGE_FRAGMENT}
	${VARIABLE_TIME_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	${VARIABLE_TIME_DISCOUNT_PRICE_MODEL_ID_FRAGMENT}
	fragment VariableTimeDiscountFragment on VariableTimeDiscount {
		variableTimeRange {
			...VariableTimeRangeFragment
		}
		...VariableTimeDiscountPaymentTermRangeIdFragment
		...VariableTimeDiscountPriceModelIdFragment
	}
`;
