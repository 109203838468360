import { gql } from "@apollo/client";
import { REPAYMENT_SCHEDULE_FRAGMENT } from "@graphQl/fragments/repaymentSchedule";
import { OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_FRAGMENT } from "@graphQl/fragments/offerVersionRuleForRepaymentSchedule";

export const SUMMARY_PRICE_PRICE_FRAGMENT = gql`
	fragment SummaryPricePriceFragment on SummaryPrice {
		price
	}
`;

export const SUMMARY_PRICE_LIST_PRICE_FRAGMENT = gql`
	fragment SummaryPriceListPriceFragment on SummaryPrice {
		listPrice
	}
`;

export const SUMMARY_PRICE_DISCOUNT_IN_PERCENTAGE_FRAGMENT = gql`
	fragment SummaryPriceDiscountInPercentageFragment on SummaryPrice {
		discountInPercentage
	}
`;

export const SUMMARY_PRICE_TOTAL_EXTRA_FRAGMENT = gql`
	fragment SummaryPriceTotalExtraFragment on SummaryPrice {
		totalExtra
	}
`;

export const SUMMARY_PRICE_TIME_METRIC_FRAGMENT = gql`
	fragment SummaryPriceTimeMetricFragment on SummaryPrice {
		timeMetric
	}
`;

export const SUMMARY_PRICE_DURATION_FRAGMENT = gql`
	fragment SummaryPriceDurationFragment on SummaryPrice {
		duration
	}
`;

export const SUMMARY_PRICE_REPAYMENT_SCHEDULE_FRAGMENT = gql`
	${REPAYMENT_SCHEDULE_FRAGMENT}
	fragment SummaryPriceRepaymentScheduleFragment on SummaryPrice {
		repaymentSchedule {
			...RepaymentScheduleFragment
		}
	}
`;

export const SUMMARY_PRICE_EXTRA_APPLIED_RULES_FRAGMENT = gql`
	${OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_FRAGMENT}
	fragment SummaryPriceExtraAppliedRulesFragment on SummaryPrice {
		extraAppliedRules {
			...OfferVersionRuleForRepaymentScheduleFragment
		}
	}
`;

export const SUMMARY_PRICE_RECURRING_NET_PRICE_HTVA_AFTER_ALL_RECURRING_DISCOUNTS_FRAGMENT = gql`
	fragment SummaryPriceRecurringNetPriceHTVAAfterAllRecurringDiscountsFragment on SummaryPrice {
		recurringNetPriceHTVAAfterAllRecurringDiscounts
	}
`;

export const SUMMARY_PRICE_RECURRING_LIST_PRICE_HTVA_FRAGMENT = gql`
	fragment SummaryPriceRecurringListPriceHTVAFragment on SummaryPrice {
		recurringListPriceHTVA
	}
`;

export const SUMMARY_PRICE_LIST_PRICE_OF_EXTRA_HTVA_FRAGMENT = gql`
	fragment SummaryPriceListPriceOfExtraHTVAFragment on SummaryPrice {
		listPriceOfExtraHTVA
	}
`;

export const SUMMARY_PRICE_NET_PRICE_OF_EXTRA_HTVA_FRAGMENT = gql`
	fragment SummaryPriceNetPriceOfExtraHTVAFragment on SummaryPrice {
		netPriceOfExtraHTVA
	}
`;

export const SUMMARY_PRICE_LIST_PRICE_HTVA_BEFORE_ALL_DISCOUNTS_WITH_EXTRA_FRAGMENT = gql`
	fragment SummaryPriceListPriceHTVABeforeAllDiscountsWithExtraFragment on SummaryPrice {
		listPriceHTVABeforeAllDiscountsWithExtra
	}
`;

export const SUMMARY_PRICE_NET_PRICE_HTVA_AFTER_ALL_DISCOUNTS_WITH_EXTRA_FRAGMENT = gql`
	fragment SummaryPriceNetPriceHTVAAfterAllDiscountsWithExtraFragment on SummaryPrice {
		netPriceHTVAAfterAllDiscountsWithExtra
	}
`;

export const SUMMARY_PRICE_DISCOUNT_IN_CURRENCY_BEFORE_OFFER_VERSION_RULES_FRAGMENT = gql`
	fragment SummaryPriceDiscountInCurrencyBeforeOfferVersionRulesFragment on SummaryPrice {
		discountInCurrencyBeforeOfferVersionRules
	}
`;

export const SUMMARY_PRICE_TVA_FRAGMENT = gql`
	fragment SummaryPriceTvaFragment on SummaryPrice {
		tva
	}
`;

export const SUMMARY_PRICE_FRAGMENT = gql`
	${SUMMARY_PRICE_PRICE_FRAGMENT}
	${SUMMARY_PRICE_LIST_PRICE_FRAGMENT}
	${SUMMARY_PRICE_DISCOUNT_IN_PERCENTAGE_FRAGMENT}
	${SUMMARY_PRICE_TOTAL_EXTRA_FRAGMENT}
	${SUMMARY_PRICE_TIME_METRIC_FRAGMENT}
	${SUMMARY_PRICE_REPAYMENT_SCHEDULE_FRAGMENT}
	${SUMMARY_PRICE_EXTRA_APPLIED_RULES_FRAGMENT}
	${SUMMARY_PRICE_RECURRING_NET_PRICE_HTVA_AFTER_ALL_RECURRING_DISCOUNTS_FRAGMENT}
	${SUMMARY_PRICE_RECURRING_LIST_PRICE_HTVA_FRAGMENT}
	${SUMMARY_PRICE_LIST_PRICE_OF_EXTRA_HTVA_FRAGMENT}
	${SUMMARY_PRICE_NET_PRICE_OF_EXTRA_HTVA_FRAGMENT}
	${SUMMARY_PRICE_LIST_PRICE_HTVA_BEFORE_ALL_DISCOUNTS_WITH_EXTRA_FRAGMENT}
	${SUMMARY_PRICE_NET_PRICE_HTVA_AFTER_ALL_DISCOUNTS_WITH_EXTRA_FRAGMENT}
	${SUMMARY_PRICE_DISCOUNT_IN_CURRENCY_BEFORE_OFFER_VERSION_RULES_FRAGMENT}
	${SUMMARY_PRICE_TVA_FRAGMENT}
	${SUMMARY_PRICE_DURATION_FRAGMENT}
	fragment SummaryPriceFragment on SummaryPrice {
		...SummaryPricePriceFragment
		...SummaryPriceListPriceFragment
		...SummaryPriceDiscountInPercentageFragment
		...SummaryPriceTotalExtraFragment
		...SummaryPriceTimeMetricFragment
		...SummaryPriceRepaymentScheduleFragment
		...SummaryPriceExtraAppliedRulesFragment
		...SummaryPriceRecurringNetPriceHTVAAfterAllRecurringDiscountsFragment
		...SummaryPriceRecurringListPriceHTVAFragment
		...SummaryPriceListPriceOfExtraHTVAFragment
		...SummaryPriceNetPriceOfExtraHTVAFragment
		...SummaryPriceListPriceHTVABeforeAllDiscountsWithExtraFragment
		...SummaryPriceNetPriceHTVAAfterAllDiscountsWithExtraFragment
		...SummaryPriceDiscountInCurrencyBeforeOfferVersionRulesFragment
		...SummaryPriceTvaFragment
		...SummaryPriceDurationFragment
		netPriceHTVAAfterAllDiscounts
		net
	}
`;
