import { gql } from "@apollo/client";

export const ERROR_FRAGMENT = gql`
	fragment ErrorFragment on Error {
		parentId
		parentType
		field
		type
	}
`;
