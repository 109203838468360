import { gql } from "@apollo/client";
import {
	DISCOUNT_VOLUME_DISCOUNT_RANGE_FRAGMENT,
	VOLUME_DISCOUNT_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT,
	VOLUME_DISCOUNT_RANGE_ENABLE_DISCOUNT_FRAGMENT,
	VOLUME_DISCOUNT_RANGE_FOR_PUBLIC_FRAGMENT,
	VOLUME_DISCOUNT_RANGE_FRAGMENT,
	VOLUME_DISCOUNT_RANGE_KEY_FIELDS_FRAGMENT,
} from "@graphQl/fragments/volumeRangeDiscountFragment";

export const VOLUME_DISCOUNT_ID_VOLUME_RANGE_FRAGMENT = gql`
	fragment VolumeDiscountIdVolumeRangeFragment on VolumeRange {
		id
		volumeDiscountId
	}
`;

export const INDEX_VOLUME_RANGE_FRAGMENT = gql`
	fragment IndexVolumeRangeFragment on VolumeRange {
		id
		index
	}
`;

export const FROM_VOLUME_RANGE_FRAGMENT = gql`
	fragment FromVolumeRangeFragment on VolumeRange {
		id
		from
	}
`;

export const TO_VOLUME_RANGE_FRAGMENT = gql`
	fragment ToVolumeRangeFragment on VolumeRange {
		id
		to
	}
`;

export const ERROR_VOLUME_RANGE_FRAGMENT = gql`
	fragment ErrorVolumeRangeFragment on VolumeRange {
		id
		error
	}
`;

export const IS_DEFAULT_SELECTED_VOLUME_RANGE_FRAGMENT = gql`
	fragment IsDefaultSelectedVolumeRangeFragment on VolumeRange {
		id
		isDefaultSelected
	}
`;

export const DESCRIPTION_VOLUME_RANGE_FRAGMENT = gql`
	fragment DescriptionVolumeRangeFragment on VolumeRange {
		id
		description
	}
`;

export const VOLUME_RANGE_DEPENDENCIES_FRAGMENT = gql`
	fragment VolumeRangeDependenciesFragment on VolumeRange {
		id
		dependencies {
			requiredId
			desiredId
			parentDependencyId
			requiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
			desiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
		}
	}
`;

export const TO_AND_ERROR_VOLUME_RANGE_FRAGMENT = gql`
	${TO_VOLUME_RANGE_FRAGMENT}
	${ERROR_VOLUME_RANGE_FRAGMENT}
	fragment ToAndErrorVolumeRangeFragment on VolumeRange {
		...ToVolumeRangeFragment
		...ErrorVolumeRangeFragment
	}
`;

export const FROM_TO_ERROR_VOLUME_RANGE_FRAGMENT = gql`
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	${ERROR_VOLUME_RANGE_FRAGMENT}
	fragment FromToErrorVolumeRangeFragment on VolumeRange {
		...FromVolumeRangeFragment
		...ToVolumeRangeFragment
		...ErrorVolumeRangeFragment
	}
`;

export const INDEX_FROM_TO_ERROR_VOLUME_RANGE_FRAGMENT = gql`
	${INDEX_VOLUME_RANGE_FRAGMENT}
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	${ERROR_VOLUME_RANGE_FRAGMENT}
	fragment IndexFromToErrorVolumeRangeFragment on VolumeRange {
		...IndexVolumeRangeFragment
		...FromVolumeRangeFragment
		...ToVolumeRangeFragment
		...ErrorVolumeRangeFragment
	}
`;

export const FROM_TO_VOLUME_RANGE_WITH_VOLUME_DISCOUNT_FRAGMENT = gql`
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	fragment FromToVolumeRangeFragment on VolumeRange {
		...ToVolumeRangeFragment
		...FromVolumeRangeFragment
		volumeDiscount {
			id
			basedOn
			unitMetric
			pluralUnitMetric
		}
	}
`;

export const FROM_TO_VOLUME_RANGE_FRAGMENT = gql`
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	fragment FromToVolumeRangeFragment on VolumeRange {
		...ToVolumeRangeFragment
		...FromVolumeRangeFragment
	}
`;

export const INDEX_FROM_TO_VOLUME_RANGE_FRAGMENT = gql`
	${INDEX_VOLUME_RANGE_FRAGMENT}
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	fragment IndexFromToVolumeRangeFragment on VolumeRange {
		...IndexVolumeRangeFragment
		...FromVolumeRangeFragment
		...ToVolumeRangeFragment
	}
`;

export const FROM_TO_VOLUME_RANGE_AND_VOLUME_RANGE_DISCOUNTS_FRAGMENT = gql`
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	${VOLUME_DISCOUNT_RANGE_KEY_FIELDS_FRAGMENT}
	${DISCOUNT_VOLUME_DISCOUNT_RANGE_FRAGMENT}
	fragment FromToVolumeRangeAndVolumesRangeDiscountsFragment on VolumeRange {
		...ToVolumeRangeFragment
		...FromVolumeRangeFragment
		volumeDiscountRange {
			...VolumeDiscountRangeKeyFieldsFragment
			...DiscountVolumeDiscountRangeFragment
		}
	}
`;

export const VOLUME_DISCOUNT_RANGE_PACK_ID_AND_VOLUME_RANGE_ID_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_KEY_FIELDS_FRAGMENT}
	fragment VolumeDiscountRangePackIdAndVolumeRangeIdFragment on VolumeRange {
		id
		volumeDiscountRange {
			...VolumeDiscountRangeKeyFieldsFragment
		}
	}
`;

export const VOLUME_RANGE_VOLUME_DISCOUNT_RANGE_DISCOUNT_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT}
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	fragment VolumeRangeVolumeDiscountRangeDiscountFragment on VolumeRange {
		id
		...FromVolumeRangeFragment
		...ToVolumeRangeFragment
		volumeDiscountRange {
			...VolumeDiscountRangeDiscountEnableDiscountFragment
		}
	}
`;

export const VOLUME_RANGE_FOR_PUBLIC_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_FOR_PUBLIC_FRAGMENT}
	${FROM_VOLUME_RANGE_FRAGMENT}
	${VOLUME_RANGE_DEPENDENCIES_FRAGMENT}
	${IS_DEFAULT_SELECTED_VOLUME_RANGE_FRAGMENT}
	${DESCRIPTION_VOLUME_RANGE_FRAGMENT}
	fragment VolumeRangeForPublicFragment on VolumeRange {
		...FromVolumeRangeFragment
		...VolumeRangeDependenciesFragment
		...IsDefaultSelectedVolumeRangeFragment
		...DescriptionVolumeRangeFragment
		volumeDiscountId
		index
		to
		volumeDiscountRange {
			...VolumeDiscountRangeForPublicFragment
		}
		uuid
	}
`;

export const VOLUME_RANGE_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_FRAGMENT}
	${FROM_VOLUME_RANGE_FRAGMENT}
	${VOLUME_RANGE_DEPENDENCIES_FRAGMENT}
	${IS_DEFAULT_SELECTED_VOLUME_RANGE_FRAGMENT}
	fragment VolumeRangeFragment on VolumeRange {
		...FromVolumeRangeFragment
		...VolumeRangeDependenciesFragment
		...IsDefaultSelectedVolumeRangeFragment
		volumeDiscountId
		index
		to
		description
		error
		uuid
		volumeDiscountRange {
			...VolumeDiscountRangeFragment
		}
	}
`;

export const VOLUME_DISCOUNT_RANGE_DATA_TO_COMPUTE_IF_DISABLED_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_ENABLE_DISCOUNT_FRAGMENT}
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	fragment VolumeDiscountRangeDataToComputeIfDisabledFragment on VolumeRange {
		id
		...FromVolumeRangeFragment
		...ToVolumeRangeFragment
		volumeDiscountRange {
			...VolumeDiscountRangeEnableDiscountFragment
		}
	}
`;

export const VOLUME_RANGE_ENABLED_DISCOUNT_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_ENABLE_DISCOUNT_FRAGMENT}
	fragment VolumeRangeEnabledDiscountFragment on VolumeRange {
		id
		volumeDiscountRange {
			...VolumeDiscountRangeEnableDiscountFragment
		}
	}
`;
