import i18n, { currencyToLocal } from "./i18n";
import { NumberFormat } from "priceit-ui";

const TEN = 10;
const HUNDRED = 100;
const THOUSAND = 1000;
const MILLION = 1000000;
const BILLION = 1000000000;

Number.prototype.fnb = function ({
	decimal,
	truncate,
	showInfinity,
	lineIfZero,
	zeroIfNan,
	stringText,
	compact,
	withCurrency,
	decomposedResult,
	removeZeroAfterDecimal,
	sign = "",
	currency = "EUR",
}) {
	const nbOfDecimal = decimal !== undefined ? decimal : 2;
	let number = Number(this.valueOf());

	if (truncate) {
		number = Math.trunc(number);
		return number;
	}

	if ((Math.abs(number) === Infinity && !showInfinity) || (!number && lineIfZero)) {
		return "-";
	}

	if (isNaN(number)) {
		if (zeroIfNan) {
			number = 0;
		} else {
			return number;
		}
	}

	if (stringText) {
		const locale = currencyToLocal(currency);
		const formatterOptions = {
			notation: compact ? "compact" : "standard",
			compactDisplay: "short",
			minimumFractionDigits: removeZeroAfterDecimal ? 0 : nbOfDecimal,
			maximumFractionDigits: nbOfDecimal,
			...(withCurrency
				? {
						style: "currency",
						currency,
						currencyDisplay: "symbol",
					}
				: {}),
		};
		const formatter = new Intl.NumberFormat(locale, formatterOptions);
		return decomposedResult
			? NumberFormat.formatNumber(formatter, number, locale.split("-")[1], sign)
			: NumberFormat.formatNumber(formatter, number, locale.split("-")[1], sign)
					.formattedValue;
	}

	const value = 0;
	let result;

	if (Math.abs(number / BILLION) >= 1) {
		result = {
			value:
				Math.round((number / BILLION) * Math.pow(10, nbOfDecimal)) /
				Math.pow(10, nbOfDecimal),
			unit: i18n.t("Billion_translate"),
			multiplier: 1000000000,
		};
	} else if (Math.abs(number / MILLION) >= 1) {
		result = {
			value:
				Math.round((number / MILLION) * Math.pow(10, nbOfDecimal)) /
				Math.pow(10, nbOfDecimal),
			unit: "M",
			multiplier: 1000000,
		};
	} else if (Math.abs(number / THOUSAND) >= 1) {
		result = {
			value:
				Math.round((number / THOUSAND) * Math.pow(10, nbOfDecimal)) /
				Math.pow(10, nbOfDecimal),
			unit: "k",
			multiplier: 1000,
		};
	} else if (Math.abs(number / HUNDRED) >= 1) {
		result = {
			value: decimal !== undefined ? number.toFixed(nbOfDecimal) : number.toPrecision(5),
			unit: "",
			multiplier: 1,
		};
	} else if (Math.abs(number / TEN) >= 1) {
		result = {
			value: decimal !== undefined ? number.toFixed(nbOfDecimal) : number.toPrecision(4),
			unit: "",
			multiplier: 1,
		};
	} else {
		result = {
			value: decimal !== undefined ? number.toFixed(nbOfDecimal) : number.toPrecision(3),
			unit: "",
			multiplier: 1,
		};
	}

	result.value = zeroIfNan
		? isNaN(result.value)
			? value.toFixed(nbOfDecimal)
			: result.value
		: result.value;

	return result;
};

//TODO Refactor it so that we can leverage localized currency format
Number.prototype.fnbYaxis = function (unit, multiplier, decimal) {
	return (
		Math.round((Number(this.valueOf()) / multiplier) * Math.pow(10, decimal)) /
			Math.pow(10, decimal) +
		unit
	);
};

Number.prototype.ordinal = function ({ suffixOnly, language, gender, decimal = 0 }) {
	const j = this % 10,
		k = this % 100;

	switch (language) {
		case "fr":
			if (this === 1) {
				if (suffixOnly) return gender === "male" ? "er" : "ère";
				return (
					this.fnb({
						stringText: true,
						decimal,
						zeroIfNan: true,
					}) + (gender === "male" ? "er" : "ère")
				);
			}
			if (suffixOnly) return "ème";
			return (
				this.fnb({
					stringText: true,
					decimal,
					zeroIfNan: true,
				}) + "ème"
			);

		default:
			if (j === 1 && k !== 11) {
				if (suffixOnly) return "st";
				return (
					this.fnb({
						stringText: true,
						decimal,
						zeroIfNan: true,
					}) + "st"
				);
			}
			if (j === 2 && k !== 12) {
				if (suffixOnly) return "nd";
				return (
					this.fnb({
						stringText: true,
						decimal,
						zeroIfNan: true,
					}) + "nd"
				);
			}
			if (j === 3 && k !== 13) {
				if (suffixOnly) return "rd";
				return (
					this.fnb({
						stringText: true,
						decimal,
						zeroIfNan: true,
					}) + "rd"
				);
			}
			if (suffixOnly) return "th";
			return (
				this.fnb({
					stringText: true,
					decimal,
					zeroIfNan: true,
				}) + "th"
			);
	}
};

Number.prototype.countDecimals = function () {
	if (Math.floor(this.valueOf()) === this.valueOf()) {
		return 0;
	}

	const str = this.toString();
	if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
		return str.split("-")[1] || 0;
	} else if (str.indexOf(".") !== -1) {
		return str.split(".")[1].length || 0;
	}
	return str.split("-")[1] || 0;
};
