import { gql } from "@apollo/client";

export const SCHEDULED_MAIL_ID_FRAGMENT = gql`
	fragment ScheduledMailIdFragment on ScheduledMail {
		id
	}
`;

export const SCHEDULED_MAIL_MAIL_ID_FRAGMENT = gql`
	${SCHEDULED_MAIL_ID_FRAGMENT}
	fragment ScheduledMailMailIdFragment on ScheduledMail {
		...ScheduledMailIdFragment
		mailId
	}
`;

export const SCHEDULED_MAIL_USER_ID_FRAGMENT = gql`
	${SCHEDULED_MAIL_ID_FRAGMENT}
	fragment ScheduledMailUserIdFragment on ScheduledMail {
		...ScheduledMailIdFragment
		userId
	}
`;

export const SCHEDULED_MAIL_SEND_AT_FRAGMENT = gql`
	${SCHEDULED_MAIL_ID_FRAGMENT}
	fragment ScheduledMailSendAtIdFragment on ScheduledMail {
		...ScheduledMailIdFragment
		sendAt
	}
`;

export const SCHEDULED_MAIL_TYPE_FRAGMENT = gql`
	${SCHEDULED_MAIL_ID_FRAGMENT}
	fragment ScheduledMailTypeFragment on ScheduledMail {
		...ScheduledMailIdFragment
		type
	}
`;

export const SCHEDULED_MAIL_WORKSPACE_ID_FRAGMENT = gql`
	${SCHEDULED_MAIL_ID_FRAGMENT}
	fragment ScheduledMailWorkspaceIdFragment on ScheduledMail {
		...ScheduledMailIdFragment
		workspaceId
	}
`;

export const SCHEDULED_MAIL_MAIL_FRAGMENT = gql`
	${SCHEDULED_MAIL_ID_FRAGMENT}
	${SCHEDULED_MAIL_MAIL_ID_FRAGMENT}
	${SCHEDULED_MAIL_USER_ID_FRAGMENT}
	${SCHEDULED_MAIL_SEND_AT_FRAGMENT}
	${SCHEDULED_MAIL_TYPE_FRAGMENT}
	${SCHEDULED_MAIL_WORKSPACE_ID_FRAGMENT}
	fragment ScheduledMailMailFragment on ScheduledMail {
		...ScheduledMailIdFragment
		...ScheduledMailMailIdFragment
		...ScheduledMailUserIdFragment
		...ScheduledMailSendAtIdFragment
		...ScheduledMailTypeFragment
		...ScheduledMailWorkspaceIdFragment
	}
`;
