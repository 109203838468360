import { gql } from "@apollo/client";
import {
	ENGAGEMENT_DISCOUNT_RANGE_DATA_TO_COMPUTE_IF_DISABLED_FRAGMENT,
	ENGAGEMENT_RANGE_ENGAGEMENT_DISCOUNT_RANGE_DISCOUNT_FRAGMENT,
	ENGAGEMENT_RANGE_FULL_FRAGMENT,
	INDEX_ENGAGEMENT_RANGE_FRAGMENT,
} from "@graphQl/fragments/engagementRangeFragment";

export const RULES_SECTION_ENGAGEMENT_DISCOUNT_FRAGMENT = gql`
	fragment RulesSectionEngagementDiscountFragment on EngagementDiscount {
		engagementRange {
			id
			duration
			index
		}
	}
`;

export const IS_LOCKED_ENGAGEMENT_DISCOUNT_FRAGMENT = gql`
	fragment IsLockedEngagementDiscountFragment on EngagementDiscount {
		id
		isLocked
	}
`;

export const ENGAGEMENT_DISCOUNT_ENGAGEMENT_RANGE_INDEX_FRAGMENT = gql`
	${INDEX_ENGAGEMENT_RANGE_FRAGMENT}
	fragment EngagementDiscountEngagementRangeIndexFragment on EngagementDiscount {
		id
		engagementRange {
			...IndexEngagementRangeFragment
		}
	}
`;

export const ENGAGEMENT_DISCOUNT_ENGAGEMENT_RANGE_FRAGMENT = gql`
	${ENGAGEMENT_RANGE_FULL_FRAGMENT}
	${IS_LOCKED_ENGAGEMENT_DISCOUNT_FRAGMENT}
	fragment EngagementDiscountEngagementRangeFragment on EngagementDiscount {
		id
		uuid
		...IsLockedEngagementDiscountFragment
		engagementRange {
			...EngagementRangeFullFragment
		}
	}
`;

export const ENGAGEMENT_RANGE_DATA_TO_COMPUTE_IF_DISABLED_FRAGMENT = gql`
	${ENGAGEMENT_DISCOUNT_RANGE_DATA_TO_COMPUTE_IF_DISABLED_FRAGMENT}
	fragment EngagementRangeDataToComputeIfDisabledFragment on EngagementDiscount {
		id
		engagementRange {
			...EngagementDiscountRangeDataToComputeIfDisabledFragment
		}
	}
`;

export const ENGAGEMENT_DISCOUNT_DATA_TO_COMPUTE_SAVINGS_FRAGMENT = gql`
	${ENGAGEMENT_RANGE_ENGAGEMENT_DISCOUNT_RANGE_DISCOUNT_FRAGMENT}
	fragment EngagementDiscountDataToComputeSavingsFragment on EngagementDiscount {
		id
		engagementRange {
			...EngagementRangeEngagementDiscountRangeDiscountFragment
		}
	}
`;
