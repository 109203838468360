import { gql } from "@apollo/client";

export const OFFER_VERSION_RULE_ID_FRAGMENT = gql`
	fragment OfferVersionRuleIdFragment on OfferVersionRuleInterface {
		uuid
	}
`;

export const OFFER_VERSION_RULE_OFFER_VERSION_ID_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleOfferVersionIdFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		offerVersionId
	}
`;

export const OFFER_VERSION_RULE_CREATED_AT_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleCreatedAtFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		createdAt
	}
`;

export const OFFER_VERSION_RULE_UPDATED_AT_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleUpdatedAtFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		updatedAt
	}
`;

export const OFFER_VERSION_RULE_CREATED_BY_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleCreatedByFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		createdBy
	}
`;

export const OFFER_VERSION_RULE_UPDATED_BY_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleUpdatedByFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		updatedBy
	}
`;

export const OFFER_VERSION_RULE_IS_GLOBAL_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleIsGlobalFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		isGlobal
	}
`;

export const OFFER_VERSION_RULE_VALUE_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleValueFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		value
	}
`;

export const OFFER_VERSION_RULE_VALUE_TYPE_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleValueTypeFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		valueType
	}
`;

export const OFFER_VERSION_RULE_NAME_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleNameFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		name
	}
`;

export const OFFER_VERSION_RULE_RULE_TYPE_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleRuleTypeFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		ruleType
	}
`;

export const OFFER_VERSION_RULE_TAB_ID_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleTabIdFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		tabId
	}
`;

export const OFFER_VERSION_RULE_METRIC_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleMetricFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		metric
	}
`;

export const OFFER_VERSION_RULE_INDEX_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleIndexFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		index
	}
`;

export const OFFER_VERSION_RULE_ONE_SHOT_AMOUNT_FRAGMENT = gql`
	${OFFER_VERSION_RULE_ID_FRAGMENT}
	fragment OfferVersionRuleOneShotAmountFragment on OfferVersionRuleInterface {
		...OfferVersionRuleIdFragment
		oneShotAmount
	}
`;

export const OFFER_VERSION_RULE_INTERFACE_FRAGMENT = gql`
	${OFFER_VERSION_RULE_OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_RULE_IS_GLOBAL_FRAGMENT}
	${OFFER_VERSION_RULE_VALUE_FRAGMENT}
	${OFFER_VERSION_RULE_VALUE_TYPE_FRAGMENT}
	${OFFER_VERSION_RULE_NAME_FRAGMENT}
	${OFFER_VERSION_RULE_RULE_TYPE_FRAGMENT}
	${OFFER_VERSION_RULE_TAB_ID_FRAGMENT}
	${OFFER_VERSION_RULE_METRIC_FRAGMENT}
	${OFFER_VERSION_RULE_INDEX_FRAGMENT}
	${OFFER_VERSION_RULE_ONE_SHOT_AMOUNT_FRAGMENT}
	fragment OfferVersionRuleInterfaceFragment on OfferVersionRuleInterface {
		...OfferVersionRuleOfferVersionIdFragment
		...OfferVersionRuleIsGlobalFragment
		...OfferVersionRuleValueFragment
		...OfferVersionRuleValueTypeFragment
		...OfferVersionRuleNameFragment
		...OfferVersionRuleRuleTypeFragment
		...OfferVersionRuleTabIdFragment
		...OfferVersionRuleMetricFragment
		...OfferVersionRuleIndexFragment
		...OfferVersionRuleOneShotAmountFragment
	}
`;

export const OFFER_VERSION_RULE_FRAGMENT = gql`
	${OFFER_VERSION_RULE_INTERFACE_FRAGMENT}
	fragment OfferVersionRuleFragment on OfferVersionRule {
		...OfferVersionRuleInterfaceFragment
	}
`;
