import { gql } from "@apollo/client";

export const PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT = gql`
	fragment PaymentTermRangeDiscountPackIdFragment on PaymentTermRangeDiscount {
		id
		packId
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT = gql`
	fragment PaymentTermRangeDiscountPaymentTermRangeIdFragment on PaymentTermRangeDiscount {
		id
		paymentTermRangeId
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_DISCOUNT_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	fragment PaymentTermRangeDiscountDiscountFragment on PaymentTermRangeDiscount {
		id
		discount
		...PaymentTermRangeDiscountPackIdFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_ABSOLUTE_DISCOUNT_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	fragment PaymentTermRangeDiscountAbsoluteDiscountFragment on PaymentTermRangeDiscount {
		id
		absoluteDiscount
		...PaymentTermRangeDiscountPackIdFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_DIFF_PREVIOUS_RANGE_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	fragment PaymentTermRangeDiscountDiffPreviousRangeFragment on PaymentTermRangeDiscount {
		id
		diffPreviousRange
		...PaymentTermRangeDiscountPackIdFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_PRICE_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	fragment PaymentTermRangeDiscountPriceFragment on PaymentTermRangeDiscount {
		id
		price
		...PaymentTermRangeDiscountPackIdFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT = gql`
	fragment PaymentTermRangeDiscountEnableDiscountFragment on PaymentTermRangeDiscount {
		id
		enableDiscount
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_WARNING_FRAGMENT = gql`
	fragment PaymentTermRangeDiscountWarningFragment on PaymentTermRangeDiscount {
		id
		warning
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_AND_PAYMENT_TERM_RANGE_ID_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	fragment PaymentTermRangeDiscountPackIdAndPaymentTermRangeIdFragment on PaymentTermRangeDiscount {
		id
		...PaymentTermRangeDiscountPackIdFragment
		...PaymentTermRangeDiscountPaymentTermRangeIdFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_FULL_ID_PRICE_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_AND_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_DISCOUNT_FRAGMENT}
	fragment PaymentTermRangeDiscountFullIdPriceFragment on PaymentTermRangeDiscount {
		...PaymentTermRangeDiscountPackIdAndPaymentTermRangeIdFragment
		...PaymentTermRangeDiscountDiscountFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PRICE_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_ABSOLUTE_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_DIFF_PREVIOUS_RANGE_FRAGMENT}
	fragment PaymentTermRangeDiscountFragment on PaymentTermRangeDiscount {
		id
		...PaymentTermRangeDiscountPackIdFragment
		...PaymentTermRangeDiscountPaymentTermRangeIdFragment
		...PaymentTermRangeDiscountEnableDiscountFragment
		...PaymentTermRangeDiscountDiscountFragment
		...PaymentTermRangeDiscountPriceFragment
		...PaymentTermRangeDiscountAbsoluteDiscountFragment
		...PaymentTermRangeDiscountDiffPreviousRangeFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_DATA_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PRICE_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_WARNING_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	fragment PaymentTermRangeDiscountDataFragment on PaymentTermRangeDiscount {
		id
		...PaymentTermRangeDiscountDiscountFragment
		...PaymentTermRangeDiscountPriceFragment
		...PaymentTermRangeDiscountEnableDiscountFragment
		...PaymentTermRangeDiscountWarningFragment
		...PaymentTermRangeDiscountPackIdFragment
		...PaymentTermRangeDiscountPaymentTermRangeIdFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_WARNING_DISCOUNT_AND_ENABLE_DISCOUNT_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_WARNING_FRAGMENT}
	fragment PaymentTermRangeDiscountWarningDiscountAndEnableDiscountFragment on PaymentTermRangeDiscount {
		id
		...PaymentTermRangeDiscountDiscountFragment
		...PaymentTermRangeDiscountEnableDiscountFragment
		...PaymentTermRangeDiscountWarningFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_PAYMENT_TERM_RANGE_ID_AND_ENABLE_DISCOUNT_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT}
	fragment PaymentTermRangeDiscountPackIdPaymentTermRangeIdAndEnableDiscountFragment on PaymentTermRangeDiscount {
		id
		...PaymentTermRangeDiscountPackIdFragment
		...PaymentTermRangeDiscountPaymentTermRangeIdFragment
		...PaymentTermRangeDiscountEnableDiscountFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_PAYMENT_TERM_RANGE_ID_AND_DISCOUNT_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_DISCOUNT_FRAGMENT}
	fragment PaymentTermRangeDiscountPackIdPaymentTermRangeIdAndDiscountFragment on PaymentTermRangeDiscount {
		id
		...PaymentTermRangeDiscountPackIdFragment
		...PaymentTermRangeDiscountPaymentTermRangeIdFragment
		...PaymentTermRangeDiscountDiscountFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_PAYMENT_TERM_RANGE_ID_AND_PRICE_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PRICE_FRAGMENT}
	fragment PaymentTermRangeDiscountPackIdPaymentTermRangeIdAndPriceFragment on PaymentTermRangeDiscount {
		id
		...PaymentTermRangeDiscountPackIdFragment
		...PaymentTermRangeDiscountPaymentTermRangeIdFragment
		...PaymentTermRangeDiscountPriceFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_PAYMENT_TERM_RANGE_ID_AND_ABSOLUTE_DISCOUNT_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_ABSOLUTE_DISCOUNT_FRAGMENT}
	fragment PaymentTermRangeDiscountPackIdPaymentTermRangeIdAndAbsoluteDiscountFragment on PaymentTermRangeDiscount {
		id
		...PaymentTermRangeDiscountPackIdFragment
		...PaymentTermRangeDiscountPaymentTermRangeIdFragment
		...PaymentTermRangeDiscountAbsoluteDiscountFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_PAYMENT_TERM_RANGE_ID_AND_DIFF_PREVIOUS_RANGE_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_DIFF_PREVIOUS_RANGE_FRAGMENT}
	fragment PaymentTermRangeDiscountPackIdPaymentTermRangeIdAndDiffPreviousRangeFragment on PaymentTermRangeDiscount {
		id
		...PaymentTermRangeDiscountPackIdFragment
		...PaymentTermRangeDiscountPaymentTermRangeIdFragment
		...PaymentTermRangeDiscountDiffPreviousRangeFragment
	}
`;

export const PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_DISCOUNT_PAYMENT_TERM_RANGE_ID_AND_ENABLE_DISCOUNT_FRAGMENT = gql`
	${PAYMENT_TERM_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_PRICE_FRAGMENT}
	fragment PaymentTermRangeDiscountPackIdDiscountPaymentTermRangeIdAndEnableDiscountFragment on PaymentTermRangeDiscount {
		id
		...PaymentTermRangeDiscountPackIdFragment
		...PaymentTermRangeDiscountDiscountFragment
		...PaymentTermRangeDiscountPaymentTermRangeIdFragment
		...PaymentTermRangeDiscountEnableDiscountFragment
		...PaymentTermRangeDiscountPriceFragment
	}
`;
