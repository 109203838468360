import { gql } from "@apollo/client";

export const VARIABLE_TIME_RANGE_DISCOUNT_PACK_ID_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountPackIdFragment on VariableTimeRangeDiscount {
		id
		packId
		variableTimeRange {
			id
		}
	}
`;

export const VARIABLE_TIME_RANGE_DISCOUNT_DISCOUNT_FRAGMENT = gql`
	${VARIABLE_TIME_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	fragment VariableTimeRangeDiscountDiscountFragment on VariableTimeRangeDiscount {
		...VariableTimeRangeDiscountPackIdFragment
		discount
	}
`;

export const VARIABLE_TIME_RANGE_DISCOUNT_PRICE_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountPriceFragment on VariableTimeRangeDiscount {
		id
		price
	}
`;

export const VARIABLE_TIME_RANGE_DISCOUNT_TOTAL_PRICE_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountTotalPriceFragment on VariableTimeRangeDiscount {
		id
		totalPrice
	}
`;

export const VARIABLE_TIME_RANGE_DISCOUNT_AND_PRICE_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountAndPriceFragment on VariableTimeRangeDiscount {
		id
		discount
		price
	}
`;

export const VARIABLE_TIME_RANGE_DISCOUNT_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountFragment on VariableTimeRangeDiscount {
		id
		packId
		variableTimeRange {
			id
		}
		discount
	}
`;

export const VARIABLE_TIME_RANGE_DISCOUNT_FULL_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountFullFragment on VariableTimeRangeDiscount {
		id
		packId
		variableTimeRange {
			id
		}
		discount
		price
		totalPrice
	}
`;
