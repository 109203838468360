import { gql } from "@apollo/client";
import { PUBLIC_SWITCH_OPTION_FRAGMENT, SWITCH_OPTION_FRAGMENT } from "./switchOption";

export const SWITCH_FRAGMENT = gql`
	${SWITCH_OPTION_FRAGMENT}
	fragment SwitchFragment on Switch {
		id
		options {
			...SwitchOptionFragment
		}
	}
`;

export const PUBLIC_SWITCH_FRAGMENT = gql`
	${PUBLIC_SWITCH_OPTION_FRAGMENT}
	fragment PublicSwitchFragment on Switch {
		id
		options {
			...PublicSwitchOptionFragment
		}
	}
`;
