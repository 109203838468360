import styled from "styled-components";
import { Button } from "priceit-ui";

export const WrapperLoader = styled.div`
	background-color: ${({ theme }) => theme.page.backgroundColor};
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const WrongWorkspaceWrapper = styled.div`
	background-color: ${({ mode, theme }) =>
		mode === "dark" ? theme.page.backgroundColor : theme.page.tertiaryBackgroundColor};
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 36px 42px;
`;
export const WrongWorkspacePanel = styled.div`
	background-color: ${({ mode, theme }) => theme.page.tertiaryBackgroundColor};
	width: 100%;
	height: 100%;
	box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
export const ImageWrapper = styled.div`
	width: 40%;
	max-width: 575px;
	> img {
		width: 100%;
	}
`;
export const TitleText = styled.div`
	font-size: 40px;
	font-weight: 600;
	color: ${({ theme }) => theme.page.textColor};
	margin-top: 20px;
`;
export const SubText = styled.div`
	font-size: 20px;
	font-weight: 400;
	color: ${({ theme }) => theme.page.textColor};
	margin-top: 20px;
	width: 75%;
	text-align: center;
`;
export const ButtonWrapper = styled.div`
	margin-top: 60px;
`;
export const StyledButton = styled(Button)`
	min-width: 130px;
`;
