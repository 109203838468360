import { gql } from "@apollo/client";

import {
	PUBLIC_SELECT_OPTION_FRAGMENT,
	SELECT_OPTION_FRAGMENT,
} from "@graphQl/fragments/selectOption";

export const SELECT_PLACEHOLDER_FRAGMENT = gql`
	fragment SelectPlaceHolderFragment on Select {
		id
		placeHolder
	}
`;

export const SELECT_FRAGMENT = gql`
	${SELECT_PLACEHOLDER_FRAGMENT}
	${SELECT_OPTION_FRAGMENT}
	fragment SelectFragment on Select {
		id
		...SelectPlaceHolderFragment
		options {
			...SelectOptionFragment
		}
	}
`;

export const PUBLIC_SELECT_FRAGMENT = gql`
	${SELECT_PLACEHOLDER_FRAGMENT}
	${PUBLIC_SELECT_OPTION_FRAGMENT}
	fragment PublicSelectFragment on Select {
		id
		...SelectPlaceHolderFragment
		options {
			...PublicSelectOptionFragment
		}
	}
`;
