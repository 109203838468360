import { gql } from "@apollo/client";

export const TERMS_AND_CONDITIONS_ID_FRAGMENT = gql`
	fragment TermsAndConditionsIdFragment on TermsAndConditions {
		id
	}
`;

export const TERMS_AND_CONDITIONS_LANGUAGE_FRAGMENT = gql`
	fragment TermsAndConditionsLanguageFragment on TermsAndConditions {
		id
		language
	}
`;

export const TERMS_AND_CONDITIONS_WORKSPACE_ID_FRAGMENT = gql`
	fragment TermsAndConditionsWorkspaceIdFragment on TermsAndConditions {
		id
		workspaceId
	}
`;

export const TERMS_AND_CONDITIONS_TYPE_FRAGMENT = gql`
	fragment TermsAndConditionsTypeFragment on TermsAndConditions {
		id
		type
	}
`;

export const TERMS_AND_CONDITIONS_TC_URL_LINK_FRAGMENT = gql`
	fragment TermsAndConditionsTCUrlLinkFragment on TermsAndConditions {
		id
		tcUrlLink
	}
`;

export const TERMS_AND_CONDITIONS_TC_PDF_LINK_FRAGMENT = gql`
	fragment TermsAndConditionsTCPDFLinkFragment on TermsAndConditions {
		id
		tcPDFLink
	}
`;

export const TERMS_AND_CONDITIONS_TC_PDF_NAME_FRAGMENT = gql`
	fragment TermsAndConditionsTCPDFNameFragment on TermsAndConditions {
		id
		tcPDFName
	}
`;

export const TERMS_AND_CONDITIONS_TC_LINK_AND_PDF_NAME_FRAGMENT = gql`
	${TERMS_AND_CONDITIONS_TC_PDF_LINK_FRAGMENT}
	${TERMS_AND_CONDITIONS_TC_PDF_NAME_FRAGMENT}
	fragment TermsAndConditionsTCLinkAndPdfNameFragment on TermsAndConditions {
		...TermsAndConditionsTCPDFLinkFragment
		...TermsAndConditionsTCPDFNameFragment
	}
`;

export const TERMS_AND_CONDITIONS_TC_BUTTON_TEXT_FRAGMENT = gql`
	fragment TermsAndConditionsTCButtonTextFragment on TermsAndConditions {
		id
		tcButtonText
	}
`;

export const TERMS_AND_CONDITIONS_FRAGMENT = gql`
	${TERMS_AND_CONDITIONS_WORKSPACE_ID_FRAGMENT}
	${TERMS_AND_CONDITIONS_TC_URL_LINK_FRAGMENT}
	${TERMS_AND_CONDITIONS_TC_PDF_LINK_FRAGMENT}
	${TERMS_AND_CONDITIONS_TC_PDF_NAME_FRAGMENT}
	${TERMS_AND_CONDITIONS_LANGUAGE_FRAGMENT}
	${TERMS_AND_CONDITIONS_TYPE_FRAGMENT}
	${TERMS_AND_CONDITIONS_TC_BUTTON_TEXT_FRAGMENT}
	fragment TermsAndConditionsFragment on TermsAndConditions {
		...TermsAndConditionsWorkspaceIdFragment
		...TermsAndConditionsTCUrlLinkFragment
		...TermsAndConditionsTCPDFLinkFragment
		...TermsAndConditionsTCButtonTextFragment
		...TermsAndConditionsTCPDFNameFragment
		...TermsAndConditionsLanguageFragment
		...TermsAndConditionsTypeFragment
	}
`;
