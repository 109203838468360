import setAuthorizationCookie from "@services/setAuthorizationCookie";

export default () => {
	const { history, location } = window;
	const queryString = location.search;
	const urlParams = new URLSearchParams(queryString);
	const jwtTokenParam = urlParams.get("jwt");
	if (jwtTokenParam) {
		urlParams.delete("jwt");
		if (history.replaceState) {
			const searchString = urlParams.toString().length > 0 ? `?${urlParams.toString()}` : "";
			const newUrl = `${location.origin}${window.location.pathname}${searchString}`;
			history.replaceState(null, "", newUrl);
		}
		localStorage.setItem("jwtToken", jwtTokenParam);
		setAuthorizationCookie(jwtTokenParam);
	}
};
