import { gql } from "@apollo/client";
import { VARIABLE_TIME_RANGE_DISCOUNT_FULL_FRAGMENT } from "./variableTimeRangeDiscount";
import { PAYMENT_TERM_RANGE_FRAGMENT } from "./paymentTermRangeFragment";

export const DURATION_VARIABLE_TIME_RANGE_FRAGMENT = gql`
	fragment DurationVariableTimeRangeFragment on VariableTimeRange {
		id
		duration
	}
`;

export const INDEX_VARIABLE_TIME_RANGE_FRAGMENT = gql`
	fragment IndexVariableTimeRangeFragment on VariableTimeRange {
		id
		index
	}
`;

export const IS_UNLIMITED_VARIABLE_TIME_RANGE_FRAGMENT = gql`
	fragment IsUnlimitedVariableTimeRangeFragment on VariableTimeRange {
		id
		isUnlimited
	}
`;

export const VARIABLE_TIME_RANGE_DISCOUNTS_VARIABLE_TIME_RANGE_FRAGMENT = gql`
	${VARIABLE_TIME_RANGE_DISCOUNT_FULL_FRAGMENT}
	fragment VariableTimeRangeDiscountsVariableTimeRangeFragment on VariableTimeRange {
		id
		variableTimeRangeDiscounts {
			...VariableTimeRangeDiscountFullFragment
		}
	}
`;

export const VARIABLE_TIME_RANGE_FRAGMENT = gql`
	${VARIABLE_TIME_RANGE_DISCOUNT_FULL_FRAGMENT}
	fragment VariableTimeRangeFragment on VariableTimeRange {
		id
		variableTimeDiscountId
		duration
		index
		isUnlimited
		variableTimeRangeDiscounts {
			...VariableTimeRangeDiscountFullFragment
		}
	}
`;
