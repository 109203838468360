import { gql } from "@apollo/client";
import { OFFER_VERSION_RULE_FOR_REPAYMENT_SCHEDULE_FRAGMENT } from "@graphQl/fragments/offerVersionRuleForRepaymentSchedule";

export const REPAYMENT_SCHEDULE_TVA_TVA_FRAGMENT = gql`
	fragment RepaymentScheduleTvaTvaFragment on RepaymentScheduleTva {
		tva
	}
`;

export const REPAYMENT_SCHEDULE_TVA_RATE_FRAGMENT = gql`
	fragment RepaymentScheduleTVARateFragment on RepaymentScheduleTva {
		rate
	}
`;

export const REPAYMENT_SCHEDULE_TVA_FRAGMENT = gql`
	${REPAYMENT_SCHEDULE_TVA_TVA_FRAGMENT}
	${REPAYMENT_SCHEDULE_TVA_RATE_FRAGMENT}
	fragment RepaymentScheduleTVAFragment on RepaymentScheduleTva {
		...RepaymentScheduleTvaTvaFragment
		...RepaymentScheduleTVARateFragment
	}
`;
