import React, { useEffect, useState } from "react";
import { useGlobalContext } from "@globalContext";
import { useLocation } from "react-router-dom";

export const useIframeActions = () => {
	const { light } = useGlobalContext();
	const { search } = useLocation();
	const [crmService, setCrmService] = useState(null);

	const confirmCreateOffer = () => {
		if (!light) {
			return;
		}
		if (crmService === "hubspot") {
			window.parent.postMessage(JSON.stringify({ action: "DONE" }), "*");
		} else {
			window.close();
		}
	};
	const cancelCreateOffer = () => {
		if (!light) {
			return;
		}
		if (crmService === "hubspot") {
			window.parent.postMessage(JSON.stringify({ action: "CANCEL" }), "*");
		} else {
			window.close();
		}
	};

	useEffect(() => {
		const searchParams = new URLSearchParams(search);
		const crmServiceParam = searchParams.get("crmservice");
		if (crmServiceParam) {
			setCrmService(crmServiceParam);
		}
	}, []);
	return [confirmCreateOffer, cancelCreateOffer];
};
