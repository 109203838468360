import { gql } from "@apollo/client";
import { CONNECTION_OWNER_FRAGMENT } from "@graphQl/fragments/user";

export const CRM_CONNECTION_SERVICE_FRAGMENT = gql`
	fragment CrmConnectionServiceFragment on CrmService {
		id
		name
	}
`;

export const CRM_CONNECTION_SERVICE_DETAILS_FRAGMENT = gql`
	fragment CrmConnectionServiceDetailsFragment on CrmService {
		id
		name
		description
		needsAdditionalInitAuthInfos
		additionalInfosStructure {
			label
			options {
				name
				value
			}
			required
			type
		}
	}
`;

export const CRM_CONNECTION_ACCOUNT_FRAGMENT = gql`
	fragment CrmConnectionAccountFragment on CrmConnectionAccount {
		email
		firstName
		lastName
		crmWorkspaceId
	}
`;

export const CRM_CONNECTION_FRAGMENT = gql`
	${CONNECTION_OWNER_FRAGMENT}
	${CRM_CONNECTION_SERVICE_FRAGMENT}
	${CRM_CONNECTION_ACCOUNT_FRAGMENT}
	fragment CrmConnectionFragment on CrmConnection {
		id
		name
		owner {
			...ConnectionOwnerFragment
		}
		service {
			...CrmConnectionServiceFragment
		}
		account {
			...CrmConnectionAccountFragment
		}
		active
	}
`;

export const CRM_CONNECTION_SERVICE_INFOS_FRAGMENT = gql`
	fragment CrmConnectionServiceInfosFragment on CrmConnection {
		id
		name
		service {
			id
			name
		}
	}
`;

export const DEAL_SEARCH_FRAGMENT = gql`
	fragment DealSearchFragment on CrmServiceDeal {
		id
		name
	}
`;

