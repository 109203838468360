import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Loader } from "priceit-ui";
import { useQuery } from "@apollo/client";

import { GET_SERVICE_BY_ID } from "@src/graphQl/queries/crmConnection";

import {
	ButtonWrapper,
	ImageWrapper,
	StyledButton,
	SubText,
	TitleText,
	WrapperLoader,
	WrongWorkspacePanel,
	WrongWorkspaceWrapper,
} from "./WrongWorkspace.style";

import { useThemeContext } from "@theme/Theme";
import { useIframeActions } from "@hooks/UseIframeActions";

const WrongWorkspace = () => {
	const { t } = useTranslation("workspace/wrongWorkspace");
	const { mode } = useThemeContext();
	const { search } = useLocation();
	const [cancelCreateOffer] = useIframeActions();
	const searchParams = new URLSearchParams(search);
	const crmServiceId = searchParams.get("crmservice");

	const {
		data: serviceData,
		loading: serviceLoading,
		error: serviceError,
	} = useQuery(GET_SERVICE_BY_ID, {
		variables: {
			id: crmServiceId,
		},
		skip: !crmServiceId,
	});

	const loading = crmServiceId && serviceLoading && !serviceError;
	const serviceName = serviceData?.getCrmServiceById?.name;

	return (
		<WrongWorkspaceWrapper mode={mode}>
			<WrongWorkspacePanel mode={mode}>
				{loading ? (
					<WrapperLoader>
						<Loader />
					</WrapperLoader>
				) : (
					<>
						<ImageWrapper>
							<img
								src={`https://${process.env.IMAGE_PROVIDER}/images/iframe_wrong_workspace_2.svg`}
								alt={"iframe_wrong_workspace_2"}
							/>
						</ImageWrapper>
						<TitleText>{t("Oops")}</TitleText>
						<SubText>
							{t(
								"It looks like you don't have access to the Givemefive workspace linked to this {{serviceName}} account",
								{ serviceName: serviceName || t("crmService") }
							)}
						</SubText>
						<ButtonWrapper>
							<StyledButton
								mode={mode}
								type={mode === "dark" ? "secondary" : "primary"}
								onClick={() => {
									cancelCreateOffer();
								}}
							>
								{t("Cancel")}
							</StyledButton>
						</ButtonWrapper>
					</>
				)}
			</WrongWorkspacePanel>
		</WrongWorkspaceWrapper>
	);
};

export default WrongWorkspace;
