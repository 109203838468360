export const colors = {
	transparent: "transparent",
	black1: "#212325",
	black2: "#121212",
	black3: "#333639",
	black4: "#000000",
	black5: "#1D1D1D",
	black6: "#30363A",
	black7: "#404D57",
	black8: "#71889A",
	black9: "#1E272E",
	black10: "#5C6F7E",
	black11: "#A5B9C8",
	black12: "#D8DFE3",
	black13: "#38444D",
	black14: "#495965",
	black15: "#535F68",
	black16: "#2B343B",
	black17: "#354048",
	black18: "#313C43",
	black19: "#5D6B75",
	black20: "#485964",
	black21: "#414C57",
	black22: "#404D74",
	black23: "#535857",
	yellow1: "#f8b500",
	yellow2: "#FFCD38",
	yellow3: "#FFDD67",
	yellow3_30: "#FFDD6733",
	yellow4: "#FDB432",
	yellow5: "#535753",
	white1: "#ffffff",
	white2: "#E5E9EC",
	white3: "#FDFDFD",
	white4: "#D4DEE5",
	white5: "#F7F7FA",
	white6: "#EEF2F5",
	white7: "#DEE1E6",
	white8: "#f1f3f4",
	white9: "#eeeeee",
	white10: "#f4f4f4",
	white11: "#fcfcfc",
	white12: "#F8F8F8",
	white13: "f5f5f5",
	white14: "#C4C4C4",
	white15: "#FAFAFA",
	grey1: "#BBBBBB",
	grey2: "#F0F0F0",
	grey3: "#E6E5E5",
	grey4: "#4B4B4B",
	grey5: "#808080",
	grey6: "#F7F7F7",
	grey7: "#CBCBCB",
	grey8: "#F0F2F7",
	grey9: "#E4E2E2",
	grey10: "#7b7b7b",
	grey11: "#9B9B9B",
	grey12: "#f3f8fb",
	grey13: "#C4C4C4",
	grey14: "#434343",
	grey15: "#a1a1a1",
	grey16: "#585858",
	red1: "#E65757",
	red2: "#D92F2F",
	red3: "#ef233c",
	green1: "#3DCF8E",
	scoreGreen: "#3DCF8E",
	scoreRed: "#9F2146",
	orange1: "#E68357",
};

export const opacity = {
	OPACITY80: "CC",
	OPACITY65: "A6",
	OPACITY50: "80",
	OPACITY20: "33",
	OPACITY19: "30",
	OPACITY10: "1A",
	OPACITY5: "0D",
};
