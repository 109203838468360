export const cookieName = process.env.COOKIE_NAME;

export default function setAuthorizationCookie(token, set = true) {
	if (set) {
		if (!token) {
			return;
		}
		document.cookie = `${cookieName}=${token}; domain=${process.env.COOKIE_DOMAIN}; path=/; max-age=31536000`;
	} else {
		document.cookie = `${cookieName}=; domain=${process.env.COOKIE_DOMAIN}; path=/; max-age=0`;
	}
}
