import styled from "styled-components";

export const WrapperLoader = styled.div`
	background: #2b343b;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;
