import { gql, useMutation } from "@apollo/client";
import {
	USER_ACTIVE_FRAGMENT,
	USER_CURRENCY_FRAGMENT,
	USER_DEFAULT_WORKSPACE_FRAGMENT,
	USER_EMAIL_FRAGMENT,
	USER_IMAGE_PATH_FRAGMENT,
	USER_IS_ROOT_FRAGMENT,
	USER_LANGUAGE_FRAGMENT,
	USER_USERNAME_FRAGMENT,
} from "@graphQl/fragments/user";
import { ROLE_FRAGMENT } from "@graphQl/fragments/role";

const CREATE_USER = gql`
	${USER_USERNAME_FRAGMENT}
	${USER_EMAIL_FRAGMENT}
	${USER_LANGUAGE_FRAGMENT}
	${USER_CURRENCY_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	${USER_IS_ROOT_FRAGMENT}
	${USER_ACTIVE_FRAGMENT}
	mutation createUsers($createUsersInput: CreateUsersInput!) {
		createUsers(createUsersInput: $createUsersInput) {
			...UserUserNameFragment
			...UserEmailFragment
			...UserLanguageFragment
			...UserCurrencyFragment
			...UserImagePathFragment
			...UserIsRootFragment
			...UserActiveFragment
			id
		}
	}
`;

export const createUsersMutation = () => {
	const [createUsers, { loading, error }] = useMutation(CREATE_USER, {
		update(cache, { data: { createUsers } }) {
			cache.modify({
				fields: {
					getWorkspaceUsers(existingUsers = [], { readField }) {
						return [...existingUsers, ...createUsers];
					},
				},
			});
		},
	});
	return [createUsers, { loading, error }];
};

const SEND_INVITATION = gql`
	mutation sendInvitation($id: ID!) {
		sendInvitation(id: $id) {
			id
			sendAt
			workspaceId
		}
	}
`;

export const sendInvitationMutation = () => {
	const [sendInvitation, { loading, error }] = useMutation(SEND_INVITATION);
	return [sendInvitation, { loading, error }];
};

const GET_INVITATION_LINK = gql`
	mutation getInvitationLink($id: ID!) {
		getInvitationLink(id: $id)
	}
`;

export const getInvitationLinkMutation = () => {
	const [getInvitationLink, { loading, error }] = useMutation(GET_INVITATION_LINK);
	return [getInvitationLink, { loading, error }];
};

export const UPDATE_THEME_USER = gql`
	mutation updateThemeUser($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
			theme
		}
	}
`;

export const updateThemeUserMutation = () => {
	const [updateThemeUser, { loading, error }] = useMutation(UPDATE_THEME_USER);
	return [updateThemeUser, { loading, error }];
};

export const UPDATE_USER_DEFAULT_WORKSPACE = gql`
	${USER_DEFAULT_WORKSPACE_FRAGMENT}
	mutation updateUserDefaultWorkspace($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			...UserDefaultWorkspaceFragment
		}
	}
`;

export const updateUserDefaultWorkspaceMutation = () => {
	const [updateUserDefaultWorkspace, { loading, error }] = useMutation(
		UPDATE_USER_DEFAULT_WORKSPACE
	);
	return [updateUserDefaultWorkspace, { loading, error }];
};

const ADD_USERS = gql`
	mutation addUsers($usersInputs: [AddUsersInput]!) {
		addUsers(usersInputs: $usersInputs) {
			id
			username
			email
			scheduledJoinMail {
				sendAt
			}
		}
	}
`;

export const addUsersMutation = () => {
	const [addUsers, { loading, error }] = useMutation(ADD_USERS, {
		update(cache, { data: { addUsers } }) {
			cache.modify({
				fields: {
					getWorkspaceUsers(existingUsersRefs = []) {
						const newUsersRefs = (addUsers || []).map(user =>
							cache.writeFragment({
								data: user,
								fragment: gql`
									fragment NewUser on User {
										id
									}
								`,
							})
						);

						return [...existingUsersRefs, ...newUsersRefs];
					},
				},
			});
		},
	});
	return [addUsers, { loading, error }];
};

const SET_USER_ROLE = gql`
	${ROLE_FRAGMENT}
	mutation setUserRole($userId: ID!, $roleId: ID!) {
		setUserRole(userId: $userId, roleId: $roleId) {
			id
			role {
				...RoleFragment
			}
		}
	}
`;

export const setUserRoleMutation = () => {
	const [setUserRole, { loading, error }] = useMutation(SET_USER_ROLE, {
		update(cache, { data: { setUserRole } }) {
			cache.modify({
				id: cache.identify({
					__typename: "User",
					id: setUserRole.id,
				}),
				fields: {
					role() {
						return setUserRole.role;
					},
				},
			});
		},
	});
	return [setUserRole, { loading, error }];
};

const DELETE_USER_ROLE = gql`
	mutation deleteUserRole($userId: ID!, $roleId: ID!) {
		deleteUserRole(userId: $userId, roleId: $roleId) {
			id
			role {
				id
			}
		}
	}
`;

export const deleteUserRoleMutation = () => {
	const [deleteUserRole, { loading, error }] = useMutation(DELETE_USER_ROLE, {
		update(cache, { data: { deleteUserRole } }) {
			const userIdToDelete = deleteUserRole?.id;
			cache.modify({
				fields: {
					getWorkspaceUsers(existingUsersRefs = []) {
						const newUsers = existingUsersRefs.filter(
							userRef => userIdToDelete !== userRef.__ref.split(":")[1]
						);
						return newUsers;
					},
				},
			});
		},
	});
	return [deleteUserRole, { loading, error }];
};

const UPDATE_USER_USERNAME = gql`
	mutation UpdateUserUsername($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
			username
		}
	}
`;

export const updateUserUsernameMutation = () => {
	const [updateUserUsername, { loading, error }] = useMutation(UPDATE_USER_USERNAME);
	return [updateUserUsername, { loading, error }];
};

const UPDATE_USER_FIRST_NAME = gql`
	mutation UpdateUserFirstName($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
			surname
		}
	}
`;

export const updateUserFirstNameMutation = () => {
	const [updateUserFirstName, { loading, error }] = useMutation(UPDATE_USER_FIRST_NAME);
	return [updateUserFirstName, { loading, error }];
};

const UPDATE_USER_LAST_NAME = gql`
	mutation UpdateUserLastName($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
			lastname
		}
	}
`;

export const updateUserLastNameMutation = () => {
	const [updateUserLastName, { loading, error }] = useMutation(UPDATE_USER_LAST_NAME);
	return [updateUserLastName, { loading, error }];
};

const UPDATE_USER_EMAIL = gql`
	mutation UpdateUserEmail($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
			email
		}
	}
`;

export const updateUserEmailMutation = () => {
	const [updateUserEmail, { loading, error }] = useMutation(UPDATE_USER_EMAIL);
	return [updateUserEmail, { loading, error }];
};

const UPDATE_USER_PHONE = gql`
	mutation UpdateUserPhone($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
			phone
		}
	}
`;

export const updateUserPhoneMutation = () => {
	const [updateUserPhone, { loading, error }] = useMutation(UPDATE_USER_PHONE);
	return [updateUserPhone, { loading, error }];
};

const UPDATE_USER_FUNCTION = gql`
	mutation UpdateUserFunction($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
			function
		}
	}
`;

export const updateUserFunctionMutation = () => {
	const [updateUserFunction, { loading, error }] = useMutation(UPDATE_USER_FUNCTION);
	return [updateUserFunction, { loading, error }];
};

const UPDATE_USER_LANGUAGE = gql`
	mutation UpdateUserLanguage($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
			language
		}
	}
`;

export const updateUserLanguageMutation = () => {
	const [updateUserLanguage, { loading, error }] = useMutation(UPDATE_USER_LANGUAGE);
	return [updateUserLanguage, { loading, error }];
};

const UPDATE_USER_THEME = gql`
	mutation UpdateUserTheme($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
			theme
		}
	}
`;

export const updateUserThemeMutation = () => {
	const [updateUserTheme, { loading, error }] = useMutation(UPDATE_USER_THEME);
	return [updateUserTheme, { loading, error }];
};

const UPDATE_USER_IMAGE = gql`
	mutation UpdateUserImage($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
			imagePath
		}
	}
`;

export const updateUserImageMutation = () => {
	const [updateUserImage, { loading, error }] = useMutation(UPDATE_USER_IMAGE);
	return [updateUserImage, { loading, error }];
};

const UPDATE_USER_PASSWORD = gql`
	mutation UpdateUserPassword($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			id
		}
	}
`;

export const updateUserPasswordMutation = () => {
	const [updateUserPassword, { loading, error }] = useMutation(UPDATE_USER_PASSWORD);
	return [updateUserPassword, { loading, error }];
};
