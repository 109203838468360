import { gql } from "@apollo/client";

export const ADDRESS_ID_FRAGMENT = gql`
	fragment AddressIdFragment on Address {
		id
	}
`;

export const ADDRESS_ADDRESS_FRAGMENT = gql`
	${ADDRESS_ID_FRAGMENT}
	fragment AddressAddressFragment on Address {
		...AddressIdFragment
		address
	}
`;

export const ADDRESS_ADDRESS_2_FRAGMENT = gql`
	${ADDRESS_ID_FRAGMENT}
	fragment AddressAddress2Fragment on Address {
		...AddressIdFragment
		address2
	}
`;

export const ADDRESS_ZIP_FRAGMENT = gql`
	${ADDRESS_ID_FRAGMENT}
	fragment AddressZipFragment on Address {
		...AddressIdFragment
		zip
	}
`;

export const ADDRESS_CITY_FRAGMENT = gql`
	${ADDRESS_ID_FRAGMENT}
	fragment AddressCityFragment on Address {
		...AddressIdFragment
		city
	}
`;

export const ADDRESS_COUNTRY_CODE_FRAGMENT = gql`
	${ADDRESS_ID_FRAGMENT}
	fragment AddressCountryCodeFragment on Address {
		...AddressIdFragment
		countryCode
	}
`;

export const ADDRESS_FRAGMENT = gql`
	${ADDRESS_ID_FRAGMENT}
	${ADDRESS_ADDRESS_FRAGMENT}
	${ADDRESS_ADDRESS_2_FRAGMENT}
	${ADDRESS_ZIP_FRAGMENT}
	${ADDRESS_CITY_FRAGMENT}
	${ADDRESS_COUNTRY_CODE_FRAGMENT}
	fragment AddressFragment on Address {
		...AddressIdFragment
		...AddressAddressFragment
		...AddressAddress2Fragment
		...AddressZipFragment
		...AddressCityFragment
		...AddressCountryCodeFragment
	}
`;
