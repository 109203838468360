import { gql, useMutation } from "@apollo/client";

export const logoutMutation = () => {
	const [logout] = useMutation(LOGOUT, {
		fetchPolicy: "no-cache",
	});
	return logout;
};

export const LOGOUT = gql`
	mutation Logout {
		logout
	}
`;
