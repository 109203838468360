import { gql } from "@apollo/client";
import { TERMS_AND_CONDITIONS_FRAGMENT } from "./termsAndConditions";
import {
	USER_EMAIL_FRAGMENT,
	USER_IMAGE_PATH_FRAGMENT,
	USER_IS_ROOT_FRAGMENT,
	USER_USERNAME_FRAGMENT,
} from "./user";

export const WORKSPACE_NAME_FRAGMENT = gql`
	fragment WorkspaceNameFragment on Workspace {
		id
		name
	}
`;

export const WORKSPACE_LOGO_LINK_FRAGMENT = gql`
	fragment WorkspaceLogoLinkFragment on Workspace {
		id
		logoLink
	}
`;

export const WORKSPACE_IMAGE_PATH_FRAGMENT = gql`
	fragment WorkspaceImagePathFragment on Workspace {
		id
		imagePath
	}
`;

export const WORKSPACE_ACTIVE_FRAGMENT = gql`
	fragment WorkspaceActiveFragment on Workspace {
		id
		active
	}
`;

export const WORKSPACE_ENDDATE_FRAGMENT = gql`
	fragment WorkspaceEndDateFragment on Workspace {
		id
		activeToDate
	}
`;

export const WORKSPACE_PAID_FRAGMENT = gql`
	fragment WorkspacePaidFragment on Workspace {
		id
		clientWorkspace
	}
`;

export const WORKSPACE_ZIP_CODE_FRAGMENT = gql`
	fragment WorkspaceZipCodeFragment on Workspace {
		id
		zipCode
	}
`;

export const WORKSPACE_CITY_FRAGMENT = gql`
	fragment WorkspaceCityFragment on Workspace {
		id
		city
	}
`;

export const WORKSPACE_COUNTRY_FRAGMENT = gql`
	fragment WorkspaceCountryFragment on Workspace {
		id
		country
	}
`;

export const WORKSPACE_CURRENCY_FRAGMENT = gql`
	fragment WorkspaceCurrencyFragment on Workspace {
		id
		currency
	}
`;

export const WORKSPACE_VAT_FRAGMENT = gql`
	fragment WorkspaceVatFragment on Workspace {
		id
		vat
	}
`;

export const WORKSPACE_ADDRESS_FRAGMENT = gql`
	fragment WorkspaceAddressFragment on Workspace {
		id
		address
	}
`;

export const WORKSPACE_CLIENTS_FRAGMENT = gql`
	fragment WorkspaceClientsFragment on Workspace {
		id
		clients
	}
`;

export const WORKSPACE_BUSINESS_MODEL_FRAGMENT = gql`
	fragment WorkspaceBusinessModelFragment on Workspace {
		id
		businessModel
	}
`;

export const WORKSPACE_TYPE_OF_SELLING_FRAGMENT = gql`
	fragment WorkspaceTypeOfSellingFragment on Workspace {
		id
		typeOfSelling
	}
`;

export const WORKSPACE_COMPANY_NAME_FRAGMENT = gql`
	fragment WorkspaceCompanyNameFragment on Workspace {
		id
		companyName
	}
`;

export const WORKSPACE_VAT_NUMBER_FRAGMENT = gql`
	fragment WorkspaceVatNumberFragment on Workspace {
		id
		vatNumber
	}
`;

export const WORKSPACE_EMAIL_CONTACT_FRAGMENT = gql`
	fragment WorkspaceEmailContactFragment on Workspace {
		id
		emailContact
	}
`;

export const WORKSPACE_PHONE_CONTACT_FRAGMENT = gql`
	fragment WorkspacePhoneContactFragment on Workspace {
		id
		phoneContact
	}
`;

export const WORKSPACE_CREATED_AT_FRAGMENT = gql`
	fragment WorkspaceCreatedAtFragment on Workspace {
		id
		createdAt
	}
`;

export const WORKSPACE_DESCRIPTION_FRAGMENT = gql`
	fragment WorkspaceDescriptionFragment on Workspace {
		id
		description
	}
`;

export const WORKSPACE_ACTIVE_TO_DATE_FRAGMENT = gql`
	fragment WorkspaceActiveToDateFragment on Workspace {
		id
		activeToDate
	}
`;

export const WORKSPACE_TC_TYPE_FRAGMENT = gql`
	fragment WorkspaceTCTypeFragment on Workspace {
		id
		tcType
	}
`;

export const WORKSPACE_PO_NUMBER_FRAGMENT = gql`
	fragment WorkspacePOnumberFragment on Workspace {
		id
		poNumber
	}
`;

export const WORKSPACE_USERS_INFOS_FRAGMENT = gql`
	${USER_EMAIL_FRAGMENT}
	${USER_IS_ROOT_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	${USER_USERNAME_FRAGMENT}
	fragment WorkspaceUsersInfosFragment on Workspace {
		id
		users {
			id
			...UserEmailFragment
			...UserIsRootFragment
			...UserImagePathFragment
			...UserUserNameFragment
		}
	}
`;

export const WORKSPACE_TERMS_AND_CONDITIONS_FRAGMENT = gql`
	${TERMS_AND_CONDITIONS_FRAGMENT}
	fragment WorkspaceTermsAndConditionsFragment on Workspace {
		id
		termsAndConditions {
			...TermsAndConditionsFragment
		}
	}
`;

export const WORKSPACE_FROM_CURRENT_USER_FRAGMENT = gql`
	${WORKSPACE_NAME_FRAGMENT}
	${WORKSPACE_DESCRIPTION_FRAGMENT}
	${WORKSPACE_IMAGE_PATH_FRAGMENT}
	${WORKSPACE_ZIP_CODE_FRAGMENT}
	${WORKSPACE_CITY_FRAGMENT}
	${WORKSPACE_COUNTRY_FRAGMENT}
	${WORKSPACE_CURRENCY_FRAGMENT}
	${WORKSPACE_VAT_FRAGMENT}
	${WORKSPACE_ADDRESS_FRAGMENT}
	${WORKSPACE_CLIENTS_FRAGMENT}
	${WORKSPACE_BUSINESS_MODEL_FRAGMENT}
	${WORKSPACE_TYPE_OF_SELLING_FRAGMENT}
	${WORKSPACE_COMPANY_NAME_FRAGMENT}
	${WORKSPACE_VAT_NUMBER_FRAGMENT}
	${WORKSPACE_EMAIL_CONTACT_FRAGMENT}
	${WORKSPACE_PHONE_CONTACT_FRAGMENT}
	${WORKSPACE_ACTIVE_FRAGMENT}
	${WORKSPACE_ACTIVE_TO_DATE_FRAGMENT}
	${WORKSPACE_USERS_INFOS_FRAGMENT}
	${WORKSPACE_TERMS_AND_CONDITIONS_FRAGMENT}
	${WORKSPACE_LOGO_LINK_FRAGMENT}
	fragment WorkspaceFromCurrentUserFragment on Workspace {
		id
		...WorkspaceNameFragment
		...WorkspaceDescriptionFragment
		...WorkspaceImagePathFragment
		...WorkspaceZipCodeFragment
		...WorkspaceCityFragment
		...WorkspaceCountryFragment
		...WorkspaceCurrencyFragment
		...WorkspaceVatFragment
		...WorkspaceAddressFragment
		...WorkspaceClientsFragment
		...WorkspaceBusinessModelFragment
		...WorkspaceTypeOfSellingFragment
		...WorkspaceCompanyNameFragment
		...WorkspaceVatNumberFragment
		...WorkspaceEmailContactFragment
		...WorkspacePhoneContactFragment
		...WorkspaceActiveFragment
		...WorkspaceActiveToDateFragment
		...WorkspaceUsersInfosFragment
		...WorkspaceTermsAndConditionsFragment
		...WorkspaceLogoLinkFragment
	}
`;

export const WORKSPACE_INFO_FRAGMENT = gql`
	${WORKSPACE_NAME_FRAGMENT}
	${WORKSPACE_IMAGE_PATH_FRAGMENT}
	${WORKSPACE_ENDDATE_FRAGMENT}
	${WORKSPACE_CURRENCY_FRAGMENT}
	${WORKSPACE_ACTIVE_FRAGMENT}
	${WORKSPACE_PAID_FRAGMENT}
	fragment WorkspaceInfoFragment on Workspace {
		...WorkspaceNameFragment
		...WorkspaceImagePathFragment
		...WorkspaceEndDateFragment
		...WorkspaceCurrencyFragment
		...WorkspaceActiveFragment
		...WorkspacePaidFragment
		id
	}
`;

export const WORKSPACE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT = gql`
	${WORKSPACE_NAME_FRAGMENT}
	${WORKSPACE_IMAGE_PATH_FRAGMENT}
	${WORKSPACE_CURRENCY_FRAGMENT}
	${WORKSPACE_LOGO_LINK_FRAGMENT}
	fragment WorkspaceForPublicPriceModelFragment on Workspace {
		...WorkspaceNameFragment
		...WorkspaceImagePathFragment
		...WorkspaceCurrencyFragment
		...WorkspaceLogoLinkFragment
	}
`;

export const WORKSPACE_FOR_FOR_DISCOVER_STEP_FRAGMENT = gql`
	${WORKSPACE_NAME_FRAGMENT}
	${WORKSPACE_IMAGE_PATH_FRAGMENT}
	${WORKSPACE_ACTIVE_FRAGMENT}
	fragment WorkspaceForDiscoverStepFragment on Workspace {
		...WorkspaceNameFragment
		...WorkspaceImagePathFragment
		...WorkspaceActiveFragment
	}
`;
