import { gql } from "@apollo/client";

export const OFFER_VERSION_ACCESS_ID_FRAGMENT = gql`
	fragment OfferVersionAccessIdFragment on OfferVersionAccess {
		id
	}
`;

export const OFFER_VERSION_ACCESS_OFFER_VERSION_ID_FRAGMENT = gql`
	${OFFER_VERSION_ACCESS_ID_FRAGMENT}
	fragment OfferVersionAccessOfferVersionIdFragment on OfferVersionAccess {
		...OfferVersionAccessIdFragment
		offerVersionId
	}
`;

export const OFFER_VERSION_ACCESS_OPENED_AT_FRAGMENT = gql`
	${OFFER_VERSION_ACCESS_ID_FRAGMENT}
	fragment OfferVersionAccessOpenedAtFragment on OfferVersionAccess {
		...OfferVersionAccessIdFragment
		openedAt
	}
`;

export const OFFER_VERSION_ACCESS_EMAIL_FRAGMENT = gql`
	${OFFER_VERSION_ACCESS_ID_FRAGMENT}
	fragment OfferVersionAccessEmailFragment on OfferVersionAccess {
		...OfferVersionAccessIdFragment
		email
	}
`;

export const OFFER_VERSION_ACCESS_IS_EXPIRED_OFFER_REQUESTED_FRAGMENT = gql`
	${OFFER_VERSION_ACCESS_ID_FRAGMENT}
	fragment OfferVersionAccessIsExpiredOfferRequestedFragment on OfferVersionAccess {
		...OfferVersionAccessIdFragment
		isExpiredOfferRequested
	}
`;

export const OFFER_VERSION_ACCESS_IS_EXPIRED_OFFER_CONSULTED_FRAGMENT = gql`
	${OFFER_VERSION_ACCESS_ID_FRAGMENT}
	fragment OfferVersionAccessIsExpiredOfferConsultedFragment on OfferVersionAccess {
		...OfferVersionAccessIdFragment
		isExpiredOfferConsulted
	}
`;

export const OFFER_VERSION_ACCESS_FRAGMENT = gql`
	${OFFER_VERSION_ACCESS_ID_FRAGMENT}
	${OFFER_VERSION_ACCESS_OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_ACCESS_OPENED_AT_FRAGMENT}
	${OFFER_VERSION_ACCESS_EMAIL_FRAGMENT}
	${OFFER_VERSION_ACCESS_IS_EXPIRED_OFFER_REQUESTED_FRAGMENT}
	${OFFER_VERSION_ACCESS_IS_EXPIRED_OFFER_CONSULTED_FRAGMENT}
	fragment OfferVersionAccessFragment on OfferVersionAccess {
		...OfferVersionAccessIdFragment
		...OfferVersionAccessOfferVersionIdFragment
		...OfferVersionAccessOpenedAtFragment
		...OfferVersionAccessEmailFragment
		...OfferVersionAccessIsExpiredOfferRequestedFragment
		...OfferVersionAccessIsExpiredOfferConsultedFragment
	}
`;
